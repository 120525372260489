import * as THREE from "three";
import { soObject } from "../soObject";
import SceneUtils from "../../../utils/SceneUtils";

/**
 * Class representing a DataBox Line in the scene.
 * Inherits from soObject.
 */
export class soDataBoxLine extends soObject {
  start: THREE.Vector3;
  end: THREE.Vector3;
  line: THREE.Line;
  isWetWall: boolean;
  isPlumbing: boolean;
  clearence: number;
  lineId: string;
  type: string;
  /**
   * Constructs an instance of soDataBoxLine.
   * @param soId - soId representing the line id.
   * @param line - THREE.Line representing the dataBox line.
   */
  constructor(
    soId: string,
    start: THREE.Vector3,
    end: THREE.Vector3,
    line: THREE.Line,
    isWetWall: boolean,
    isPlumbing: boolean,
    clearence: number,
    type: string
  ) {
    super();

    this.soId = soId;
    this.start = start;
    this.end = end;
    this.line = line;
    this.isWetWall = isWetWall;
    this.isPlumbing = isPlumbing;
    this.clearence = clearence;
    this.type = type;
  }

  get Line3(): THREE.Line3 {
    return SceneUtils.getLine3(this.line);
  }
}
