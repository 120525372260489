import jsPDF, { GState } from "jspdf";
import * as THREE from "three";
import { areaToEnUsLocaleString, inches2feetSq, inchesToFeetInchesFraction } from "../../helpers/measures";
import { integerToHexColor } from "../../helpers/utilities";
import { appModel } from "../../models/AppModel";
import { Floor } from "../../models/Floor";
import { RoomEntityType } from "../../models/RoomEntityType";
import { arcGapColors, formatRoomGapDescription } from "../../ui/components/Editor/LeftBar/ValidationPanel/ArcGapValidationTab";
import { arcValidationItems } from "../../ui/components/Editor/LeftBar/ValidationPanel/AreaValidationTab";
// import { getGravityLoadsLegendColors, gravityLoadsErrors, gravityLoadsLegendItems } from "../../ui/components/Editor/LeftBar/ValidationPanel/GravityLoadsTab";
// import { plumbingLegendItemsMap } from "../../ui/components/Editor/LeftBar/ValidationPanel/PlumbingTab";
// import { getShearCapacityLegendColors, shearCapacityLegendItems } from "../../ui/components/Editor/LeftBar/ValidationPanel/ShearCapacityTab";
import { ValidationItemProps, ValidationItemType } from "../../ui/components/Editor/LeftBar/ValidationPanel/ValidationItem";
import { INTERSECTED_COLOR, RENDERER_CLEAR_COLOR, VEEV_EMAIL, ZOOM_TO_FIT_SIZE_FACTOR_PDF_REPORT } from "../consts";
import RoomManager from "../managers/RoomManager/RoomManager";
import { SceneEntityType } from "../models/SceneEntityType";
import { IArcAreaValidationResult, IArcGapsValidationResult } from "../models/ValidationResult";
import { ValidationMode } from "../models/ValidationType";
import GeometryUtils from "../utils/GeometryUtils/GeometryUtils";
import SceneUtils from "../utils/SceneUtils";
import UnitsUtils from "../utils/UnitsUtils";
import { ArcGapStatus } from "./ValidationTools/GapValidationTool";
import RoomUtils from "../utils/RoomUtils";
import FloorUtils from "../utils/FloorUtils";
import SceneManager from "../managers/SceneManager/SceneManager";

// pdf size units conversion from pt; see https://github.com/parallax/jsPDF/blob/ddbfc0f0250ca908f8061a72fa057116b7613e78/jspdf.js#L791
// in = pt / 72
// px = in * ppi
const ppi = 54;

// see https://github.com/parallax/jsPDF/blob/ddbfc0f0250ca908f8061a72fa057116b7613e78/jspdf.js#L59
// Ledger: (1224.0 x 792.0) pt = (17 x 11) " = (918 x 594) px
const pageSize = new THREE.Vector2(17 * ppi, 11 * ppi); // px;

const outerHMargin = 5;
const outerVMargin = 5;
const spacer = 10;
const panelWidth = pageSize.x / 7;
const logoHeight = 40;
const imageMargin = 30;
const imageQuality = 0.5;
const tab1 = 2;
const tab2 = 5;
const titleFontSize = 20;
const subtitleFontSize = 10;
const captionFontSize = 8;
const textFontSize = 6;
const captionFontSize2 = 4;

const scaleLineWidthInches = 3.0 / 4.0; // pdf inches
const scaleLinesCount = 5;

const validationModePageName = {
  [ValidationMode.StrGravityLoads]: "STR Gravity",
  [ValidationMode.StrShearCapacity]: "STR Shear",
  [ValidationMode.MepPlumbing]: "MEP Plumbing",
  [ValidationMode.ArcAreaValidation]: "ARC Area Calculation",
  [ValidationMode.ArcGap]: "ARC Gap",
};

export default class PdfReportTool {
  private doc: jsPDF = null;
  private innerRectX = 0;
  private innerRectCenterX = 0;
  private innerRectY = 0;
  private innerRectWidth = 0;
  private innerRectHeight = 0;
  private panelStartX = 0;
  private panelCenterX = 0;
  private panelEndX = 0;
  private architecturalScale: { value: number; name: string };
  private floors: Floor[] = [];
  private demandData: Attribute[] = [];

  constructor(private roomManager: any) {}

  public async generatePdfReport(onlyActiveFloor = false): Promise<File> {
    this.doc = new jsPDF({
      orientation: "l",
      unit: "px", // measurement unit (base unit) to be used when coordinates are specified
      // format: "ledger",
      format: [pageSize.x, pageSize.y],
    });

    this.pdfCalculateLayout();

    const selectedRooms = this.roomManager.getCorePlanSelectedSoRooms();
    this.roomManager.selectAllRooms(false);
    this.roomManager.updateObsoleteRoomsHighlighting(false);
    if (this.roomManager.validationTool.isActive) {
      this.roomManager.validationTool.removeValidationVisualization();
    }

    const showBelowFloor = appModel.showBelowFloor;
    const showAboveFloor = appModel.showAboveFloor;

    appModel.showBelowFloor = false;
    appModel.showAboveFloor = false;

    this.demandData = this.getDemandData();
    this.floors = onlyActiveFloor ? [appModel.activeFloor] : [...appModel.activeCorePlan.floors].sort((a, b) => a.index - b.index);
    const soFloors = this.floors.map(floor => this.getSoFloor(floor));

    const renderer = new THREE.WebGLRenderer({ antialias: true, canvas: document.createElement("canvas") });
    renderer.setClearColor(RENDERER_CLEAR_COLOR, 1);

    this.pdfAddFloorPagesToReport(onlyActiveFloor, soFloors, renderer);
    this.pdfAddValidationPagesToReport(soFloors, renderer);

    // Reset.
    this.roomManager.validationTool.setContainer();
    this.roomManager.validationTool.removeValidationVisualization();

    soFloors.forEach(g => GeometryUtils.disposeObject(g));
    renderer.domElement.remove();

    selectedRooms.forEach(sr => this.roomManager.selectRoom(sr, true));
    this.roomManager.updateObsoleteRoomsHighlighting();

    appModel.showBelowFloor = showBelowFloor;
    appModel.showAboveFloor = showAboveFloor;
    this.roomManager.updateFloorsVisibility();

    if (this.roomManager.validationTool.isActive) {
      this.roomManager.performValidation();
    }

    // Export to file.
    const reportType = onlyActiveFloor ? "Floor Plan" : "CorePlan Plan";
    const fileName = `${appModel.activeCorePlan.name} - ${reportType}.pdf`;
    return new File([this.doc.output("blob")], fileName, { type: "application/pdf" });
  }

  private getZoomData(floors: Floor[]): { camera: THREE.PerspectiveCamera; planSize: THREE.Vector2 } {
    const bb = new THREE.Box3();
    floors.forEach(floor => {
      const soFloor = this.roomManager.getSoFloor(floor.id);
      FloorUtils.getFloorSoRooms(soFloor).forEach(room => {
        bb.expandByObject(room);
      });
    });
    const bbSize = bb.getSize(new THREE.Vector3());
    const bbCenter = bb.getCenter(new THREE.Vector3());

    const zoomToBbox = floors.length > 1;
    const boxSide = zoomToBbox ? bbSize.y : Math.max(bbSize.x, bbSize.y);
    const coefficient = zoomToBbox ? 1 : ZOOM_TO_FIT_SIZE_FACTOR_PDF_REPORT;
    const aspect = zoomToBbox ? bbSize.x / bbSize.y : 1;

    const { camera } = this.roomManager;
    const zoomToFitCamera = new THREE.PerspectiveCamera(camera.fov, aspect, camera.near, camera.far);
    zoomToFitCamera.up.copy(camera.up);
    zoomToFitCamera.zoom = camera.zoom;

    const distance = (0.5 * boxSide * coefficient) / Math.tan(0.5 * zoomToFitCamera.getEffectiveFOV() * THREE.MathUtils.DEG2RAD);
    zoomToFitCamera.position.copy(bbCenter.clone().add(new THREE.Vector3(0, 0, distance)));
    zoomToFitCamera.lookAt(bbCenter);
    zoomToFitCamera.updateProjectionMatrix();

    return { camera: zoomToFitCamera, planSize: new THREE.Vector2(bbSize.x, bbSize.y) };
  }
  private getDemandData(): Attribute[] {
    const attributes = appModel.activeCorePlan.attributes;
    const netArea = appModel.activeCorePlan.floors.reduce((sum, floor) => sum + this.roomManager.tmlTool.calculateNetArea(floor), 0);

    return [
      { label: "Net area", value: areaToEnUsLocaleString(netArea), unit: "sqft" },
      { label: "Lot size", value: areaToEnUsLocaleString(attributes.lotSize), unit: "sqft" },
      { label: "Min “Net” area", value: areaToEnUsLocaleString(attributes.minNetArea), unit: "sqft" },
      { label: "Max “Gross” area", value: areaToEnUsLocaleString(attributes.maxGrossArea), unit: "sqft" },
      { label: "Stories", value: attributes.floors || 0 },
      { label: "Garage", value: attributes.garage || 0, unit: "cars" },
      { label: "Bedrooms", value: attributes.bedRooms || 0 },
      { label: "Bathrooms", value: attributes.bathRooms || 0 },
      { label: "First Floor to Floor", value: inchesToFeetInchesFraction(appModel.activeCorePlan.firstFloorToFloorHeight) },
      { label: "Upper Floor to Floor", value: inchesToFeetInchesFraction(appModel.activeCorePlan.upperFloorToFloorHeight) },
    ];
  }
  private getSoFloor(floor: Floor): THREE.Group {
    const result = new THREE.Group();

    const soFloor = this.roomManager.getSoFloorsRoot().children.find(x => x.soId == floor.id || x.userData.id == floor.id);
    this.roomManager.updateFloorsVisibility(soFloor, floor);

    FloorUtils.getFloorSoRooms(soFloor).forEach(soRoom => {
      result.add(soRoom.clone());

      const roomType = appModel.getRoomType(soRoom.userData.roomTypeId);
      const bb = RoomUtils.getRoomNetBoundingBox(this.roomManager, soRoom);
      const bbSize = bb.getSize(new THREE.Vector3());
      const title = (roomType?.attributes?.revitRoomTagName as string) || roomType.name;
      const subtitle = `${inchesToFeetInchesFraction(bbSize.x, UnitsUtils.getRoundFractionPrecision(), true)} x ${inchesToFeetInchesFraction(
        bbSize.y,
        UnitsUtils.getRoundFractionPrecision(),
        true
      )}`;
      const soRoomLabel = this.getSoRoomLabel(title, subtitle, bb);

      const labelBb = GeometryUtils.getGeometryBoundingBox2D(soRoomLabel);
      let intersects = false;
      soRoom.children
        .filter(child => child.userData.type == RoomEntityType.Wall)
        .forEach(child => {
          const wallBb = GeometryUtils.getGeometryBoundingBox2D(child);
          if (GeometryUtils.doBoundingBoxesIntersect([labelBb], [wallBb])) {
            intersects = true;
          }
        });

      if (!intersects) {
        result.add(soRoomLabel);
      }
    });

    return result;
  }
  private getSoRoomLabel(title: string, subtitle: string, bb: THREE.Box3): THREE.Group {
    const fontSize = 64 * 0.01 * UnitsUtils.getConversionFactor();
    const result = SceneUtils.createTextLabels(title, subtitle, fontSize, fontSize / 1.5, 0.25 * UnitsUtils.getConversionFactor(), 0x000000, bb);
    result.position.z = 0.1; // TODO render order
    result.userData.type = SceneEntityType.RoomLabel;

    return result;
  }
  private getSoFloorScreenshot(soFloor: THREE.Group, renderer: THREE.WebGLRenderer, camera: THREE.PerspectiveCamera): string {
    renderer.render(soFloor, camera);
    return renderer.domElement.toDataURL("image/jpeg");
  }
  private getValidationItems(mode: ValidationMode): { legendItems: ValidationItemProps[]; floorData: FloorData[] } {
    switch (mode) {
      // case ValidationMode.StrGravityLoads: {
      //   const colorMap = getGravityLoadsLegendColors();
      //   const items = Object.keys(gravityLoadsLegendItems).map(key => ({
      //     color: colorMap[key],
      //     text: gravityLoadsLegendItems[key],
      //     type: key === "ImpossibleBeam" ? ValidationItemType.DashedSegment : ValidationItemType.Segment,
      //   }));
      //   const result = this.roomManager.validationTool.getGravityLoadsErrors(this.floors.length === 1 ? this.floors[0].id : null);
      //   result.forEach(res => {
      //     if (!res.result) {
      //       return;
      //     }
      //     items.push({
      //       color: integerToHexColor(INTERSECTED_COLOR),
      //       text: gravityLoadsErrors[res.name],
      //       type: ValidationItemType.Dot,
      //     });
      //   });
      //   return { legendItems: items, floorData: [] };
      // }

      // case ValidationMode.StrShearCapacity: {
      //   const colorMap = getShearCapacityLegendColors();
      //   const items = Object.keys(shearCapacityLegendItems).map(key => ({
      //     color: colorMap[key],
      //     text: shearCapacityLegendItems[key],
      //     type: ValidationItemType.Segment,
      //   }));
      //   return { legendItems: items, floorData: [] };
      // }

      // case ValidationMode.MepPlumbing: {
      //   const items = Object.entries(plumbingLegendItemsMap).map(item => item[1]);
      //   return { legendItems: items, floorData: [] };
      // }

      case ValidationMode.ArcAreaValidation: {
        const result = this.roomManager.validationTool.getFloorValidationResult(ValidationMode.ArcAreaValidation) as IArcAreaValidationResult;
        const floorData = result.levelArea.map(la => ({
          floorName: appModel.activeCorePlan.floors.find(f => f.id === la.floorId).name,
          items: [
            { label: "Gross area", value: inches2feetSq(la.grossArea, 2), unit: "sqft" },
            { label: "Net area", value: inches2feetSq(la.netArea, 2), unit: "sqft" },
          ],
        })) as FloorData[];
        floorData.push({
          floorName: "Total area",
          items: [
            {
              label: "Gross area",
              value: inches2feetSq(
                result.levelArea.reduce((sum, la) => sum + la.grossArea, 0),
                2
              ),
              unit: "sqft",
            },
            {
              label: "Net area",
              value: inches2feetSq(
                result.levelArea.reduce((sum, la) => sum + la.netArea, 0),
                2
              ),
              unit: "sqft",
            },
          ],
        });
        return { legendItems: arcValidationItems, floorData: floorData };
      }

      case ValidationMode.ArcGap: {
        const result = this.roomManager.validationTool.getFloorValidationResult(ValidationMode.ArcGap) as IArcGapsValidationResult[];
        const items = [];

        result.forEach(res => {
          if (res.status === ArcGapStatus.None) {
            return;
          }
          items.push({
            color: arcGapColors[res.status],
            text: formatRoomGapDescription(res).itemDescription,
            type: ValidationItemType.Dot,
          });
        });
        return { legendItems: items, floorData: [] };
      }

      default: {
        return { legendItems: [], floorData: [] };
      }
    }
  }

  private pdfCalculateLayout(): void {
    this.innerRectX = outerHMargin + spacer;
    this.innerRectY = outerVMargin + spacer;
    this.innerRectWidth = pageSize.x - outerHMargin - 2 * spacer - panelWidth - this.innerRectX;
    this.innerRectHeight = pageSize.y - 2 * this.innerRectY;
    this.innerRectCenterX = this.innerRectX + this.innerRectWidth / 2;

    this.panelEndX = pageSize.x - outerHMargin - spacer;
    this.panelStartX = this.panelEndX - panelWidth;
    this.panelCenterX = this.panelStartX + panelWidth / 2;
  }
  private pdfAddFloorPagesToReport(onlyActiveFloor: boolean, soFloors: THREE.Group[], renderer: THREE.WebGLRenderer): void {
    const screenShotSize = new THREE.Vector2(this.innerRectWidth, this.innerRectHeight).subScalar(2 * imageMargin); // pdf px

    const floorsBbs: THREE.Box3[] = [];
    let largestFloorBb: THREE.Box3;
    let factor = 1000000.0; // pdf pixels / scene inch
    soFloors.forEach(soFloor => {
      const floorBb = new THREE.Box3();
      FloorUtils.getFloorSoRooms(soFloor).forEach(room => {
        floorBb.expandByObject(room);
      });
      floorsBbs.push(floorBb);

      const sz = floorBb.getSize(new THREE.Vector3());
      const bbSizeInches = new THREE.Vector2(sz.x, sz.y);
      const bbFactor = GeometryUtils.getFitToSizeFactor2D(screenShotSize, bbSizeInches); // pdf pixels / scene inch
      if (bbFactor < factor) {
        factor = bbFactor;
        largestFloorBb = floorBb;
      }
    });

    const sz = largestFloorBb.getSize(new THREE.Vector3());
    const sizeInches = new THREE.Vector2(sz.x, sz.y); // scene inches
    let scale = GeometryUtils.getFitToSizeFactor2D(screenShotSize, sizeInches); // pdf pixels / scene inch
    const archScale = scale / ppi / UnitsUtils.INCHES2FEET; // pdf inches / scene foot
    const archScaleNearest = SceneUtils.getNearestArchitecturalScale(archScale); // pdf inches / scene foot
    scale *= archScaleNearest.value / archScale;

    this.architecturalScale = archScaleNearest;

    this.floors.forEach((floor, idx) => {
      if (!onlyActiveFloor && floor.index > 0) {
        this.doc.addPage();
      }

      this.pdfAddPageFrame(floor.name.toUpperCase(), true);

      // Floor name at the page bottom.
      this.doc.setFontSize(subtitleFontSize);
      this.doc.text(`${floor.name} - FLOOR PLAN`, this.innerRectCenterX, this.innerRectY + this.innerRectHeight - spacer, {
        align: "center",
        maxWidth: this.innerRectWidth,
      });

      // Scale ruler.
      this.pdfAddScaleRuler();

      // Floor screenshot.
      const floorBb = floorsBbs[idx];
      if (floorBb.isEmpty()) {
        return;
      }
      const bbSize = floorBb.getSize(new THREE.Vector3());
      const bbCenter = floorBb.getCenter(new THREE.Vector3());
      const aspect = bbSize.x / bbSize.y;

      const { camera } = this.roomManager;
      const zoomToFitCamera = new THREE.PerspectiveCamera(camera.fov, aspect, camera.near, camera.far);
      zoomToFitCamera.up.copy(camera.up);
      zoomToFitCamera.zoom = camera.zoom;
      const distance = (0.5 * bbSize.y) / Math.tan(0.5 * zoomToFitCamera.getEffectiveFOV() * THREE.MathUtils.DEG2RAD);
      zoomToFitCamera.position.copy(bbCenter.clone().add(new THREE.Vector3(0, 0, distance)));
      zoomToFitCamera.lookAt(bbCenter);
      zoomToFitCamera.updateProjectionMatrix();

      const canvasSize = GeometryUtils.getMaxRenderSize(aspect, imageQuality); // px
      renderer.setSize(canvasSize.x, canvasSize.y);
      const floorScreenshot = this.getSoFloorScreenshot(soFloors[idx], renderer, zoomToFitCamera);

      const w = bbSize.x * scale;
      const h = bbSize.y * scale;
      const x = this.innerRectX + (this.innerRectWidth - w) * 0.5;
      const y = this.innerRectY + (this.innerRectHeight - h) * 0.5;
      this.doc.addImage(floorScreenshot, "JPEG", x, y, w, h);
    });
  }
  private pdfAddValidationPagesToReport(soFloors: THREE.Group[], renderer: THREE.WebGLRenderer): void {
    const zoomData = this.getZoomData(this.floors);
    const aspectRatio = this.floors.length > 1 ? zoomData.planSize.x / zoomData.planSize.y : 1;

    const canvasSize = GeometryUtils.getMaxRenderSize(aspectRatio, imageQuality);
    renderer.setSize(canvasSize.x, canvasSize.y);

    this.pdfAddValidationPageToReport(ValidationMode.StrGravityLoads, soFloors, zoomData.camera, aspectRatio, renderer);
    this.pdfAddValidationPageToReport(ValidationMode.StrShearCapacity, soFloors, zoomData.camera, aspectRatio, renderer);
    this.pdfAddValidationPageToReport(ValidationMode.MepPlumbing, soFloors, zoomData.camera, aspectRatio, renderer);
    soFloors.forEach(soFloor => {
      FloorUtils.getFloorSoRooms(soFloor).forEach(child => {
        if (child.userData.type === SceneEntityType.RoomLabel) {
          child.visible = false;
        }
      });
    });
    this.pdfAddValidationPageToReport(ValidationMode.ArcAreaValidation, soFloors, zoomData.camera, aspectRatio, renderer);
    this.pdfAddValidationPageToReport(ValidationMode.ArcGap, soFloors, zoomData.camera, aspectRatio, renderer);
  }
  private pdfAddValidationPageToReport(
    validationMode: ValidationMode,
    soFloors: THREE.Group[],
    camera: THREE.PerspectiveCamera,
    aspectRatio: number,
    renderer: THREE.WebGLRenderer
  ): void {
    this.doc.addPage();

    let y = this.pdfAddPageFrame(`Validations ${validationModePageName[validationMode]}`);

    // Screenshots.
    this.roomManager.validationTool.performValidation(validationMode);
    const resultsContainer = this.roomManager.validationTool.getResultContainer();

    const screenshots: string[] = [];
    this.floors.forEach((floor, index) => {
      this.roomManager.updateFloorsVisibility(this.roomManager.getSoFloor(floor.id), floor);
      this.roomManager.validationTool.visualizeValidationResult(validationMode, floor.id);
      soFloors[index].add(resultsContainer);
      resultsContainer.updateMatrixWorld();

      screenshots.push(this.getSoFloorScreenshot(soFloors[index], renderer, camera));

      this.roomManager.validationTool.removeValidationVisualization();
    });

    const rows = screenshots.length > 2 ? 2 : 1;
    const columns = screenshots.length > 2 ? Math.ceil(screenshots.length / 2) : screenshots.length;
    const rowHeight = this.innerRectHeight / rows;
    const columnWidth = this.innerRectWidth / columns;
    const maxScreenshotWidth = columnWidth - 2 * imageMargin;
    const maxScreenshotHeight = rowHeight - 2 * imageMargin;
    let proportionalHeight = maxScreenshotHeight;
    let proportionalWidth = proportionalHeight * aspectRatio;
    if (proportionalWidth > maxScreenshotWidth) {
      proportionalWidth = maxScreenshotWidth;
      proportionalHeight = proportionalWidth / aspectRatio;
    }

    screenshots.forEach((screenshot, index) => {
      const yPosition = this.innerRectY + (index < columns ? imageMargin : rowHeight + imageMargin) + (maxScreenshotHeight - proportionalHeight) / 2;
      const xPosition = this.innerRectX + (index % columns) * columnWidth + imageMargin + (maxScreenshotWidth - proportionalWidth) / 2;
      this.doc.addImage(screenshot, "JPEG", xPosition, yPosition, proportionalWidth, proportionalHeight);

      const textXPosition = this.innerRectX + (index % columns) * columnWidth + columnWidth / 2;
      const textYPosition = this.innerRectY + rowHeight * (index < columns ? 1 : 2) - imageMargin / 2;
      this.doc.setFontSize(subtitleFontSize);
      this.doc.text(`Story ${index + 1}`, textXPosition, textYPosition, { align: "center" });
    });

    // Validation legend table.
    const { legendItems, floorData } = this.getValidationItems(validationMode);
    if (!legendItems.length && !floorData.length) {
      return;
    }

    y += spacer * 0.5;
    this.doc.setFontSize(captionFontSize);
    this.doc.setTextColor(106, 106, 106);
    this.doc.text("Legend", this.panelStartX + tab1, y);
    y += spacer * 0.5;
    this.pdfAddSeparator(3, y, 106, 106, 106);

    y += spacer;
    this.doc.setFontSize(textFontSize);
    for (let i = 0; i < legendItems.length; i++) {
      const item = legendItems[i];

      this.pdfAddValidationItem(item, y);
      this.doc.setTextColor(106, 106, 106);
      this.doc.text(item.text, this.panelStartX + tab2 + 2.5 * spacer, y);

      y += spacer;
    }

    // Floors attributes.
    for (let i = 0; i < floorData.length; i++) {
      y = this.pdfAddTableData(floorData[i].floorName, floorData[i].items, y);
      y += spacer * 0.5;
    }
  }

  private pdfAddPageFrame(sheetName: string, showScale: boolean = false): number {
    // const architectEngineer = "Veev";
    // const veevCorePlanNumber = "";
    // const designerOfRecordStamp = "APN";
    // const sheetNumber = `A1.${this.doc.getNumberOfPages().toString().padStart(2, "0")}`;
    // const people = [
    //   { label: "Drawn by", value: "" },
    //   { label: "Checked", value: "" },
    //   { label: "Approved", value: "" },
    // ];
    // const organizationalFileNumber = ""; /* `${veevCorePlanNumber}-${sheetNumber}-${unitZone}` */
    const issueRevision = "";
    const notes =
      "1. All rights reserved to Veev\n" +
      "2. All drawings and written materials appearing here constitute original and unpublished work of Veev\n" +
      "3. Add drawings may not be duplicated, used or disclosed without written consent from Veev\n" +
      "4. Do not scale from this drawing\n" +
      "5. All discrepancies to be reported to Veev DTS immediately\n" +
      "6. All dimensions to be verified by FAB and B&I on site prior to any works\n" +
      "7. All drawings to be read in conjunction with sheet notes document\n" +
      "8. All drawings to be read in conjunction with graphics, symbols and abbreviations document\n" +
      "9. All drawings to be read in conjunction with Structural and MEP engineering information\n";

    // Outer rectangle.
    this.doc.setLineWidth(1);
    this.doc.rect(outerHMargin, outerVMargin, pageSize.x - 2 * outerHMargin, pageSize.y - 2 * outerVMargin);

    // Inner rectangle.
    this.doc.setLineWidth(1);
    this.doc.rect(this.innerRectX, this.innerRectY, this.innerRectWidth, this.innerRectHeight);

    // // Date.
    // this.doc.setFontSize(6);
    // this.doc.text(new Date().toLocaleString(), 118, this.innerRectY + 60, { angle: 90 });

    // Panel top line.
    this.pdfAddSeparator(1, this.innerRectY, 0, 0, 0);

    let y = this.innerRectY;

    // Veev logo.
    y += spacer * 0.5;
    const img = new Image();
    img.src = "/assets/veevLogo.png";
    this.doc.addImage(img, "png", this.panelStartX + tab2, y, panelWidth - 2 * tab2, logoHeight);
    // Veev Email.
    y += logoHeight + spacer;
    this.doc.setFontSize(textFontSize);
    this.doc.setTextColor(106, 106, 106);
    this.doc.text(VEEV_EMAIL, this.panelCenterX, y, { align: "center", maxWidth: panelWidth });
    y += spacer * 0.5;
    this.pdfAddSeparator(3, y, 106, 106, 106);

    // // Architect/Engineer.
    // y += 7;
    // this.doc.setFontSize(8);
    // this.doc.text("Architect/Engineer", this.panelStartX + tab1, y);
    // y += 30;
    // this.doc.setFontSize(20);
    // this.doc.setTextColor(3, 203, 106);
    // this.doc.setFont(this.doc.getFont().fontName, "bold");
    // this.doc.text(architectEngineer.toUpperCase(), this.panelStartX, y, { align: "center", maxWidth: panelWidth });
    // y += 25;
    // this.pdfAddSeparator(3, y, 106, 106, 106);

    // CorePlan Name.
    y += spacer;
    this.doc.setFont(this.doc.getFont().fontName, "normal");
    this.doc.setFontSize(captionFontSize);
    this.doc.setTextColor(106, 106, 106);
    this.doc.text("CorePlan Name", this.panelStartX + tab1, y);
    y += 1.5 * spacer;
    this.doc.setFontSize(titleFontSize);
    this.doc.setTextColor(0, 0, 0);
    this.doc.text(appModel.activeCorePlan.name, this.panelStartX + tab2, y, { maxWidth: panelWidth });
    y += spacer * 0.5;
    this.pdfAddSeparator(3, y, 106, 106, 106);

    // // CorePlan Address.
    // y += spacer;
    // this.doc.setFontSize(8);
    // this.doc.setTextColor(106, 106, 106);
    // this.doc.text("Address", this.panelStartX + tab1, y);
    // y += spacer;
    // this.doc.setTextColor(0, 0, 0);
    // this.doc.text(appModel.activeCorePlan.location, this.panelStartX + tab1, y, { maxWidth: panelWidth });
    // y += spacer;
    // this.pdfAddSeparator(3, y, 106, 106, 106);

    // // CorePlan Number.
    // y += spacer; // 352
    // this.doc.setFontSize(8);
    // this.doc.setTextColor(106, 106, 106);
    // this.doc.text("Veev CorePlan Number:", this.panelStartX + tab1, y);
    // this.doc.setTextColor(0, 0, 0);
    // this.doc.text(veevCorePlanNumber, this.panelCenterX + tab1, y);

    // // Designer of record stamp
    // y += 12; // 364
    // this.doc.setFontSize(5.5);
    // this.doc.text(designerOfRecordStamp, this.panelStartX + tab1, y);
    // y += 6; // 370
    // this.doc.setLineWidth(2);
    // this.doc.setDrawColor(106, 106, 106);
    // this.doc.line(this.panelStartX, y, this.panelEndX, y);
    // y += 10; // 380
    // this.doc.setFontSize(8);
    // this.doc.setTextColor(106, 106, 106);
    // this.doc.text("Designer of Record Stamp", this.panelStartX + tab1, y);

    // Notes.
    y += spacer;
    // this.doc.setFont(this.doc.getFont().fontName, "normal");
    this.doc.setFontSize(captionFontSize);
    this.doc.setTextColor(106, 106, 106);
    this.doc.text("Notes", this.panelStartX + tab1, y);
    y += spacer;
    this.doc.setFontSize(textFontSize);
    this.doc.setTextColor(0, 0, 0);
    this.doc.text(notes, this.panelStartX + tab2, y, { maxWidth: panelWidth });
    y += 8 * spacer;
    this.pdfAddSeparator(3, y, 106, 106, 106);

    // Demand data.
    y += spacer;
    y = this.pdfAddTableData("Demand Data", this.demandData, y);
    // this.pdfAddSeparator(3, y, 106, 106, 106);

    const retY = y;

    // y += 58; // 965
    // this.doc.setTextColor(0, 0, 0);
    // this.doc.setFontSize(16);
    // this.doc.line(this.panelStartX, y, this.panelEndX, y);

    // // Sheet number.
    // y += spacer; // 972
    // this.doc.setTextColor(106, 106, 106);
    // this.doc.setFontSize(8);
    // this.doc.text("Sheet Number", this.panelStartX + tab1, y);
    // y += 34; // 1006
    // this.doc.setTextColor(0, 0, 0);
    // this.doc.setFontSize(44);
    // this.doc.text(sheetNumber, this.panelStartX + tab2, y);

    // y += spacer; // 1013
    // this.doc.line(this.panelStartX, y, this.panelEndX, y);

    // // Scale.
    // y += spacer; // 1020
    // this.doc.setTextColor(106, 106, 106);
    // this.doc.setFontSize(8);
    // this.doc.text("Scale", this.panelStartX + tab1, y);
    // y += 16; // 1036
    // this.doc.setTextColor(0, 0, 0);
    // this.doc.setFontSize(16);
    // this.doc.text(scale, this.panelStartX + tab2, y);

    // y += 4; // 1040
    // this.doc.line(this.panelStartX, y, this.panelEndX, y);

    // y += 10; // 1050
    // for (let i = 0; i < people.length; i++) {
    //   let yPosition = y + i * 12;

    //   this.doc.setFontSize(5.5);
    //   this.doc.setFont(this.doc.getFont().fontName, "normal");
    //   this.doc.text(people[i].label, this.panelStartX + tab2, yPosition);

    //   this.doc.setFontSize(8);
    //   this.doc.setFont(this.doc.getFont().fontName, "bold");
    //   const clipped = this.doc.splitTextToSize(people[i].value, 100)[0];
    //   this.doc.text(clipped, 1615, yPosition); // TODO

    //   yPosition += 2;
    //   this.doc.line(this.panelStartX, yPosition, this.panelEndX, yPosition);
    // }

    // y += 40; // 1090
    // this.doc.line(this.panelStartX, y, this.panelEndX, y);

    // y += spacer; // 1098
    // this.doc.setFont(this.doc.getFont().fontName, "normal");
    // this.doc.setTextColor(106, 106, 106);
    // this.doc.setFontSize(5.5);
    // this.doc.text("Organizational File Number", this.panelStartX + tab1, y);
    // y += 4; // 1102
    // this.doc.text("Issue/Revision", 1705, y, { align: "center" });
    // y += 4; // 1106
    // this.doc.text("CorePlan", this.panelStartX + tab1, y);
    // this.doc.text("Sheet No.", 1627, y); // TODO
    // this.doc.text("Unit/Zone", 1657, y); // TODO

    // y += spacer;
    // this.doc.setTextColor(0, 0, 0);
    // this.doc.setFontSize(16);
    // this.doc.text(organizationalFileNumber, this.panelStartX + tab2, y);

    y = this.innerRectY + this.innerRectHeight - 90; //100;

    // Sheet name.
    y += spacer;
    this.pdfAddSeparator(3, y, 106, 106, 106);
    y += spacer;
    this.doc.setFontSize(captionFontSize);
    this.doc.setTextColor(106, 106, 106);
    this.doc.text("Sheet Name", this.panelStartX + tab1, y);
    y += spacer;
    this.doc.setFontSize(subtitleFontSize);
    this.doc.setTextColor(0, 0, 0);
    this.doc.text(sheetName, this.panelStartX + tab2, y, { maxWidth: panelWidth });
    y += spacer * 0.5;

    // Footer table.
    const tableY = y;
    // row #1
    this.pdfAddSeparator(1, y, 106, 106, 106);
    y += spacer * 0.5;
    this.doc.setFontSize(captionFontSize2);
    this.doc.setTextColor(106, 106, 106);
    this.doc.text("drawn", this.panelStartX + tab1, y);
    this.doc.text("checked", this.panelStartX + tab1 + panelWidth / 3, y);
    this.doc.text("approved", this.panelStartX + tab1 + 2 * (panelWidth / 3), y);
    y += spacer;
    // row #2
    this.pdfAddSeparator(1, y, 106, 106, 106);
    y += spacer * 0.5;
    this.doc.setFontSize(captionFontSize2);
    this.doc.setTextColor(106, 106, 106);
    this.doc.text("scale", this.panelStartX + tab1, y);
    this.doc.text("date", this.panelStartX + tab1 + panelWidth / 3, y);
    this.doc.text("status", this.panelStartX + tab1 + 2 * (panelWidth / 3), y);
    y += spacer * 0.5;
    this.doc.setFontSize(textFontSize);
    this.doc.setTextColor(0, 0, 0);
    if (showScale) {
      this.doc.text(this.architecturalScale.name, this.panelStartX + tab2, y);
    }
    this.doc.text(new Date().toLocaleDateString(), this.panelStartX + tab2 + panelWidth / 3, y, { maxWidth: panelWidth / 3 });
    this.doc.text("FOR INFO", this.panelStartX + tab2 + 2 * (panelWidth / 3), y);
    y += spacer * 0.5;
    this.pdfAddSeparator(1, y, 106, 106, 106);
    // Vertical lines.
    // this.doc.setLineWidth(1);
    this.doc.line(this.panelStartX + panelWidth / 3, tableY, this.panelStartX + panelWidth / 3, y);
    this.doc.line(this.panelStartX + 2 * (panelWidth / 3), tableY, this.panelStartX + 2 * (panelWidth / 3), y);

    y += spacer;
    this.doc.setFontSize(captionFontSize);
    this.doc.setTextColor(106, 106, 106);
    this.doc.text("corePlan code", this.panelStartX + tab1, y);
    this.doc.setFontSize(captionFontSize2);
    this.doc.text("issue / revision", this.panelEndX - tab1, y, { align: "right" });
    y += spacer * 0.5;
    this.doc.setFontSize(textFontSize);
    this.doc.setTextColor(0, 0, 0);
    // this.doc.text(CorePlanCode, this.panelStartX + tab2, y);
    this.doc.text(issueRevision, this.panelEndX - tab2, y, { align: "right" });

    // Panel bottom line.
    this.pdfAddSeparator(1, this.innerRectY + this.innerRectHeight, 0, 0, 0);

    return retY;
  }
  private pdfAddTableData(title: string, tableData: Attribute[], y: number): number {
    this.doc.setFontSize(captionFontSize);
    this.doc.setTextColor(106, 106, 106);
    this.doc.text(title, this.panelStartX + tab1, y);
    y += spacer * 0.5;
    this.pdfAddSeparator(3, y, 106, 106, 106);

    y += spacer;
    this.doc.setFontSize(textFontSize);
    for (let i = 0; i < tableData.length; i++) {
      const attr = tableData[i];

      this.doc.setTextColor(106, 106, 106);
      this.doc.text(attr.label, this.panelStartX + tab2, y);
      this.doc.setTextColor(0, 0, 0);
      this.doc.text(`${attr.value}${attr.unit ? ` ${attr.unit}` : ""}`, this.panelEndX - tab2, y, { align: "right" });

      y += spacer * 0.5;
    }

    return y;
  }
  private pdfAddValidationItem(item: ValidationItemProps, y: number) {
    switch (item.type) {
      case ValidationItemType.Dot:
        this.doc.setFillColor(item.color);
        this.doc.circle(this.panelStartX + tab2 + 8, y - 2, 1.5, "F");
        break;
      case ValidationItemType.Segment:
        this.doc.setFillColor(item.color);
        this.doc.rect(this.panelStartX + tab2, y - 3, 16, 2, "F");
        break;
      case ValidationItemType.DashedSegment:
        this.doc.setFillColor(item.color);
        this.doc.rect(this.panelStartX + tab2, y - 3, 4, 2, "F");
        this.doc.rect(this.panelStartX + tab2 + 6, y - 3, 4, 2, "F");
        this.doc.rect(this.panelStartX + tab2 + 12, y - 3, 4, 2, "F");
        break;
      case ValidationItemType.PlumbingPoint:
        this.doc.setLineWidth(1.5);
        this.doc.setFillColor(item.color);
        this.doc.setDrawColor(item.color);
        this.doc.circle(this.panelStartX + tab2 + 2, y - 2, 2.75, "F");
        this.doc.rect(this.panelStartX + tab2 + 4, y - 3, 7, 2, "F");
        this.doc.circle(this.panelStartX + tab2 + 13, y - 2, 2);
        this.doc.setLineWidth(0.25);
        this.doc.setDrawColor(106, 106, 106);
        break;
      case ValidationItemType.DashedLine:
        this.doc.setDrawColor(item.color);
        this.doc.setLineDashPattern([1, 1], 0);
        this.doc.line(this.panelStartX + tab2, y - 2, this.panelStartX + tab2 + 16, y - 2);
        this.doc.setLineDashPattern([], 0);
        this.doc.setDrawColor(106, 106, 106);
        break;
      case ValidationItemType.Room:
        this.doc.setFillColor(item.color.slice(0, 7));
        this.doc.saveGraphicsState();
        this.doc.setGState(new GState({ opacity: 0.2 }));
        this.doc.rect(this.panelStartX + tab2, y - 6, 16, 8, "F");
        this.doc.restoreGraphicsState();
        break;
      case ValidationItemType.Area:
        this.doc.setLineWidth(0.25);
        this.doc.setFillColor(item.color);
        this.doc.setDrawColor(item.borderColor);
        this.doc.rect(this.panelStartX + tab2 + 4, y - 6, 8, 8, "FD");
        this.doc.setDrawColor(106, 106, 106);
        break;
    }
  }
  private pdfAddSeparator(thickness: number, y: number, r: number, g: number, b: number, padding: number = 0): void {
    this.doc.setLineWidth(thickness);
    this.doc.setDrawColor(r, g, b);
    this.doc.line(this.panelStartX + padding, y, this.panelEndX - padding, y);
  }
  private pdfAddScaleRuler(): void {
    const s0 = scaleLineWidthInches / this.architecturalScale.value; // feet per scale line
    const scaleLineWidth = scaleLineWidthInches * ppi; // px
    const scaleLineHeight = 3; // px

    const x = this.innerRectX + this.innerRectWidth - 3 * spacer - scaleLinesCount * scaleLineWidth;
    const y = this.innerRectY + this.innerRectHeight - 3 * spacer;
    const markerY = y - spacer * 0.5;

    this.doc.setLineWidth(scaleLineHeight);
    this.doc.setDrawColor(0, 0, 0);
    this.doc.setTextColor(106, 106, 106);
    this.doc.setFontSize(captionFontSize);

    for (let i = 0; i <= scaleLinesCount; i++) {
      const x0 = x + scaleLineWidth * i;
      const y0 = y + (i % 2 == 0 ? 0 : scaleLineHeight);
      const n = i * s0;

      if (i != scaleLinesCount) {
        this.doc.line(x0, y0, x0 + scaleLineWidth, y0);
      }

      const marker = n.toString() + (i == scaleLinesCount ? " FT" : "");
      this.doc.text(marker, x0, markerY);
    }
  }
}

type Attribute = { label: string; value: string | number; unit?: string };
type FloorData = { floorName: string; items: Attribute[] };
