import { v4 as uuid } from "uuid";
import { appModel } from "../../../models/AppModel";
import { LotLineEdge, LotLineVertex } from "../../../models/LotLine";
import { LotLineManager } from "../../managers/SceneManager/LotLineManager";
import GeometryUtils from "../../utils/GeometryUtils/GeometryUtils";
import SceneUtils from "../../utils/SceneUtils";
import { SoLotLineEdge, SoLotLineVertex } from "../scene/SoLotLine";
import { CommandTypes } from "./CommandTypes";
import { LotItemCommand } from "./LotItemCommand";
import VectorUtils from "../../utils/GeometryUtils/VectorUtils";

interface FloorLotItems {
  edge?: LotLineEdge;
  newVertex?: LotLineVertex;
  newEdge1?: LotLineEdge;
  newEdge2?: LotLineEdge;

  edgeIndex?: number;
  newVertexIndex?: number;
}

export class SplitLotEdgeCommand extends LotItemCommand {
  private soEdge: SoLotLineEdge;
  private newSoVertex: SoLotLineVertex;
  private newSoEdge1: SoLotLineEdge;
  private newSoEdge2: SoLotLineEdge;
  private floorItems: Map<string, FloorLotItems> = new Map();

  constructor(entityId: string) {
    super(entityId);

    this.type = CommandTypes.TranslateBackgroundCommand;
  }

  apply(manager: LotLineManager): void {
    if (!this.soEdge) {
      this.init(manager);
    }

    appModel.activeCorePlan.floors.forEach(floor => {
      const items = this.floorItems.get(floor.id);

      floor.lotLine.addVertex(items.newVertex, items.newVertexIndex);
      floor.lotLine.removeEdge(items.edge);
      floor.lotLine.addEdge(items.newEdge2, items.edgeIndex);
      floor.lotLine.addEdge(items.newEdge1, items.edgeIndex);
    });

    this.soEdge.start.unlinkEdge(this.soEdge);
    this.soEdge.end.unlinkEdge(this.soEdge);
    manager.getSoRoot().remove(this.soEdge);

    SceneUtils.setObjectColor(this.soEdge);

    this.newSoEdge1.start.linkEdge(this.newSoEdge1);
    this.newSoEdge2.end.linkEdge(this.newSoEdge2);
    manager.getSoRoot().add(this.newSoEdge1, this.newSoVertex, this.newSoEdge2);

    manager.updateLotItemsProperties([this.newSoEdge1.userData.id, this.newSoVertex.userData.id, this.newSoEdge2.userData.id], true);
  }

  undo(manager: LotLineManager): void {
    appModel.activeCorePlan.floors.forEach(floor => {
      const items = this.floorItems.get(floor.id);

      floor.lotLine.removeEdge(items.newEdge1);
      floor.lotLine.removeEdge(items.newEdge2);
      floor.lotLine.addEdge(items.edge, items.edgeIndex);
      floor.lotLine.removeVertex(items.newVertex);
    });

    this.newSoEdge1.start.unlinkEdge(this.newSoEdge1);
    this.newSoEdge2.end.unlinkEdge(this.newSoEdge2);
    manager.getSoRoot().remove(this.newSoEdge1, this.newSoVertex, this.newSoEdge2);

    this.soEdge.start.linkEdge(this.soEdge);
    this.soEdge.end.linkEdge(this.soEdge);
    manager.getSoRoot().add(this.soEdge);
  }

  private init(manager: LotLineManager): void {
    this.soEdge = manager.getSoLotItem(this.entityId) as SoLotLineEdge;
    this.newSoVertex = new SoLotLineVertex(this.soEdge.getPosition(), uuid());
    this.newSoEdge1 = new SoLotLineEdge(this.soEdge.start, this.newSoVertex, uuid());
    this.newSoEdge2 = new SoLotLineEdge(this.newSoVertex, this.soEdge.end, uuid());

    appModel.activeCorePlan.floors.forEach(floor => {
      const items: FloorLotItems = {};

      items.edge = floor.lotLine.getLotItem(this.entityId) as LotLineEdge;
      items.newVertex = new LotLineVertex(VectorUtils.Vector3ToVector3V(this.newSoVertex.getPosition()), this.newSoVertex.userData.id);
      items.newEdge1 = new LotLineEdge(items.edge.startId, items.newVertex.id, items.edge.offset, this.newSoEdge1.userData.id);
      items.newEdge2 = new LotLineEdge(items.newVertex.id, items.edge.endId, items.edge.offset, this.newSoEdge2.userData.id);
      items.edgeIndex = floor.lotLine.edges.indexOf(items.edge);
      items.newVertexIndex = floor.lotLine.vertices.findIndex(vertex => vertex.id === items.edge.startId) + 1;

      this.floorItems.set(floor.id, items);
    });
  }
}
