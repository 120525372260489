import { Edge } from "../models/graph/Edge";
import { Graph } from "../models/graph/Graph";
//import { Vertex } from "../models/graph/Vertex";

export class GraphUtils {
  public static getKeyToEdgeFromGraph<T>(graph: Graph<T> | Graph<T>): Map<string, Edge<T>[]> {
    const keyToEdges = new Map<string, Edge<T>[]>();
    graph.getEdges().forEach(edge => {
      const key = edge.keyStr;

      if (keyToEdges.has(key)) {
        keyToEdges.get(key).push(edge);
      } else {
        keyToEdges.set(key, [edge]);
      }
    });
    return keyToEdges;
  }
}
