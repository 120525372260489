import * as THREE from "three";
import { v4 as uuidv4 } from "uuid";

/**
 * Abstract class extending THREE.Group with an added unique identifier.
 */
export abstract class soObject extends THREE.Object3D {
  soId: string;

  /**
   * Constructs an instance of soGroup, generating a unique GUID for the soId.
   */
  constructor() {
    super();
    this.soId = uuidv4();
  }
}
