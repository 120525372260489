import { Side } from "../../../../models/Side";

/**
 * Represents the side information of a room side in the scene.
 */
export default class SoRoomSideInfo {
  private isWallOptional: boolean = true;
  private roomSide: Side;
  /**
   * Initializes a new instance of the SoRoomSideInfo class.
   */
  constructor(side: Side, isWallOptional: boolean = true) {
    this.roomSide = side;
  }

  /**
   * Gets a value indicating whether the wall is optional.
   * @returns {boolean} True if the wall is optional; otherwise, false.
   */
  public get wallOptional(): boolean {
    return this.isWallOptional;
  }

  /**
   * Sets a value indicating whether the wall is optional.
   * @param {boolean} value - True if the wall is optional; otherwise, false.
   */
  public set wallOptional(value: boolean) {
    this.isWallOptional = value;
  }

  /**
   * Gets the room side.
   * @returns {Side} The room side.
   */
  public get side(): Side {
    return this.roomSide;
  }
}
