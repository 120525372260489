import GeometryUtils from "../../utils/GeometryUtils/GeometryUtils";
import VectorUtils from "../../utils/GeometryUtils/VectorUtils";
import { soWall2D } from "../SceneObjects/Wall/soWall2D";

export default class soSpace {
  spaceType: string;
  private _contour: Map<string, soWall2D> = new Map();
  private _innerSegments: Map<string, soWall2D> = new Map();
  private _contourPoints: Set<THREE.Vector3> = new Set();
  containedRoomsIds: string[] = [];
  constructor() {
    // Initialize default properties here
  }
  public addContourWall(wall: soWall2D): void {
    if (!this._contour.has(wall.wallId)) {
      this._contour.set(wall.wallId, wall);
      this._contourPoints.add(VectorUtils.Vector2ToVector3(wall.start));
      this._contourPoints.add(VectorUtils.Vector2ToVector3(wall.end));
    }
  }
  public addinternalWall(wall: soWall2D): void {
    if (!this._contour.has(wall.wallId)) this._innerSegments.set(wall.wallId, wall);
  }
  public addContainedRoom(roomId: string): void {
    if (!this.containedRoomsIds.includes(roomId)) this.containedRoomsIds.push(roomId);
  }
  public addContainedRooms(roomIds: string[]): void {
    roomIds.forEach(roomId => this.addContainedRoom(roomId));
  }
  get contour(): soWall2D[] {
    return Array.from(this._contour.values());
  }
  get innerSegments(): soWall2D[] {
    return Array.from(this._innerSegments.values());
  }
  get contourPoints(): THREE.Vector3[] {
    return Array.from(this._contourPoints);
  }
  public containsSpace(space: soSpace): boolean {
    return space.contourPoints.every(point => this.containsPoint(point));
  }
  public containsPoint(point: THREE.Vector3): boolean {
    return GeometryUtils.isPointInsidePolygon(this.contourPoints, point);
  }
  public containsSegment(segment: soWall2D): boolean {
    return this.containsPoint(VectorUtils.Vector2ToVector3(segment.start)) || this.containsPoint(VectorUtils.Vector2ToVector3(segment.end));
  }
}
