import IntervalTree from "node-interval-tree";
import { EPSILON } from "../../consts";
import MathUtils from "../../utils/MathUtils";
import { Segment } from "../segments/Segment";
import { Graph } from "./Graph";
import { GraphManager } from "../GraphManager";

export default class SweepLine {
  private currPosition: number = NaN;
  private currSegments: Segment[] = [];

  private segmentsTree: IntervalTree<Segment, number>;
  private graph: Graph<Segment> | GraphManager<Segment>;
  private sweepX: boolean; // If TRUE - vertical sweep line will be moved from left to right. otherwise - horizontal from bottom to top

  constructor(segmentTree: IntervalTree<Segment, number>, graph: Graph<Segment> | GraphManager<Segment>, sweepX: boolean) {
    this.segmentsTree = segmentTree;
    this.graph = graph;
    this.sweepX = sweepX;
  }

  moveTo(nextPos: number): void {
    this.currPosition = nextPos;

    const leftSegments: Segment[] = [];
    const rightSegments: Segment[] = [];

    this.currSegments.forEach(segment => {
      const segEndPos = this.sweepX ? segment.end.x : segment.end.y;

      if (MathUtils.areNumbersEqual(segEndPos, this.currPosition)) {
        leftSegments.push(segment);
      } else {
        const splitPoint = this.intersectSegment(segment);
        const left = new Segment(segment.start, splitPoint.clone(), segment.roomId[0], segment.hasWall);
        leftSegments.push(left);

        const right = new Segment(splitPoint.clone(), segment.end, segment.roomId[0], segment.hasWall);
        rightSegments.push(right);
      }
    });

    // Process all left segments, as they will not be affected by next iteration
    this.processLeftSegments(leftSegments);

    // Collect right segment for current position
    const segmentsAtPosition = this.segmentsTree.search(nextPos - EPSILON, nextPos + EPSILON);

    // /*if( _dbg)*/ {
    //   const segmentOnSweepLine = segmentsAtPosition.filter((seg: Line2d) => {
    //     return this.isOnSweepLine(seg.start) && this.isOnSweepLine(seg.end);
    //   });
    //   if (segmentOnSweepLine.length > 0) throw new Error("Unsupported segments");
    // }

    // Keep only segments that start at current positions. Other segments at the position should have been already in this.currSegments from previous iteration
    const newRightSegments = segmentsAtPosition.filter((seg: Segment) => {
      return this.isOnSweepLine(seg.start);
    });
    rightSegments.push(...newRightSegments);

    // when we move to next site, the right segment will become current.
    this.currSegments = rightSegments;
  }

  private isOnSweepLine(point: THREE.Vector2): boolean {
    if (this.sweepX) {
      return MathUtils.areNumbersEqual(point.x, this.currPosition);
    }

    return MathUtils.areNumbersEqual(point.y, this.currPosition);
  }

  private intersectSegment(seg: Segment): THREE.Vector2 {
    let t: number;
    if (this.sweepX) {
      t = (this.currPosition - seg.start.x) / (seg.end.x - seg.start.x);
    } else {
      t = (this.currPosition - seg.start.y) / (seg.end.y - seg.start.y);
    }

    // /*if(_debug)*/ {
    //   if (t < 0 || t > 1) throw Error("Invalid sites for sweep line");
    // }
    return seg.evaluate(t);
  }

  private processLeftSegments(leftSegments: Segment[]): void {
    leftSegments.forEach(segment => {
      this.graph.createEdgeFromNumbers(segment.start.x, segment.start.y, segment.end.x, segment.end.y, segment);
    });
  }
}
