import { IReactionDisposer, reaction } from "mobx";
import * as THREE from "three";
import { appModel } from "../../models/AppModel";
import { CursorStyle } from "../../models/CursorStyle";
import { SceneMode } from "../../models/SceneMode";
import { getIsModalCorePlanWindowOpen } from "../../ui/components/CorePlans/ModalCorePlanWindow";
import { RENDERER_CLEAR_COLOR } from "../consts";
import PreviewManager from "./PreviewManager/PreviewManager";
import RoomManager from "./RoomManager/RoomManager";
import { getIsModalCorePlanPackageWindowOpen } from "../../ui/components/CorePlans/ModalCorePlanPackageWindow";
import RoomEditToolPosition from "../tools/RoomEditToolPosition";
import SceneManager from "./SceneManager/SceneManager";
import { IManager } from "./IManager";

export default class BaseManager {
  private reactions: IReactionDisposer[] = [];

  public renderer: THREE.WebGLRenderer;

  public mousePointer: THREE.Vector2 = new THREE.Vector2();
  public isMouseDown: boolean = false;
  public isMouseHandlersEnabled: boolean = false;
  public isMouseWithinScene: boolean = false;

  public roomManager: any = null;
  public previewManager: PreviewManager = null;

  constructor() {
    // this.roomManager = new SceneManager(this);
    this.roomManager = appModel.featureFlags && appModel.featureFlags["refactor"] ? new SceneManager(this) : new RoomManager(this);
    this.previewManager = new PreviewManager(this);

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.resize = this.resize.bind(this);
  }

  public init(canvas?: HTMLCanvasElement): void {
    this.uninit();

    if (canvas) {
      this.renderer = new THREE.WebGLRenderer({ preserveDrawingBuffer: true, antialias: true, alpha: true, canvas: canvas });
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setClearColor(new THREE.Color(RENDERER_CLEAR_COLOR));
      RoomEditToolPosition.setPosition(false);

      this.renderer.domElement.addEventListener("mousedown", this.onMouseDown);
      this.renderer.domElement.addEventListener("mousemove", this.onMouseMove);
      this.renderer.domElement.addEventListener("mouseup", this.onMouseUp);
      this.renderer.domElement.addEventListener("mouseleave", this.onMouseLeave);

      window.addEventListener("resize", this.resize, false);

      this.subscribe();
      //this.roomManager = appModel.featureFlags && appModel.featureFlags["refactor"] ? new SceneManager(this) : new RoomManager(this);
      this.roomManager.init();
      this.previewManager.init();

      this.onSceneModeChanged();
      this.resize();

      this.render();
    }
  }
  public uninit(): void {
    this.roomManager.uninit();
    this.previewManager.uninit();

    if (this.renderer) {
      this.renderer.domElement.removeEventListener("mousedown", this.onMouseDown);
      this.renderer.domElement.removeEventListener("mousemove", this.onMouseMove);
      this.renderer.domElement.removeEventListener("mouseup", this.onMouseUp);
      this.renderer.domElement.removeEventListener("mouseleave", this.onMouseLeave);
    }

    this.unsubscribe(this.reactions);

    window.removeEventListener("keyup", this.onKeyUp);
    window.removeEventListener("keydown", this.onKeyDown);
    window.removeEventListener("resize", this.resize);

    this.renderer = null;
  }

  public resize(): void {
    const parent = this.getParentContainer();
    const w = parent?.clientWidth;
    const h = parent?.clientHeight;

    this.renderer.setSize(w, h);
    this.roomManager.setSize(w, h);
    this.previewManager.setSize(w, h);
  }

  public getParentContainer(): HTMLElement {
    return this.renderer?.domElement?.parentElement;
  }
  public getCanvas(): HTMLCanvasElement {
    return this.renderer?.domElement || null;
  }
  public setCursorStyle(style: CursorStyle): void {
    const canvas = this.getCanvas();
    if (canvas) {
      canvas.style.cursor = style;
    }
  }
  public getParentContainerRectangle(): DOMRect {
    return this.getParentContainer()?.getBoundingClientRect();
  }

  public updateMouseProperties(clientX: number, clientY: number) {
    const rect = this.renderer?.domElement.getBoundingClientRect();
    this.mousePointer.x = ((clientX - rect.left) / rect.width) * 2 - 1; // -1...1
    this.mousePointer.y = -((clientY - rect.top) / rect.height) * 2 + 1; // -1...1

    this.isMouseWithinScene = this.mousePointer.x <= 1.0 && this.mousePointer.x >= -1.0 && this.mousePointer.y <= 1.0 && this.mousePointer.y >= -1.0;
  }

  // --------------------------------------

  private subscribe(): void {
    this.reactions.push(reaction(() => getIsModalCorePlanWindowOpen(), this.onIsModalCorePlanWindowOpenChanged.bind(this), { fireImmediately: true }));
    this.reactions.push(
      reaction(() => getIsModalCorePlanPackageWindowOpen(), this.onIsModalCorePlanPackageWindowOpenChanged.bind(this), { fireImmediately: true })
    );
    this.reactions.push(reaction(() => appModel.sceneMode, this.onSceneModeChanged.bind(this)));
    this.reactions.push(reaction(() => appModel.sceneEditorMode, this.onSceneEditorModeChanged.bind(this)));
  }
  private unsubscribe(reactions: IReactionDisposer[]): void {
    reactions.forEach(r => r());
    reactions.length = 0;
  }

  private render(): void {
    if (appModel.sceneMode == SceneMode.Editor) {
      this.roomManager.render();
    } else if (appModel.sceneMode == SceneMode.Preview) {
      this.previewManager.render();
    }

    if (this.renderer) {
      requestAnimationFrame(this.render.bind(this));
    }
  }

  private onSceneModeChanged(): void {
    if (appModel.sceneMode !== SceneMode.Editor) {
      this.roomManager.uninit();
    } else {
      this.roomManager.init();
    }
    this.roomManager.setActive(appModel.sceneMode == SceneMode.Editor);
    this.previewManager.setActive(appModel.sceneMode == SceneMode.Preview);
  }
  private onSceneEditorModeChanged(): void {
    this.setCursorStyle(CursorStyle.Default);
  }
  private onIsModalCorePlanWindowOpenChanged(isOpen: boolean): void {
    if (isOpen) {
      window.removeEventListener("keyup", this.onKeyUp);
      window.removeEventListener("keydown", this.onKeyDown);
    } else {
      window.addEventListener("keyup", this.onKeyUp);
      window.addEventListener("keydown", this.onKeyDown);
    }
  }
  private onIsModalCorePlanPackageWindowOpenChanged(isOpen: boolean): void {
    if (isOpen) {
      window.removeEventListener("keyup", this.onKeyUp);
      window.removeEventListener("keydown", this.onKeyDown);
    } else {
      window.addEventListener("keyup", this.onKeyUp);
      window.addEventListener("keydown", this.onKeyDown);
    }
  }

  private onMouseDown(e: MouseEvent): void {
    this.isMouseDown = true;
    this.isMouseHandlersEnabled = true;

    if (appModel.sceneMode == SceneMode.Editor) {
      this.roomManager.onMouseDown(e);
    }
  }
  private onMouseMove(e: MouseEvent): void {
    this.updateMouseProperties(e.clientX, e.clientY);

    if (appModel.sceneMode == SceneMode.Editor) {
      this.roomManager.onMouseMove(e);
    } else if (appModel.sceneMode == SceneMode.Preview) {
      this.previewManager.onMouseMove(e);
    }
  }
  private onMouseLeave(e: MouseEvent): void {
    if (appModel.sceneMode == SceneMode.Editor) {
      this.roomManager.onMouseLeave(e);
    }

    this.isMouseHandlersEnabled = false;
  }
  private onMouseUp(e: MouseEvent): void {
    if (appModel.sceneMode == SceneMode.Editor) {
      this.roomManager.onMouseUp(e);
    } else if (appModel.sceneMode == SceneMode.Preview) {
      this.previewManager.onMouseUp(e);
    }

    this.isMouseDown = false;
    this.isMouseHandlersEnabled = false;
  }

  private onKeyDown(e: KeyboardEvent) {
    if (appModel.sceneMode == SceneMode.Editor) {
      this.roomManager.onKeyDown(e);
    }
  }
  private onKeyUp(e: KeyboardEvent) {
    if (appModel.sceneMode == SceneMode.Editor) {
      this.roomManager.onKeyUp(e);
    }
  }
}
