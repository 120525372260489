import * as THREE from "three";
import { Side } from "../../models/Side";
import SceneUtils from "../utils/SceneUtils";
import RoomUtils from "../utils/RoomUtils";
import { EPSILON } from "../consts";
import GeometryUtils from "../utils/GeometryUtils/GeometryUtils";

/**
 * Represents a snapping event between two rooms.
 */
class SnapEvent {
  /**
   * The primary room involved in the snapping event.
   */
  public primaryRoom: THREE.Object3D;

  /**
   * The side of the primary room where snapping occurs.
   */
  public primaryRoomSide: Side;

  /**
   * The secondary room involved in the snapping event.
   */
  public secondaryRoom: THREE.Object3D;

  /**
   * The side of the secondary room where snapping occurs.
   */
  public secondaryRoomSide: Side;

  /**
   * The vector representing the snapping direction and magnitude.
   */
  public snapVector: THREE.Vector2;

  /**
   * The absolute distance of the snapping.
   */
  public snapAbsDistance: number;

  /**
   * The snapping tolerance value.
   */
  public tolerance: number;
  /**
   * Indicates whether the rooms are snapped.
   */
  public WallShiftDistance: number = null;
  public CalculateWallShiftDistance(): void {
    this.WallShiftDistance = RoomUtils.calculateWallSnapCompenstationOffset(this);
  }
  public get StillSnappedCheck(): boolean {
    const { primarySide, secondarySide } = this.calculateSnapping();
    if (primarySide === null || secondarySide === null || primarySide != this.primaryRoomSide || secondarySide != this.secondaryRoomSide) {
      return false;
    } else {
      return true;
    }
  }
  /**
   * The event key used to identify the snapping event.
   */
  public get EventKey(): string {
    return [this.primaryRoomSide, this.secondaryRoomSide, this.primaryRoom.userData.id, this.secondaryRoom.userData.id].join(",");
  }
  public get SecondaryRoomEventKey(): string {
    return [this.secondaryRoomSide, this.primaryRoomSide, this.secondaryRoom.userData.id, this.primaryRoom.userData.id].join(",");
  }
  /**
   * Callback function to be called when snapping is calculated.
   */

  /**
   * Creates an instance of SnapEvent.
   * @param primaryRoom - The primary room involved in the snapping event.
   * @param secondaryRoom - The secondary room involved in the snapping event.
   * @param tolerance - The snapping tolerance value.
   */
  constructor(primaryRoom: THREE.Object3D, secondaryRoom: THREE.Object3D, tolerance: number = EPSILON) {
    this.primaryRoom = primaryRoom;
    this.secondaryRoom = secondaryRoom;
    this.tolerance = tolerance;

    // Initialize other properties
    this.primaryRoomSide = null;
    this.secondaryRoomSide = null;
    this.snapVector = new THREE.Vector2();
    this.snapAbsDistance = 0;

    // Perform snapping calculations
    const { primarySide, secondarySide } = this.calculateSnapping();
    this.primaryRoomSide = primarySide;
    this.secondaryRoomSide = secondarySide;
    if (this.primaryRoomSide != null && this.secondaryRoomSide != null) {
      this.CalculateWallShiftDistance();
    }
  }

  /**
   * Calculates the snapping vector, sides involved, and snapping distances.
   */
  public calculateSnapping(): { primarySide: Side; secondarySide: Side } {
    // Get bounding boxes of the rooms
    if (!this.primaryRoom || !this.secondaryRoom) return { primarySide: null, secondarySide: null };
    const bbPrimary = RoomUtils.getRoomBoundingBoxByModelLines(this.primaryRoom);
    const bbSecondary = RoomUtils.getRoomBoundingBoxByModelLines(this.secondaryRoom);

    // Compute centers and sizes of the bounding boxes
    const centerPrimary = bbPrimary.getCenter(new THREE.Vector3());
    const sizePrimary = bbPrimary.getSize(new THREE.Vector3());
    const centerSecondary = bbSecondary.getCenter(new THREE.Vector3());
    const sizeSecondary = bbSecondary.getSize(new THREE.Vector3());

    // Calculate distances along X and Y axes
    let distanceX = Math.abs(centerPrimary.x - centerSecondary.x) - sizePrimary.x / 2 - sizeSecondary.x / 2;
    let distanceY = Math.abs(centerPrimary.y - centerSecondary.y) - sizePrimary.y / 2 - sizeSecondary.y / 2;

    // Correct small floating-point inaccuracies
    distanceX = Math.abs(distanceX) < Number.EPSILON ? 0 : Math.abs(distanceX);
    distanceY = Math.abs(distanceY) < Number.EPSILON ? 0 : Math.abs(distanceY);

    // Determine if snapping is within tolerance
    if (distanceX <= this.tolerance || distanceY <= this.tolerance) {
      // Determine snapping sides
      return this.determineSnappingSides(bbPrimary, bbSecondary, distanceX, distanceY);
    } else {
      return { primarySide: null, secondarySide: null };
    }
  }

  /**
   * Determines the sides of the primary and secondary rooms involved in snapping.
   * @param bbPrimary - Bounding box of the primary room.
   * @param bbSecondary - Bounding box of the secondary room.
   * @param distanceX - Distance along the X axis.
   * @param distanceY - Distance along the Y axis.
   */
  private determineSnappingSides(
    bbPrimary: THREE.Box3,
    bbSecondary: THREE.Box3,
    distanceX: number,
    distanceY: number
  ): { primarySide: Side; secondarySide: Side } {
    let primarySide: Side = null;
    let secondarySide: Side = null;

    // Determine sides based on positions
    if (distanceX <= this.tolerance) {
      if (bbPrimary.min.x >= bbSecondary.max.x) {
        primarySide = Side.left;
        secondarySide = Side.right;
      } else if (bbPrimary.max.x <= bbSecondary.min.x) {
        primarySide = Side.right;
        secondarySide = Side.left;
      }
    }

    if (distanceY <= this.tolerance) {
      if (bbPrimary.max.y <= bbSecondary.min.y) {
        primarySide = Side.top;
        secondarySide = Side.bottom;
      } else if (bbPrimary.min.y >= bbSecondary.max.y) {
        primarySide = Side.bottom;
        secondarySide = Side.top;
      }
    }

    return { primarySide, secondarySide };
  }
}

export default SnapEvent;
