import * as THREE from "three";
import { soObject } from "../soObject";
import RoomUtils from "../../../utils/RoomUtils";
import { RawRoomEntity } from "../../../../services/rawTypes";
import { deepCopy2DTO } from "../../../../helpers/pojo";
import { EntityDataType } from "../../../../entities/entityManager";
import LineUtils from "../../../utils/GeometryUtils/LineUtils";
import GeometryUtils from "../../../utils/GeometryUtils/GeometryUtils";
import { Direction } from "../../Direction";

/**
 * Class representing a boundary line in the scene.
 * Inherits from soObject.
 */
export class soBoundaryLine extends soObject {
  line: THREE.Line;
  lineType: string;
  fileId: string;
  bindingId: string;
  properties: string[];
  private parentRoomSide: string = "";
  /**
   * Constructs an instance of soBoundaryLine.
   * @param soId - soId representing the line id.
   * @param line - THREE.Line representing the boundary line.
   */
  constructor(soId: string, line: THREE.Line) {
    super();

    this.soId = soId;
    this.line = line;
    if (line) {
      //this.add(line);
    }
    this.lineType = line.name;
  }
  get line3(): THREE.Line3 {
    return LineUtils.LineToLine3(this.line);
  }
  get IsVertical(): boolean {
    return GeometryUtils.getLineDirection(this.line3) == Direction.Vertical;
  }
  get IsHorizontal(): boolean {
    return GeometryUtils.getLineDirection(this.line3) == Direction.Horizontal;
  }
  get ParentRoomSide(): string {
    return this.parentRoomSide;
  }
  set ParentRoomSide(value: string) {
    this.parentRoomSide = value;
  }
  get MidPoint(): THREE.Vector3 {
    const line = this.line3;
    return new THREE.Vector3((line.start.x + line.end.x) / 2, (line.start.y + line.end.y) / 2, 0);
  }
}
