import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { DTOConvertOptions, entityManager } from "../../../entities/entityManager";
import { settings } from "../../../entities/settings";
import { showToastMessage } from "../../../helpers/messages";
import { isEmpty } from "../../../helpers/pojo";
import { arrayIdSet } from "../../../helpers/utilities";
import { appModel } from "../../../models/AppModel";
import { CorePlan, CorePlanFormData } from "../../../models/CorePlan";
import { LotLineSide, CorePlanAttributes, convert2feet } from "../../../models/CorePlanAttributes";
import LoadingSpinner from "../common/LoadingSpinner";
import { ContentMode } from "./ModalCorePlanWindow";
import CorePlanDetailsEditor, { getFloorCoverages } from "./CorePlanDetailsEditor";
import "./CorePlanRequestContent.sass";
import { corePlanLockModalState } from "./CorePlanLockModal";
import { LennarCorePlan } from "../../../models/LennarCorePlanData";
import { runInAction } from "mobx";

interface CorePlanRequestContentProps {
  corePlan: CorePlan;
  mode: ContentMode;
  onSuccess: (corePlan: CorePlan) => void;
}

let CorePlanRequestContent = ({ corePlan: corePlanProp, mode, onSuccess }: CorePlanRequestContentProps) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { corePlanDefaults } = settings.values;
  const defaultNewCorePlanValues = useMemo(() => {
    const attributes = {
      lotSize: corePlanDefaults.lotArea,
      lotLength: corePlanDefaults.lotLength,
      lotWidth: corePlanDefaults.lotWidth,
      isNotRect: true,
      floors: corePlanDefaults.numOfStories,
      bedRooms: corePlanDefaults.numOfBedrooms,
      garage: corePlanDefaults.numOfCars,
      bathRooms: corePlanDefaults.numOfBathrooms,
      halfBathRooms: corePlanDefaults.numOfHalfBathrooms,
      minNetArea: corePlanDefaults.minBuildingNetArea,
      floorThickness: corePlanDefaults.floorThickness,
      maxGrossArea: corePlanDefaults.maxBuildingGrossArea,
      lotLineSetback: {
        front: corePlanDefaults.lotFrontSetback,
        side: corePlanDefaults.lotSideSetback,
        rear: corePlanDefaults.lotBackSetback,
        front2: corePlanDefaults.lotFront2Setback,
      },
      lotLineSideAssociation: {
        left: LotLineSide.Side,
        top: LotLineSide.Rear,
        right: LotLineSide.Side,
      },
      floorCoverages: getFloorCoverages(corePlanDefaults.numOfStories),
    } as CorePlanAttributes;

    return {
      id: "",
      name: "",
      lennar_id: null,
      state: "",
      firstFloorToPlateHeight: settings.values.corePlanDefaults.floorToPlateLevelHeight,
      upperFloorToPlateHeight: settings.values.corePlanDefaults.floorToPlateLevelHeight,
      // width: 0,
      series: "",
      // location_id: "",
      _uploadFiles: [],
      notes: [],
      attributes: convert2feet(attributes),
    };
  }, [corePlanDefaults]);

  const [formData, setFormData] = useState<CorePlanFormData>(defaultNewCorePlanValues);
  const [corePlan, setCorePlan] = useState<CorePlan>();
  const [didLotLineChange, setDidLotLineChange] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  //const corePlansData = await entityManager.pullList(LennarCorePlan);
  const isClone = mode === ContentMode.Clone;
  const isEditMode: boolean = mode === ContentMode.Edit;

  useEffect(() => {
    // Force update 'formData' if 'defaultNewCorePlanValues' has been changed
    setFormData(defaultNewCorePlanValues);
  }, [defaultNewCorePlanValues]);

  useEffect(() => {
    let isNewCorePlan = mode === ContentMode.New;

    let corePlan: CorePlan;
    if (!isNewCorePlan) {
      corePlan = corePlanProp;
      setDidLotLineChange(corePlan?.floors?.some(f => f.lotLine));
      if (isClone) {
        isNewCorePlan = true;
        corePlan = corePlan.clone(true);
      }
    } else {
      defaultNewCorePlanValues._uploadFiles = [];
      formData.attributes = defaultNewCorePlanValues.attributes;
      formData._isNewCorePlan = true;
      corePlan = new CorePlan().fromDTO(formData, "FormData");
    }

    const corePlanData = corePlan.toDTO("FormData") as unknown as CorePlanFormData;
    setCorePlan(corePlan);
    const newFormData = { ...corePlanData, _isNewCorePlan: isNewCorePlan };
    if (isClone) {
      newFormData._copyAllFiles = false;
    }
    setFormData(newFormData);
  }, [corePlanProp?.id]);

  const handleInputData = input => e => {
    const { value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [input]: value,
    }));
  };

  const onCorePlanSave = async () => {
    setIsSaving(true);

    const savedCorePlan = corePlan.toDTO();
    corePlan.fromDTO(formData, "FormData");
    const saveMode: DTOConvertOptions = { scope: "single" };
    const areFloorsChanged = corePlan.changeFloorCount(corePlan.attributes.floors);
    if (formData._isNewCorePlan) {
      saveMode.scope = "full";
      saveMode.mode = "new";
    } else if (areFloorsChanged) {
      saveMode.scope = "full";
      if (appModel.activeFloor) {
        appModel.baseManager.roomManager.updateRoomDrawings(false);
      }
    }

    corePlan.ext_info ||= {};
    corePlan.ext_info.outdateCost = true;

    if (isClone) {
      // TODO create own endpoint
      corePlan.ext_info.runOnce = { act: "clone" };
      if (formData._copyAllFiles) {
        corePlan.ext_info.runOnce.actProps = { files: "all" };
      }
    }

    await entityManager
      .save(corePlan, "APIdata", saveMode)
      .then(response => {
        if (isEmpty(response.result)) {
          corePlan.fromDTO(savedCorePlan);
        } else {
          formData._isNewCorePlan = undefined;
          corePlan.clearUploadFiles();
          defaultNewCorePlanValues._uploadFiles = [];
          arrayIdSet(appModel.corePlans, response.result);

          const message = isEditMode ? `${response.result.name} was successfully updated` : `${response.result.name} was successfully added`;
          showToastMessage("Success", message, { autoClose: 4000 });

          onSuccess(response.result);
        }
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const onCorePlanSaveAndGoToEditor = async () => {
    await onCorePlanSave();

    try {
      setIsLoading(true);
      const newest = await entityManager.getCorePlan(corePlan.id);
      if (!newest) {
        return;
      }
      arrayIdSet(appModel.corePlans, newest);

      if (newest.isLockedByCurrentUser() || !newest.isLocked()) {
        history.push(`/editor/${newest.id}`);
        return;
      }

      // Open corePlan lock dialog
      corePlanLockModalState.setCorePlan(newest);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="corePlan-request">
      <h2>Core Plan Brief</h2>
      {
        <section className="form-step step-1">
          <CorePlanDetailsEditor
            handleFormData={handleInputData}
            corePlanData={formData}
            isEditMode={isEditMode}
            isSaving={isSaving}
            onCorePlanSave={onCorePlanSave}
            onCorePlanSaveAndGoToEditor={onCorePlanSaveAndGoToEditor}
          />
        </section>
      }
      {isSaving && (
        <div className="spinner-container corePlan-saving-spinner">
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};

CorePlanRequestContent = observer(CorePlanRequestContent);
export default CorePlanRequestContent;
