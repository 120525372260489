import { soGroup } from "../soGroup";
import { soRoom2D } from "../Room/soRoom2D";
import { GraphAnalysisUtils } from "../../../utils/GraphAnalysisUtils";
import SceneManager from "../../../managers/SceneManager/SceneManager";
import SceneUtils from "../../../utils/SceneUtils";
import { MessageKindsEnum, showToastMessage } from "../../../../helpers/messages";
import { MESSAGE_DURATION, ROOM_OVERLAP_MESSAGE } from "../../../consts";
import GeometryUtils from "../../../utils/GeometryUtils/GeometryUtils";
import WallManager from "../../graph/WallManager";
import { MergeSegmentsMode } from "../../SegmentsMergeMode";
import { soWall2D } from "../Wall/soWall2D";
import { appModel } from "../../../../models/AppModel";

/**
 * Class representing a 2D floor in the scene.
 * Inherits from soGroup to allow handling multiple child components.
 */
export class soFloor2D extends soGroup {
  floorName: string;
  soRooms: soRoom2D[] = [];
  floorIndex: number;
  fileId: string;
  bindingId: string;
  properties: any[];
  wallManager: WallManager = null;

  /**
   * Constructs an instance of soFloor2D.
   * @param floorName - Name of the floor.
   * @param soRooms - Array of rooms contained in the floor.
   * @param floorIndex - Index of the floor.
   */
  constructor(floorId, floorName, floorIndex) {
    super();
    this.soId = floorId;
    this.floorName = floorName;
    this.floorIndex = floorIndex;
    this.wallManager = new WallManager(this);
    this.add(this.wallManager);
  }

  addRoom(soRoom: soRoom2D, updateWallManager = true): void {
    if (soRoom && !this.soRooms.includes(soRoom)) {
      soRoom.ParentFloorId = this.soId;
      this.soRooms.push(soRoom);
      this.add(soRoom);
    }
    if (updateWallManager) this.wallManager.addRooms([soRoom]);
  }
  addRooms(soRooms: soRoom2D[], updateWallManager = true): void {
    soRooms.forEach(soRoom => (soRoom.ParentFloorId = this.soId));
    this.soRooms.push(...soRooms);
    this.add(...soRooms);
    if (updateWallManager) this.wallManager.addRooms(soRooms);
  }
  removeRoom(soRoom: soRoom2D, dispose: boolean = true): void {
    const index = this.soRooms.indexOf(soRoom);
    this.wallManager.removeRooms([soRoom]);
    if (index > -1) {
      this.soRooms.splice(index, 1);
      this.remove(soRoom);
      if (dispose) GeometryUtils.disposeObject(soRoom);
    }
  }
  removeRooms(soRooms: soRoom2D[], dispose: boolean = true): void {
    this.wallManager.removeRooms(soRooms);
    soRooms.forEach(soRoom => {
      const index = this.soRooms.indexOf(soRoom);
      if (index > -1) {
        this.soRooms.splice(index, 1);
        this.remove(soRoom);
        if (dispose) GeometryUtils.disposeObject(soRoom);
      }
    });
  }
  IsRoomInWallManager(soRoom: soRoom2D | string): boolean {
    const roomId = soRoom instanceof soRoom2D ? soRoom.soId : soRoom;
    return this.wallManager.HasRoom(roomId);
  }
  getRoomById(roomId: string): soRoom2D | undefined {
    return this.soRooms.find(room => room.soId === roomId);
  }
  getWallById(wallId: string): soWall2D | undefined {
    if (!this.wallManager) return null;
    return this.wallManager.getWallById(wallId);
  }
  UpdateWallsGeometry(): void {
    this.wallManager.updateWallsGeometry();
  }

  getWallsByIds(wallIds: string[]): soWall2D[] {
    return this.wallManager.getWallsByIds(wallIds);
  }
  getWalls(): soWall2D[] {
    return this.wallManager.getWalls();
  }
  checkRoomsOverlapping(showErrorMessage = true): void {
    const intersectedRooms: Set<soRoom2D> = new Set<soRoom2D>();
    const toUnHighLight: Set<soRoom2D> = new Set<soRoom2D>();

    const soRooms = this.soRooms;

    for (let i = 0; i < soRooms.length; i++) {
      // reset helper properties
      delete soRooms[i].userData.isOverlapped;
      delete soRooms[i].userData.isTooCloseToOther;

      for (let j = i + 1; j < soRooms.length; j++) {
        if (intersectedRooms.has(soRooms[i]) && intersectedRooms.has(soRooms[j])) {
          continue;
        }
        if (SceneUtils.areSoRoomsOverlapping(soRooms[i], soRooms[j])) {
          intersectedRooms.add(soRooms[i]);
          intersectedRooms.add(soRooms[j]);
        }
      }

      if (!intersectedRooms.has(soRooms[i])) {
        toUnHighLight.add(soRooms[i]);
      }
    }

    intersectedRooms.forEach(room => SceneUtils.highlightIntersectedRoom(room));
    toUnHighLight.forEach(room => SceneUtils.unhighlightIntersectedRoom(room));

    if (showErrorMessage && soRooms.some(soRoom => soRoom.userData.isOverlapped)) {
      showToastMessage(MessageKindsEnum.Error, ROOM_OVERLAP_MESSAGE, { autoClose: MESSAGE_DURATION });
    }
  }
  public collectSegments(
    soRooms: soRoom2D[] = [],
    mergeSegmentsMode = MergeSegmentsMode.SameRoom,
    excludeOutdoorRooms = true
  ): { externalSegments: soWall2D[]; internalSegments: soWall2D[] } {
    if (soRooms.length === 0) soRooms = this.soRooms;
    if (excludeOutdoorRooms) {
      soRooms = soRooms.filter(soRoom => appModel.getRoomType(soRoom.userData.roomTypeId).attributes.indoor);
    }

    const walls = this.wallManager.getWalls();
    const externalSegments = walls.filter(wall => wall.isExternal);
    const internalSegments = walls.filter(wall => !wall.isExternal);
    return { externalSegments, internalSegments };
  }
}
