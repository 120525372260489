import { Vertex } from "./Vertex";

// Undirected edge
export class Edge<T> {
  v1: Vertex;
  v2: Vertex;
  data: T;

  constructor(v1: Vertex, v2: Vertex, data: T) {
    if (v1.id < v2.id) {
      this.v1 = v1;
      this.v2 = v2;
    } else {
      this.v1 = v2;
      this.v2 = v1;
    }
    this.data = data;
  }

  get keyStr(): string {
    return "[" + this.v1.id + ", " + this.v2.id + "]";
  }
}
