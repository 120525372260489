import * as THREE from "three";
import GeometryUtils from "../../utils/GeometryUtils/GeometryUtils";
import { appModel } from "../../../models/AppModel";
import { IReactionDisposer, reaction } from "mobx";
import UnitsUtils from "../../utils/UnitsUtils";
// import SceneUtils from "../../utils/SceneUtils";
import {
  GLOBAL_GRID_RENDER_ORDER,
  GLOBAL_RULERS_RENDER_ORDER,
  RULER_BACKGROUND_COLOR,
  GRID_MAJOR_CELL_RATIO,
  GRID_MIN_CELL_SIZE_CUTOFF_2D,
  GRID_MINOR_CELL_RATIO,
  RULER_MARKER_COLOR,
  // RULER_TEXT_COLOR,
} from "../../consts";
import RoomManager from "./RoomManager";
import { settings } from "../../../entities/settings";
import MathUtils from "../../utils/MathUtils";
import { WebAppUISettingsKeys } from "../../../entities/settings/types";
import { IManager } from "../IManager";

export default class GridAndRulersManager implements IManager {
  private reactions: IReactionDisposer[] = [];
  private soRoot: THREE.Group = new THREE.Group();
  private soGrid: THREE.Group = new THREE.Group();
  private soTopRuler: THREE.Group = new THREE.Group();
  private soLeftRuler: THREE.Group = new THREE.Group();

  private gridMinorLineMaterial = new THREE.LineDashedMaterial({
    dashSize: UnitsUtils.getGridMinorLineDashSize(),
    gapSize: UnitsUtils.getGridMinorLineDashSize(),
    color: settings.getColorNumber(WebAppUISettingsKeys.gridSecondaryColor),
    transparent: true,
    opacity: 1.0,
  }); // 0.5 unit
  private gridMediumLineMaterial = new THREE.LineBasicMaterial({
    color: settings.getColorNumber(WebAppUISettingsKeys.gridSecondaryColor),
    transparent: true,
    opacity: 1.0,
  }); // 1 unit
  private gridMajorLineMaterial = new THREE.LineBasicMaterial({
    color: settings.getColorNumber(WebAppUISettingsKeys.gridPrimaryColor),
    transparent: true,
    opacity: 1.0,
  }); // 5 units
  private rulerBackgroundMaterial = new THREE.MeshBasicMaterial({
    color: new THREE.Color(RULER_BACKGROUND_COLOR),
    transparent: true,
    opacity: 1.0,
  });
  private rulerMarkerMaterial = new THREE.LineBasicMaterial({ color: RULER_MARKER_COLOR, transparent: true, opacity: 1.0 });
  // private rulerTextMaterial = new THREE.MeshBasicMaterial({ color: new THREE.Color(RULER_TEXT_COLOR), transparent: true, opacity: 1.0 });

  // helper parameters
  private sbb: THREE.Box3;
  private viewportSize: THREE.Vector3;
  private viewportCenter: THREE.Vector3;
  private cellSize: number;
  private minorCellSize: number;
  private majorCellSize: number;
  private minorCellSizePixels: number;
  private cellSizePixels: number;
  private minorCellsInMajorCell: number;
  private minorCellsCountX: number;
  private minorCellsCountY: number;
  private rulerThickness: number;
  private majorOffsetFactor: THREE.Vector3;
  private textScale: number;

  constructor(public roomManager: RoomManager) {
    this.soRoot.name = "Scene Grid and Rulers Manager Root";
    this.roomManager.soGridAndRulersRoot.add(this.soRoot);

    this.soGrid.name = "Scene Grid";
    this.soGrid.renderOrder = GLOBAL_GRID_RENDER_ORDER;
    this.soRoot.add(this.soGrid);

    this.soTopRuler.name = "Scene Top Ruler";
    this.soTopRuler.renderOrder = GLOBAL_RULERS_RENDER_ORDER;
    this.soRoot.add(this.soTopRuler);

    this.soLeftRuler.name = "Scene Left Ruler";
    this.soLeftRuler.renderOrder = GLOBAL_RULERS_RENDER_ORDER;
    this.soRoot.add(this.soLeftRuler);

    this.reactions.push(reaction(() => appModel.gridUnitSizeInches, this.onGridUnitSizeChanged.bind(this)));
    this.reactions.push(reaction(() => appModel.showGrid, this.onShowGridChanged.bind(this)));
    this.reactions.push(reaction(() => appModel.showGridOverPlan, this.onShowGridOverPlanChanged.bind(this)));
  }

  public reset(): void {
    GeometryUtils.disposeObject(this.soGrid);
    GeometryUtils.disposeObject(this.soTopRuler);
    GeometryUtils.disposeObject(this.soLeftRuler);
  }
  public update(): void {
    this.reset();

    this.updateParameters();
    this.updateSoGrid();
    this.updateSoRulers();
  }

  public setColors() {
    this.gridMinorLineMaterial = new THREE.LineDashedMaterial({
      dashSize: UnitsUtils.getGridMinorLineDashSize(),
      gapSize: UnitsUtils.getGridMinorLineDashSize(),
      color: settings.getColorNumber(WebAppUISettingsKeys.gridSecondaryColor),
      transparent: true,
      opacity: 1.0,
    }); // 0.5 unit
    this.gridMediumLineMaterial = new THREE.LineBasicMaterial({
      color: settings.getColorNumber(WebAppUISettingsKeys.gridSecondaryColor),
      transparent: true,
      opacity: 1.0,
    }); // 1 unit
    this.gridMajorLineMaterial = new THREE.LineBasicMaterial({
      color: settings.getColorNumber(WebAppUISettingsKeys.gridPrimaryColor),
      transparent: true,
      opacity: 1.0,
    }); // 5 units
  }

  public setVisibility(state: boolean) {
    this.soRoot.visible = state;
  }

  private onGridUnitSizeChanged(): void {
    this.update();
  }
  private onShowGridChanged(): void {
    this.update();
  }
  private onShowGridOverPlanChanged(val: boolean): void {
    this.update();
  }

  private updateParameters(): void {
    this.sbb = this.getViewportBoundingBox();
    this.viewportSize = this.sbb.getSize(new THREE.Vector3());
    this.viewportCenter = this.sbb.getCenter(new THREE.Vector3());

    // in scene units
    this.cellSize = UnitsUtils.getGridCellSize();
    this.minorCellSize = this.cellSize / GRID_MINOR_CELL_RATIO;
    this.majorCellSize = this.cellSize * GRID_MAJOR_CELL_RATIO;

    this.minorCellSizePixels = this.minorCellSize * GeometryUtils.getSceneUnitPixels(this.roomManager.camera, this.roomManager.baseManager.renderer);
    this.cellSizePixels = this.minorCellSizePixels * GRID_MINOR_CELL_RATIO;

    const extraMajorCellsCount = 1; // add extra major cell beyond the viewport
    this.minorCellsInMajorCell = GRID_MINOR_CELL_RATIO * GRID_MAJOR_CELL_RATIO;
    const extraMinorCellsCount = extraMajorCellsCount * this.minorCellsInMajorCell;

    // minor steps in both semi-axes
    this.minorCellsCountX = MathUtils.ceil(this.viewportSize.x / this.minorCellSize / 2.0, 1) + extraMinorCellsCount;
    this.minorCellsCountY = MathUtils.ceil(this.viewportSize.y / this.minorCellSize / 2.0, 1) + extraMinorCellsCount;

    this.majorOffsetFactor = new THREE.Vector3(
      MathUtils.round(this.viewportCenter.x / this.majorCellSize, 1),
      MathUtils.round(this.viewportCenter.y / this.majorCellSize, 1),
      0.0
    );

    this.textScale = this.roomManager.getDistanceFactor();

    //this.rulerThickness = UnitsManager.getRulerThickness() * this.roomManager.getDistanceFactor(); // with labels
    this.rulerThickness = UnitsUtils.getRulerThickness() * 0.7 * this.roomManager.getDistanceFactor(); // w/o labels
  }

  private updateSoRulers(): void {
    // top ruler
    GeometryUtils.disposeObject(this.soTopRuler);
    let soBackgroundPanel = new THREE.Mesh(new THREE.PlaneBufferGeometry(this.viewportSize.x, this.rulerThickness), this.rulerBackgroundMaterial);
    soBackgroundPanel.position.copy(new THREE.Vector3(this.viewportCenter.x, this.sbb.max.y - this.rulerThickness / 2.0, 0.0));
    this.soTopRuler.add(soBackgroundPanel);
    for (let idx = -this.minorCellsCountX; idx <= this.minorCellsCountX; idx++) {
      this.addRulerMarker(idx, true);
    }

    // left ruler
    GeometryUtils.disposeObject(this.soLeftRuler);
    soBackgroundPanel = new THREE.Mesh(new THREE.PlaneBufferGeometry(this.rulerThickness, this.viewportSize.y), this.rulerBackgroundMaterial);
    soBackgroundPanel.position.copy(new THREE.Vector3(this.sbb.min.x + this.rulerThickness / 2.0, this.viewportCenter.y, 0.0));
    this.soLeftRuler.add(soBackgroundPanel);
    for (let idx = -this.minorCellsCountY; idx <= this.minorCellsCountY; idx++) {
      this.addRulerMarker(idx, false);
    }

    // corner patch
    const soCornerPatch = new THREE.Mesh(new THREE.PlaneBufferGeometry(this.rulerThickness, this.rulerThickness), this.rulerBackgroundMaterial);
    soCornerPatch.position.copy(new THREE.Vector3(this.sbb.min.x + this.rulerThickness / 2.0, this.sbb.max.y - this.rulerThickness / 2.0, 0.01));
    soCornerPatch.renderOrder = GLOBAL_RULERS_RENDER_ORDER + 1;
    this.soLeftRuler.add(soCornerPatch);
  }
  private addRulerMarker(idx: number, isTopRuler: boolean): void {
    const isMajorLine = idx % (GRID_MAJOR_CELL_RATIO * 2) == 0;
    const isMediumLine = !isMajorLine && idx % 2 == 0;
    const isMinorLine = !isMajorLine && !isMediumLine;

    const isMinorLineVisible = this.minorCellSizePixels >= GRID_MIN_CELL_SIZE_CUTOFF_2D;
    const isMediumLineVisible = this.cellSizePixels >= GRID_MIN_CELL_SIZE_CUTOFF_2D;

    if (isMinorLine && !isMinorLineVisible) {
      return;
    }
    if (isMediumLine && !isMediumLineVisible) {
      return;
    }

    const offset = this.majorOffsetFactor.clone().multiplyScalar(this.majorCellSize);
    const markerLength = this.rulerThickness * (isMajorLine ? 0.6 : isMediumLine ? 0.47 : 0.35);
    const labelOffset = this.rulerThickness * 0.5;

    let vertices: THREE.Vector3[];
    let labelPosition: THREE.Vector3;
    if (isTopRuler) {
      const x = this.minorCellSize * idx + offset.x;
      labelPosition = new THREE.Vector3(x, this.sbb.max.y - labelOffset, 0.0);
      vertices = [new THREE.Vector3(x, this.sbb.max.y, 0.0), new THREE.Vector3(x, this.sbb.max.y - markerLength, 0.0)];
    } else {
      const y = this.minorCellSize * idx + offset.y;
      labelPosition = new THREE.Vector3(this.sbb.min.x + labelOffset, y, 0.0);
      vertices = [new THREE.Vector3(this.sbb.min.x, y, 0.0), new THREE.Vector3(this.sbb.min.x + markerLength, y, 0.0)];
    }

    const soMarker = new THREE.Line(new THREE.BufferGeometry().setFromPoints(vertices), this.rulerMarkerMaterial);
    (isTopRuler ? this.soTopRuler : this.soLeftRuler).add(soMarker);

    // label:
    const n = ((idx + (isTopRuler ? this.majorOffsetFactor.x : this.majorOffsetFactor.y) * this.minorCellsInMajorCell) * appModel.gridUnitSizeInches) / 2.0;
    if (isMinorLineVisible) {
      if (isMajorLine || isMediumLine) {
        this.addRulerMarkerLabel(n.toString(), labelPosition, isTopRuler);
      }
    } else {
      if (isMajorLine) {
        this.addRulerMarkerLabel(n.toString(), labelPosition, isTopRuler);
      }
    }
  }
  private addRulerMarkerLabel(n: string, pos: THREE.Vector3, isTopRuler: boolean): void {
    // const geometry = new THREE.ShapeBufferGeometry(SceneUtils.Font.generateShapes(n.toString(), UnitsUtils.getRulerFontSize()));
    // const soLabel = new THREE.Mesh(geometry, this.rulerTextMaterial);
    // soLabel.name = "Ruler Marker Label";
    // soLabel.scale.setScalar(this.textScale);
    // const size = GeometryUtils.getGeometryBoundingBox2D(soLabel).getSize(new THREE.Vector3());
    // const x = isTopRuler ? pos.x - size.x / 2.0 : pos.x;
    // const y = isTopRuler ? pos.y - size.y : pos.y - size.y / 2.0;
    // soLabel.position.set(x, y, 0.0);
    // (isTopRuler ? this.soTopRuler : this.soLeftRuler).add(soLabel);
  }

  private updateSoGrid(): void {
    GeometryUtils.disposeObject(this.soGrid);
    this.soGrid.visible = appModel.showGrid;

    // vertical lines
    for (let idx = -this.minorCellsCountX; idx <= this.minorCellsCountX; idx++) {
      this.addGridLine(idx, true);
    }
    // horizontal lines
    for (let idx = -this.minorCellsCountY; idx <= this.minorCellsCountY; idx++) {
      this.addGridLine(idx, false);
    }

    // grid is calculated relative to (0; 0);
    // need to shift it regarding the viewport origin offset, proportionally to integer major cells count
    this.soGrid.position.copy(this.majorOffsetFactor.clone().multiplyScalar(this.majorCellSize));
  }
  private addGridLine(idx: number, isVerticalLine: boolean): void {
    const isMajorLine = idx % (GRID_MAJOR_CELL_RATIO * 2) == 0;
    const isMediumLine = !isMajorLine && idx % 2 == 0;
    const isMinorLine = !isMajorLine && !isMediumLine;

    const isMinorLineVisible = this.minorCellSizePixels >= GRID_MIN_CELL_SIZE_CUTOFF_2D;
    const isMediumLineVisible = this.cellSizePixels >= GRID_MIN_CELL_SIZE_CUTOFF_2D;

    if (isMinorLine && !isMinorLineVisible) {
      return;
    }
    if (isMediumLine && !isMediumLineVisible) {
      return;
    }

    let vertices: THREE.Vector3[];
    if (isVerticalLine) {
      const x = this.minorCellSize * idx;
      const y = this.minorCellSize * this.minorCellsCountY;
      vertices = [new THREE.Vector3(x, -y, 0.0), new THREE.Vector3(x, y, 0.0)];
    } else {
      const y = this.minorCellSize * idx;
      const x = this.minorCellSize * this.minorCellsCountX;
      vertices = [new THREE.Vector3(-x, y, 0.0), new THREE.Vector3(x, y, 0.0)];
    }

    const material = isMajorLine ? this.gridMajorLineMaterial : isMediumLine ? this.gridMediumLineMaterial : this.gridMinorLineMaterial;
    material.transparent = appModel.showGridOverPlan;

    const line = new THREE.Line(new THREE.BufferGeometry().setFromPoints(vertices), material);
    if (isMinorLine) {
      line.computeLineDistances();
    }
    line.renderOrder = appModel.showGridOverPlan ? GLOBAL_RULERS_RENDER_ORDER : GLOBAL_GRID_RENDER_ORDER;
    this.soGrid.add(line);
  }

  private getViewportBoundingBox(): THREE.Box3 {
    const raycaster = new THREE.Raycaster();
    raycaster.params.Line.threshold = 0.001;
    raycaster.params.Points.threshold = 0.001;

    raycaster.setFromCamera(new THREE.Vector2(-1.0, -1.0), this.roomManager.camera);
    const iMin = raycaster.intersectObject(this.roomManager.intersectionsPlane);
    //log.debug("iMin", iMin);
    const pMin = iMin[0]?.point || new THREE.Vector3();
    //log.debug("pMin", pMin);

    raycaster.setFromCamera(new THREE.Vector2(1.0, 1.0), this.roomManager.camera);
    const iMax = raycaster.intersectObject(this.roomManager.intersectionsPlane);
    //log.debug("iMax", iMax);
    const pMax = iMax[0]?.point || new THREE.Vector3();
    //log.debug("pMax", pMax);

    return new THREE.Box3(new THREE.Vector3(pMin.x, pMin.y, 0.0), new THREE.Vector3(pMax.x, pMax.y, 0.0));
  }
}
