import { appModel } from "../../../models/AppModel";
import { Room } from "../../../models/Room";
import RoomManager from "../../managers/SceneManager/SceneManager";
import { CommandTypes } from "./CommandTypes";
import { RoomCommand } from "./RoomCommand";
/**
 * @deprecated use soPasteRoomCommand
 */
export class PasteRoomCommand extends RoomCommand {
  public copiedRoomAppliedCommandsNumber: number;
  private pastedSoRoom: THREE.Object3D;
  private pastedRoom: Room;

  constructor(
    roomId: string,
    public copiedRoomId: string,
    private position: THREE.Vector3
  ) {
    super(roomId);

    this.type = CommandTypes.PasteRoomCommand;
  }

  apply(roomManager: RoomManager): void {
    const activeSoFloor = roomManager.getActiveSoFloor();
    activeSoFloor.add(this.pastedSoRoom);
    appModel.activeFloor.addRoom(this.pastedRoom);

    roomManager.checkRoomsSharedObjects([this.entityId]);
  }

  undo(roomManager: RoomManager): void {
    const activeSoFloor = roomManager.getActiveSoFloor();
    const soRoom = roomManager.getActiveFloorSoRoom(this.entityId);
    roomManager.selectRoom(soRoom, false);
    activeSoFloor.remove(soRoom);
    this.pastedSoRoom = soRoom;
    this.pastedRoom = appModel.activeFloor.rooms.find(r => r.id === this.pastedSoRoom.userData.id);
    appModel.activeFloor.removeRoom(this.pastedRoom.id);
  }
}
