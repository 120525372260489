import RoomManager from "../../managers/SceneManager/SceneManager";
import SceneUtils from "../../utils/SceneUtils";
import { RoomCommand } from "./RoomCommand";

export class TranslateRoomOpeningCommand extends RoomCommand {
  private isLocked: boolean;

  constructor(
    private soOpening: THREE.Object3D,
    private oldDistance: number,
    private newDistance: number
  ) {
    super(soOpening.userData.id);

    this.isLocked = soOpening.userData.isLocked;
  }

  public apply(roomManager: RoomManager): void {
    SceneUtils.moveOpening(this.soOpening, this.newDistance);
    this.soOpening.userData.isLocked = true;

    roomManager.updateRoomsProperties([this.soOpening.parent.userData.id]);
    roomManager.roomOpeningManager.updateOpeningMarker(this.soOpening);
  }

  public undo(roomManager: RoomManager): void {
    SceneUtils.moveOpening(this.soOpening, this.oldDistance);
    this.soOpening.userData.isLocked = this.isLocked; // Restore initial value.

    roomManager.updateRoomsProperties([this.soOpening.parent.userData.id]);
    roomManager.roomOpeningManager.updateOpeningMarker(this.soOpening);
  }
}
