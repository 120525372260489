import * as THREE from "three";
import MathUtils from "../../utils/MathUtils";
import { Vertex } from "./Vertex";
import { Edge } from "./Edge";
import { soRoom2D } from "../SceneObjects/Room/soRoom2D";

////////////////////////////////////////////////////////////////////////////////////////////////////////
// Graph
////////////////////////////////////////////////////////////////////////////////////////////////////////

export class Graph<T> {
  private vertices: Vertex[] = [];
  private edges: Edge<T>[] = [];
  private soRooms: soRoom2D[] = [];

  public createEdgeFromPoints(p1: THREE.Vector2, p2: THREE.Vector2, data: T): Edge<T> {
    return this.createEdgeFromNumbers(p1.x, p1.y, p2.x, p2.y, data);
  }

  public createEdgeFromNumbers(x1: number, y1: number, x2: number, y2: number, data: T): Edge<T> {
    const v1 = this.getOrCreateVertex(x1, y1);
    const v2 = this.getOrCreateVertex(x2, y2);
    return this.createEdge(v1, v2, data);
  }

  public createEdge(v1: Vertex, v2: Vertex, data: T): Edge<T> {
    const edge = new Edge(v1, v2, data);
    this.edges.push(edge);
    return edge;
  }

  public getLinkedEdges(vertex: Vertex): Array<Edge<T>> {
    return this.edges.filter(edge => edge.v1 === vertex || edge.v2 === vertex);
  }

  public getVertices(): Vertex[] {
    return this.vertices;
  }
  public getEdges(): Edge<T>[] {
    return this.edges;
  }

  public getVertexByPoint(p: THREE.Vector2): Vertex {
    return this.getVertexByNumbers(p.x, p.y);
  }

  public removeEdge(edge: Edge<T>): void {
    const idx = this.edges.findIndex(e => e === edge);

    if (idx !== -1) {
      this.edges.splice(idx, 1);
    }
  }

  public clear(): void {
    this.vertices.length = 0;
    this.edges.length = 0;
  }

  private getVertexByNumbers(x: number, y: number): Vertex {
    const id = this.findIndex(x, y);

    if (id >= 0) {
      return this.vertices[id];
    }

    return null;
  }

  public getOrCreateVertex(x: number, y: number): Vertex {
    let vertex = this.getVertexByNumbers(x, y);

    if (!vertex) {
      vertex = new Vertex(new THREE.Vector2(x, y));
      this.vertices.push(vertex);
    }

    return vertex;
  }

  // Optimize the search using some sort of spatial index
  // Probably generate hashmap CellID -> Vertex[]. As the cellId can be used something like this:
  //
  // [MathUtils.floor(v.x * 10 / EPSILON, 1), [MathUtils.floor(v.y * 10 / EPSILON, 1)]
  private findIndex(x: number, y: number): number {
    return this.vertices.findIndex(vertex => MathUtils.areNumbersEqual(x, vertex.point.x) && MathUtils.areNumbersEqual(y, vertex.point.y));
  }
}
