import React, { useContext, useEffect } from "react";
import { basicLogger, LDSingleKindContext, useLDClient, withLDProvider } from "launchdarkly-react-client-sdk";
import AuthContext from "./store/auth-context";
import { initializeMessageHandler } from "./helpers/messages";
import App from "./App";

const WrappedApp = ({ userContext }: { userContext: LDSingleKindContext }) => {
  const ldClient = useLDClient();
  useEffect(() => {
    const initialize = async () => {
      if (ldClient) {
        await ldClient.identify(userContext);
        await initializeMessageHandler(ldClient);
      }
    };
    initialize();
  }, [ldClient, userContext]);

  return <App />;
};

const LDProviderWrapper = () => {
  const authCtx = useContext(AuthContext);

  const userContext: LDSingleKindContext = {
    kind: "user",
    key: authCtx.user?.id || "anonymous", // Unique identifier for the user
    name: authCtx.user?.username || "anonymous", // Optional: user name/email
  };

  const LDWrappedApp = withLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID,
    context: userContext,
    timeout: 5, // recommended
    options: {
      //bootstrap: "localStorage",
      streaming: true,
      logger: basicLogger({ level: "error" }),
    },
  })(() => <WrappedApp userContext={userContext} />);

  return <LDWrappedApp />;
};

export default LDProviderWrapper;
