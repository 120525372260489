import { Typography } from "antd";

import { areaToEnUsLocaleString } from "../../../../../../helpers/measures";
import { AreaTakeOff } from "../../../../../../editor/models/ValidationResult";
import { appModel } from "../../../../../../models/AppModel";

interface LevelAreaItems {
  floorId?: string;
  livableArea: AreaTakeOff;
  garageArea: AreaTakeOff;
  porchesArea: AreaTakeOff;
}

const areaItem = (label: string, value: number) => {
  return (
    <div className="area-row">
      <span>{label}</span>
      <span>{areaToEnUsLocaleString(value)} sqft</span>
    </div>
  );
};

const LevelAreaItems = ({ livableArea, garageArea, porchesArea, floorId = "" }: LevelAreaItems) => {
  const title = floorId ? `${appModel.activeCorePlan.floors.find(f => f.id === floorId)?.index + 1}st Floor` : "Total";
  const areaItems = [
    livableArea.value ? areaItem(livableArea.title, livableArea.value) : null,
    garageArea.value ? areaItem(garageArea.title, garageArea.value) : null,
    porchesArea.value ? areaItem(porchesArea.title, porchesArea.value) : null,
  ].filter(item => item);

  return (
    <div key={title} className="level-areas">
      <Typography.Paragraph className="legend-text">{title}</Typography.Paragraph>
      {areaItems.length ? [...areaItems] : " --"}
    </div>
  );
};

export default LevelAreaItems;
