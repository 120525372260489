import { reaction } from "mobx";
import * as THREE from "three";
import { appModel } from "../../models/AppModel";
import { CursorStyle } from "../../models/CursorStyle";
import RoomManager from "../managers/RoomManager/RoomManager";
import { DragMode } from "../models/DragMode";
import { SceneEditorMode } from "../models/SceneEditorMode";
import { MeasureLine } from "../models/scene/MeasureLine";
import GeometryUtils from "../utils/GeometryUtils/GeometryUtils";
import { Keys } from "../models/Keys";
import SceneManager from "../managers/SceneManager/SceneManager";

export default class MeasureTool {
  private soRoot = new THREE.Group();
  private dragMode = DragMode.none;

  constructor(public roomManager: any) {
    if (!(this.roomManager instanceof RoomManager) && !(this.roomManager instanceof SceneManager)) {
      throw new Error("Manager is not an instance of RoomManager");
    }
    this.soRoot.name = "Measurement Tool Root";
    this.roomManager.getSoRoot().add(this.soRoot);

    this.onKeyUp = this.onKeyUp.bind(this);

    reaction(() => appModel.sceneEditorMode, this.onSceneEditModeChanged.bind(this));
  }

  public onMouseMove(e: MouseEvent) {
    switch (this.dragMode) {
      case DragMode.measurement: {
        this.updateLine();
      }
    }
  }
  public onMouseLeave(e: MouseEvent): void {
    if (this.dragMode !== DragMode.none) {
      this.handleDragFinish();
      this.disposeLine();
    }
  }
  public onMouseUp(e: MouseEvent) {
    if (this.dragMode === DragMode.none) {
      if (e.button === 0) {
        this.dragMode = DragMode.measurement;
        this.addLine();
      }
      if (e.button === 2) {
        this.disposeLine();
      }

      return;
    } else if (this.dragMode === DragMode.measurement) {
      if (e.button === 2) {
        this.disposeLine();
      }
    }

    this.handleDragFinish();
  }
  public onKeyUp(e: KeyboardEvent) {
    if (e.code == Keys.Esc && this.dragMode === DragMode.measurement) {
      this.disposeLine();
      this.dragMode = DragMode.none;
    }
  }

  private onSceneEditModeChanged(mode: SceneEditorMode, oldMode: SceneEditorMode): void {
    if (oldMode === SceneEditorMode.Measurement) {
      this.disposeLine();
    }
  }

  private addLine(): void {
    this.disposeLine();

    const soLine = new MeasureLine(this.roomManager.intersectionPoint.clone());
    this.soRoot.add(soLine);
  }
  private getLine(): MeasureLine {
    return this.soRoot.children.find(child => child instanceof MeasureLine) as MeasureLine;
  }
  private updateLine(): void {
    const soLine = this.getLine();
    if (soLine) {
      soLine.setEndPoint(this.roomManager.intersectionPoint.clone());
    }
  }
  private disposeLine(): void {
    const soLine = this.getLine();
    if (soLine) {
      this.soRoot.remove(soLine);
      GeometryUtils.disposeObject(soLine);
    }
  }

  /**
   * Perform necessary operations based on the current drag mode and reset the drag mode to none.
   */
  private handleDragFinish(): void {
    if (this.dragMode !== DragMode.none) {
      this.dragMode = DragMode.none;
      this.roomManager.controls.noPan = false;
      this.roomManager.baseManager.setCursorStyle(CursorStyle.Default);
    }
  }
}
