import { soGroup } from "../soGroup";
import { soStretchObject } from "./soStretchObject";

/**
 * Class representing a stretchable triangle in the scene.
 * Inherits from soStretchObject.
 */
export class soStretchTriangle extends soStretchObject {
  // Additional attributes and methods can be added here in future.
}
