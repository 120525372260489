import { LDClient, LDFlagSet } from "launchdarkly-js-client-sdk";
import log from "loglevel";
import { toast, Id, ToastOptions } from "react-toastify";

export const MessageKindsEnum = {
  Message: 10,
  Success: 11,
  Info: 12,
  Warning: 14,
  Error: 16,
  Server: 18,
  FATAL: 19,
};

export const ErrorKindsEnum = {
  Error: 16,
  Server: 18,
  FATAL: 19,
};

type MessageType = keyof typeof MessageKindsEnum | number;

let ldClient: LDClient;
let cachedFlags: LDFlagSet | null = null;

export const initializeMessageHandler = async (client: LDClient) => {
  ldClient = client;
  cachedFlags = await ldClient.allFlags();
};

export function showToastMessage(msgType: MessageType, message: string, options?: ToastOptions): Id {
  const isStudioErrorDisplayEnabled = !ldClient || !cachedFlags ? false : cachedFlags["studio-error-display"] ?? false;

  if (typeof msgType === "number") {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    msgType = Object.entries(MessageKindsEnum).find(pair => pair[1] == msgType)?.[0] || "Message";
  }

  if (msgType === "Server") {
    log.error("Server", message);
    return 0;
  }

  options = Object.assign({ theme: "dark" }, options || {});
  if (msgType == "Info") {
    return toast.info(message, options);
  } else if (msgType == "Success") {
    return toast.success(message, options);
  }

  if (isStudioErrorDisplayEnabled) {
    switch (msgType) {
      case "Warning":
        return toast.warning(message, options);
      case "Error":
      case "FATAL":
        return toast.error(message, options);
      default:
        console.log(message);
        return 0;
    }
  } else {
    switch (msgType) {
      case "Warning":
      case "Error":
      case "FATAL":
        console.error(message);
        return 0;
      default:
        console.log(message);
        return 0;
    }
  }
}
