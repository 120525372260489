import * as THREE from "three";
import GeometryUtils from "../utils/GeometryUtils/GeometryUtils";

export class ReferenceLine {
  public startParent: THREE.Object3D;
  public endParent: THREE.Object3D;
  public stretchedDistance: number = 0;

  constructor(
    public id: string,
    public min: number,
    public max: number,
    public currentStretch: number
  ) {}

  get start() {
    if (!this.startParent) {
      return new THREE.Vector3();
    }
    const geometry = (this.startParent as any).geometry as THREE.BufferGeometry;
    const points = GeometryUtils.getPointsPositions(geometry);
    const localStart = GeometryUtils.getLineMidPoint2(points[0], points[1]);
    return localStart.applyMatrix4(this.startParent.matrixWorld);
  }

  get end() {
    if (!this.endParent) {
      return new THREE.Vector3();
    }
    const geometry = (this.endParent as any).geometry as THREE.BufferGeometry;
    const points = GeometryUtils.getPointsPositions(geometry);
    const localEnd = GeometryUtils.getLineMidPoint2(points[0], points[1]);
    return localEnd.applyMatrix4(this.endParent.matrixWorld);
  }
}
