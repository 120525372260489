import log from "loglevel";
import * as THREE from "three";
import { revitFiles } from "../../entities/revitFiles";
import { inches2feet } from "../../helpers/measures";
import { removeFileExtension } from "../../helpers/utilities";
import { appModel } from "../../models/AppModel";
import { RoomEntityType } from "../../models/RoomEntityType";
import RoomManager from "../managers/RoomManager/RoomManager";
import { Segment } from "../models/segments/Segment";
import SceneUtils from "../utils/SceneUtils";
import UnitsUtils from "../utils/UnitsUtils";
import RoofUtils from "../utils/RoofUtils";
import { IRoofEdge } from "../../models/Roof";
import apiProvider from "../../services/api/utilities/Provider";
import { getAxiosRequestConfig } from "../../services/api/utilities";
import axios from "axios";
import RoomUtils from "../utils/RoomUtils";
import FloorUtils from "../utils/FloorUtils";
import SceneManager from "../managers/SceneManager/SceneManager";
import configuration from "../../entities/configuration/Configuration";
import { soWall2D } from "../models/SceneObjects/Wall/soWall2D";
import { FuncCode } from "../../entities/catalogSettings/types";
import { soFloor2D } from "../models/SceneObjects/Floor/soFloor2D";

export default class CorePlanJsonTool {
  constructor(private roomManager: RoomManager | SceneManager) {
    this.generateCorePlanJson = this.generateCorePlanJson.bind(this);
  }

  public async generateCorePlanJson(): Promise<File> {
    const data = await this.getCorePlanData();
    const jsonFileName = `${appModel.activeCorePlan.name} - CorePlan JSON.json`;

    return new File([JSON.stringify(data, undefined, 2)], jsonFileName, { type: "application/json" });
  }

  /**
   * Convert corePlan data into serializable format
   */
  public async getCorePlanData(activeVariationName: string = ""): Promise<any> {
    RoofUtils.calculateActiveCorePlanRoofs();
    const corePlanSoRooms = this.roomManager.getCorePlanSoRooms();
    const bboxes = new Map<string, THREE.Box3>();
    corePlanSoRooms.forEach(soRoom => {
      bboxes.set(soRoom.uuid, RoomUtils.getRoomBoundingBoxByModelLines(soRoom));
    });
    const corePlanBbox = new THREE.Box3();

    corePlanSoRooms.forEach(soRoom => {
      if (appModel.getRoomType(soRoom.userData.roomTypeId).attributes.indoor) {
        corePlanBbox.union(bboxes.get(soRoom.uuid));
      }
    });
    if (corePlanBbox.isEmpty()) {
      corePlanBbox.expandByPoint(new THREE.Vector3());
    }

    const corePlanMinPos = corePlanBbox.min;

    const firstFloorToFloorHeight = inches2feet(appModel.activeCorePlan.firstFloorToFloorHeight);
    const upperFloorToFloorHeight = inches2feet(appModel.activeCorePlan.upperFloorToFloorHeight);
    //const walls = this.roomManager.validationTool.getExtendedWallTypesResult();
    const levels = await Promise.all(
      appModel.activeCorePlan.floors.map(async floor => {
        const areaContours = FloorUtils.calculateAreaContours(appModel.baseManager.roomManager.getSoFloor(floor.id), this.roomManager);
        const { liveableFloorSpaces: livableAreaContours, garageFloorSpaces: garageAreaContours } = areaContours.indoorAreaContour;
        const { floorSpaces: porchesAndPatiosAreaContours } = areaContours.outdoorAreaContour;

        const areaTypes = [
          { contours: livableAreaContours, type: "livableArea" },
          { contours: garageAreaContours, type: "garageArea" },
          { contours: porchesAndPatiosAreaContours, type: "porchesAndPatiosArea" },
        ];

        const arcAreas = areaTypes.flatMap(({ contours, type }) =>
          contours.map(floorSpace => {
            const spaceContourPoints = floorSpace.space.contourPoints;
            return {
              areaType: type,
              areaContourPoints: spaceContourPoints.map(point => ({
                point: {
                  x: UnitsUtils.inchesToFeet(point.x - corePlanMinPos.x),
                  y: UnitsUtils.inchesToFeet(point.y - corePlanMinPos.y),
                  z: UnitsUtils.inchesToFeet(point.z - corePlanMinPos.z),
                },
              })),
            };
          })
        );

        const roofs = await Promise.all(
          floor.roofs.map(async roof => {
            const edgesDataJson = JSON.stringify(roof.roofEdges, null, null);
            try {
              const url = `${apiProvider.host}create-roof`;
              const jsonBody = { json: edgesDataJson };
              const axiosConfig = getAxiosRequestConfig();
              const response = await axios.post(url, jsonBody, axiosConfig);
              const jsonObject = JSON.stringify(response.data);
              const roofSurfacesData = JSON.parse(jsonObject);
              const gables = await SceneUtils.getGables(jsonObject, floor.rooms);

              const gablePanelType = []; // gables
              for (let i = 0; i < gables.length; i++) {
                const finish = gables[i]
                  .getOverlappingRooms(floor.rooms)[0]
                  .getFinishByIndex(gables[i].getOverlappingRooms(floor.rooms)[0].gableExteriorFinishes?.[gables[i].index] ?? -1);
                gablePanelType.push((finish?.panelFamily && finish?.panelType && `${finish.panelFamily}⇨${finish.panelType}`) || null);
              }

              const getEdgesFromGeometry = (geometry: THREE.Geometry) => {
                const edges = [];

                if (geometry.vertices.length > 0) {
                  // Iterate over vertices to create edges
                  for (let i = 0; i < geometry.vertices.length - 1; i++) {
                    const edge = [geometry.vertices[i], geometry.vertices[i + 1]];
                    edges.push(edge);
                  }

                  // Add edge between the last and the first vertex
                  const lastEdge = [geometry.vertices[geometry.vertices.length - 1], geometry.vertices[0]];
                  edges.push(lastEdge);
                }

                return edges.map(edge => ({
                  startNode: {
                    x: UnitsUtils.inchesToFeet(edge[0].x - corePlanMinPos.x),
                    y: UnitsUtils.inchesToFeet(edge[0].y - corePlanMinPos.y),
                    z: UnitsUtils.inchesToFeet(edge[0].z - corePlanMinPos.z),
                  },
                  endNode: {
                    x: UnitsUtils.inchesToFeet(edge[1].x - corePlanMinPos.x),
                    y: UnitsUtils.inchesToFeet(edge[1].y - corePlanMinPos.y),
                    z: UnitsUtils.inchesToFeet(edge[1].z - corePlanMinPos.z),
                  },
                }));
              };

              return {
                gables: gables.map((gable, index) => ({
                  gablePanelType: gablePanelType[index],
                  gableEdges: getEdgesFromGeometry(gable.geometry as THREE.Geometry),
                })),
                roofEdges: roof.roofEdges.map((edge: IRoofEdge) => ({
                  startNode: {
                    x: UnitsUtils.inchesToFeet(edge.startNode.x - corePlanMinPos.x),
                    y: UnitsUtils.inchesToFeet(edge.startNode.y - corePlanMinPos.y),
                  },
                  endNode: {
                    x: UnitsUtils.inchesToFeet(edge.endNode.x - corePlanMinPos.x),
                    y: UnitsUtils.inchesToFeet(edge.endNode.y - corePlanMinPos.y),
                  },
                  slope: edge.slope,
                  gableDepth: edge.gableDepth,
                })),
                roofSurfaces: roofSurfacesData.RoofSurface.map(roofSurface => ({
                  surfaceEdges: roofSurface.Edges.map(edge => ({
                    startNode: {
                      x: UnitsUtils.inchesToFeet(edge.StartNode.X - corePlanMinPos.x),
                      y: UnitsUtils.inchesToFeet(edge.StartNode.Y - corePlanMinPos.y),
                      z: UnitsUtils.inchesToFeet(edge.StartNode.Z - corePlanMinPos.z),
                    },
                    endNode: {
                      x: UnitsUtils.inchesToFeet(edge.EndNode.X - corePlanMinPos.x),
                      y: UnitsUtils.inchesToFeet(edge.EndNode.Y - corePlanMinPos.y),
                      z: UnitsUtils.inchesToFeet(edge.EndNode.Z - corePlanMinPos.z),
                    },
                    edgeType: edge.EdgeType,
                  })),
                  surfaceSlope: roofSurface.Slope,
                  surfaceInnerHoles: roofSurface.HolesInSurface?.map(hole => ({
                    holeNodes: hole.map(holeNode => ({
                      x: UnitsUtils.inchesToFeet(holeNode.Node.X - corePlanMinPos.x),
                      y: UnitsUtils.inchesToFeet(holeNode.Node.Y - corePlanMinPos.y),
                      z: UnitsUtils.inchesToFeet(holeNode.Node.Z - corePlanMinPos.z),
                    })),
                  })),
                })),
              };
            } catch (error) {
              console.error("Error sending data:", error);
            }
          })
        );

        const configurationData = configuration.configurationData;
        const rooms = floor.rooms.map(room => {
          const soRoom = corePlanSoRooms.find(pr => pr.userData.id === room.id);

          const bb = bboxes.get(soRoom.uuid);

          const newMinX = bb.min.x;
          const newMinY = bb.min.y;
          const newMaxX = bb.max.x;
          const newMaxY = bb.max.y;

          const sizeX = newMaxX - newMinX;
          const sizeY = newMaxY - newMinY;

          const roomTypeId = soRoom.userData?.roomTypeId;
          const roomType = appModel.getRoomType(roomTypeId || "?");
          const roomCategory = roomType?.roomCategoryId ? appModel.getRoomCategory(roomType.roomCategoryId) : null;

          const openings = soRoom.children
            .filter(
              soObject =>
                (soObject.userData.type === RoomEntityType.Window || soObject.userData.type === RoomEntityType.Door) && soObject.userData.shiftDistance
            )
            .map(soOpening => ({
              id: soOpening.userData.revitId,
              shiftDistance: UnitsUtils.inchesToFeet(soOpening.userData.shiftDistance),
              offset: 0,
            }));

          const preciseCenterX = newMinX + sizeX / 2 - corePlanMinPos.x;
          const preciseCenterY = newMinY + sizeY / 2 - corePlanMinPos.y;

          const roomName = {
            name: roomCategory?.name ?? null,
            function: roomType?.settings?.function ?? null,
            type: roomType?.settings?.typeNumber ?? null,
          };

          if (!Object.values(roomName).every(Boolean)) {
            log.error(`Part of room name by (${roomTypeId}) not found`, room.toDTO());
          }

          const [isMirrHorizontal, isMirrVertical] = [room.mirroring, false];
          const [absSizeX, absSizeY] = [UnitsUtils.inchesToFeet(sizeX), UnitsUtils.inchesToFeet(sizeY)];
          const sizeXY = [isMirrHorizontal ? -absSizeX : absSizeX, isMirrVertical ? -absSizeY : absSizeY];
          const centerXY = [UnitsUtils.inchesToFeet(preciseCenterX), UnitsUtils.inchesToFeet(preciseCenterY)];
          const rotation90CounterClockWise = CorePlanJsonTool.rotationAngleToCounterClockWise(room.rotation);
          //const panelType = (room.finish?.panelFamily && room.finish?.panelType && `${room.finish.panelFamily}⇨${room.finish.panelType}`) || null;
          const panelType = []; // walls
          if (room.exteriorFinishes != null) {
            for (let i = 0; i < room.exteriorFinishes.length; i++) {
              const finish = room.getFinishByIndex(room.exteriorFinishes[i]);
              panelType[i] = (finish?.panelFamily && finish?.panelType && `${finish.panelFamily}⇨${finish.panelType}`) || null;
            }
          }
          const gablePanelType = []; // gables
          if (room.gableExteriorFinishes != null) {
            for (let i = 0; i < room.gableExteriorFinishes.length; i++) {
              const finish = room.getFinishByIndex(room.gableExteriorFinishes[i]);
              gablePanelType[i] = (finish?.panelFamily && finish?.panelType && `${finish.panelFamily}⇨${finish.panelType}`) || null;
            }
          }

          // Add floorSlope if matching configuration is found
          const matchingConfig = configurationData.rooms.find(
            conf => conf.room_category === roomName.name && conf.function === roomName.function && conf.room_name === roomName.type
          );

          const floorSlope = matchingConfig
            ? {
                ratio: matchingConfig.slope.ratio,
                directionXY: [matchingConfig.slope.direction[0], matchingConfig.slope.direction[1]],
              }
            : null;

          return {
            category: roomName.name,
            function: roomName.function,
            type: roomName.type,
            centerXY,
            sizeXY,
            rotation90CounterClockWise,
            openings,
            floorSlope,
            panelType,
            gablePanelType,
          };
        });

        const allWalls = (this.roomManager.getActiveSoFloor() as soFloor2D).getWalls();
        const walls = allWalls.filter(wall => wall.HasGeometry);
        const noWalls = allWalls.filter(wall => !wall.HasGeometry);

        return {
          name: floor.name,
          elevation: floor.index === 0 ? floor.index * firstFloorToFloorHeight : firstFloorToFloorHeight + (floor.index - 1) * upperFloorToFloorHeight,
          roofs,
          rooms,
          arcAreas,

          walls: walls.map(w => this.WallToJson(w, corePlanMinPos, true, true, "", true)) || [],
          noWalls: noWalls.map(w => this.WallToJson(w, corePlanMinPos)) || [],
          gravityWalls: [],
          shearWalls: [],

          beams: [],
          spatialWalls: {
            DDL: [],
            GRG: [],
          },
        };
      })
    );

    levels.push({
      name: "Roof",
      elevation:
        appModel.activeCorePlan.floors.length === 1
          ? firstFloorToFloorHeight
          : firstFloorToFloorHeight + (appModel.activeCorePlan.floors.length - 1) * upperFloorToFloorHeight,
    } as any);

    return {
      corePlanName: appModel.activeCorePlan.name,
      corePlanID: appModel.activeCorePlan.lennar_id,
      variationName: activeVariationName,
      levels,
      catalog: revitFiles.isFilesExists ? removeFileExtension(revitFiles.files.catalogFile.uri) : null,
    };
  }

  private WallToJson(
    wallSource: soWall2D,
    shift: THREE.Vector3,
    addClassification: boolean = false,
    addFunctionCode: boolean = false,
    type?: string,
    addOffset: boolean = false
  ): any {
    const wall = appModel.findModifiedSegmentWall(wallSource) || wallSource;
    let functionCode = wall.getWallFunctionCode();
    // TODO: TEMPROARY FIX!!!!: because DEngine doesnt support for now EXT_2X4/6_GARG its replaced with EXT_2X4/6
    const stringsToFix = ["EXT_2X4_GARG", "EXT_2X6_GARG"];
    functionCode = stringsToFix.includes(functionCode) ? functionCode.replace("_GARG", "") : functionCode;
    const offset = UnitsUtils.inchesToFeet(wall.wallOffset?.getSignedDistance()) || 0;
    const startPointX = UnitsUtils.inchesToFeet(wall.start.x - shift.x);
    const startPointY = UnitsUtils.inchesToFeet(wall.start.y - shift.y);
    const endPointX = UnitsUtils.inchesToFeet(wall.end.x - shift.x);
    const endPointY = UnitsUtils.inchesToFeet(wall.end.y - shift.y);
    return {
      startPoint: [startPointX, startPointY],
      endPoint: [endPointX, endPointY],
      ...(type ? { type } : {}),
      ...(addClassification ? { classification: wall.wallTypeTags } : {}),
      ...(addFunctionCode ? { functionCode } : {}),
      ...(addOffset ? { offset } : {}),
    };
  }
  private static rotationAngleToCounterClockWise(angle: number): 0 | 1 | 2 | 3 | null {
    switch (angle) {
      case 0:
        return 0;
      case 90:
        return 1;
      case 180:
        return 2;
      case 270:
      case -90:
        return 3;
      default:
        log.error(`Wrong rotation angle (${angle})`);
        return null;
    }
  }
}
