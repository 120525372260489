import { BackgroundManager } from "../../managers/SceneManager/BackgroundManager";
import { BackgroundCommand } from "./BackgroundCommand";
import { CommandTypes } from "./CommandTypes";

export class ScaleBackgroundCommand extends BackgroundCommand {
  constructor(
    entityId: string,
    private newScale: THREE.Vector3,
    private oldScale: THREE.Vector3
  ) {
    super(entityId);

    this.type = CommandTypes.ScaleBackgroundCommand;
  }

  apply(manager: BackgroundManager): void {
    const soBackground = manager.findSoBackground(this.entityId);
    soBackground.scale.copy(this.newScale);
    soBackground.updateMatrixWorld();

    manager.updateActiveBackgroundProperties();
  }

  undo(manager: BackgroundManager): void {
    const soBackground = manager.findSoBackground(this.entityId);
    soBackground.scale.copy(this.oldScale);
    soBackground.updateMatrixWorld();

    manager.updateActiveBackgroundProperties();
  }
}
