export enum CommandTypes {
  AddRoomCommand = "AddRoomCommand",
  DeleteRoomCommand = "DeleteRoomCommand",
  PasteRoomCommand = "CopyRoomCommand",
  MirrorRoomCommand = "MirrorRoomCommand",
  RotateRoomCommand = "RotateRoomCommand",
  RotateRoomContentCommand = "RotateRoomContentCommand",
  StretchRoomCommand = "StretchRoomCommand",
  TranslateRoomCommand = "TranslateRoomCommand",
  MultiCommand = "MultiCommand",

  ChangeFloorCommand = "ChangeFloorCommand",
  ChangeBackgroundCommand = "ChangeBackgroundCommand",
  RotateBackgroundCommand = "RotateBackgroundCommand",
  ScaleBackgroundCommand = "ScaleBackgroundCommand",
  TranslateBackgroundCommand = "TranslateBackgroundCommand",
  ChangeWallWidthCommand = "ChangeWallWidthCommand",
  ChangeWallAlignmentCommand = "ChangeWallAlignmentCommand",
}
