import * as THREE from "three";
import { soGroup } from "../soGroup";
/**
 * Class representing a 2D room item in the scene.
 * Inherits from soGroup to allow handling multiple child components.
 */
export class soRoomItem2D extends soGroup {
  itemName: string;
  itemCategory: string;
  parentRoomId: string;
  isStreachable: boolean;

  /**
   * Constructs an instance of soRoomItem2D.
   * @param itemGeometry - Array of geometry objects representing the item.
   * @param itemName - Name of the item.
   * @param itemCategory - Category of the item.
   * @param parentRoomId - ID of the parent room that contains this item.
   * @param isStreachable - Boolean indicating if the item is stretchable.
   */
  constructor(obj3D: THREE.Object3D | string, itemName: string, itemCategory?: string, parentRoomId?: string, isStreachable?: boolean) {
    super();

    if (obj3D instanceof THREE.Object3D) {
      this.copyPropertiesFromObject3D(obj3D);
      this.itemName = itemName || "Unnamed Item";
      this.itemCategory = obj3D.userData.itemCategory || "Uncategorized";
      this.parentRoomId = obj3D.userData.parentRoomId || "Unknown";
      this.isStreachable = obj3D.userData.isStreachable ?? false;
    } else {
      this.itemName = obj3D;
      this.itemCategory = itemCategory || "Uncategorized";
      this.parentRoomId = parentRoomId || "Unknown";
      this.isStreachable = isStreachable ?? false;
    }
  }

  private copyPropertiesFromObject3D(obj3D: THREE.Object3D) {
    this.position.copy(obj3D.position);
    this.rotation.copy(obj3D.rotation);
    this.scale.copy(obj3D.scale);

    obj3D.children.forEach(child => {
      this.add(child.clone());
    });

    this.userData = { ...obj3D.userData };

    if ((obj3D as any).material) {
      (this as any).material = (obj3D as any).material.clone();
    }

    if ((obj3D as any).geometry) {
      (this as any).geometry = (obj3D as any).geometry.clone();
    }
  }
}
