import * as THREE from "three";
import { soGroup } from "../soGroup";
import { soOpeningZone } from "./soOpeningZone";

/**
 * Abstract class representing a generic opening in a wall.
 */
export abstract class soOpening extends soGroup {
  OpeningZone: soOpeningZone;
  OpeningGeometry: THREE.Object3D[];
  ParentWallId: string;
  IsLocked: boolean;
  RoughHeight: number;
  RoughWidth: number;
  OpeningType: string;
  WallPointDistances: [number, number];
  WallPointIndexes: [number, number];

  constructor() {
    super();
  }
}

// Assuming soOpeningZone is defined elsewhere.
