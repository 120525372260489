import * as THREE from "three";
import { ARC_AREA_VALIDATION_LABEL_SIZE, ARC_GAP_HIGHT_COLOR, ARC_GAP_LOW_COLOR, ARC_GAP_MEDIUM_COLOR, ARC_GAP_UNEVALUATED_COLOR } from "../../consts";
import RoomManager from "../../managers/SceneManager/SceneManager";
import { IArcGapsValidationResult } from "../../models/ValidationResult";
import SceneUtils from "../../utils/SceneUtils";
import { IValidationTool } from "./IValidationTool";
import { appModel } from "../../../models/AppModel";
import UnitsUtils from "../../utils/UnitsUtils";
import GeometryUtils from "../../utils/GeometryUtils/GeometryUtils";
import RoomUtils from "../../utils/RoomUtils";

export enum ArcGapStatus {
  Hight = "High",
  Medium = "Medium",
  Low = "Low",
  Unevaluated = "Unevaluated",
  Unverified = "Unverified",
  None = "None",
}

const arcGapSceneColors = {
  [ArcGapStatus.Hight]: ARC_GAP_HIGHT_COLOR,
  [ArcGapStatus.Medium]: ARC_GAP_MEDIUM_COLOR,
  [ArcGapStatus.Low]: ARC_GAP_LOW_COLOR,
  [ArcGapStatus.Unevaluated]: ARC_GAP_UNEVALUATED_COLOR,
  [ArcGapStatus.Unverified]: ARC_GAP_UNEVALUATED_COLOR,
  [ArcGapStatus.None]: ARC_GAP_UNEVALUATED_COLOR,
};

const arcGapSort = {
  [ArcGapStatus.Hight]: 0,
  [ArcGapStatus.Medium]: 1,
  [ArcGapStatus.Low]: 2,
  [ArcGapStatus.Unevaluated]: 3,
  [ArcGapStatus.Unverified]: 4,
  [ArcGapStatus.None]: 5,
};

export default class GapValidationTool implements IValidationTool {
  private validationResult: IArcGapsValidationResult[] = [];
  constructor(private roomManager: any) {}

  public performValidation(): void {
    this.resetResult();

    this.validateGaps();
  }

  public visualizeValidationResult(container: THREE.Group, floorId: string): void {
    const soFloorRooms = this.roomManager.getSoFloor(floorId).children;

    this.validationResult.forEach(roomGap => {
      if (roomGap.floorId !== floorId || roomGap.status === ArcGapStatus.None || !arcGapSceneColors[roomGap.status]) {
        return;
      }
      const soRoom = soFloorRooms.find(r => r.userData.id === roomGap.roomId);
      const bbox = RoomUtils.getRoomBoundingBoxByModelLines(soRoom);
      const boxCenter = bbox.getCenter(new THREE.Vector3());
      container.add(SceneUtils.createRoomBboxPlane(bbox, arcGapSceneColors[roomGap.status], 0.2));
      const labelText = roomGap.roomNumber.toString().padStart(2, "0");
      const fontSize = ARC_AREA_VALIDATION_LABEL_SIZE * UnitsUtils.getConversionFactor();
      const label = SceneUtils.createTextMesh(labelText, 0, fontSize);
      const labelCenter = GeometryUtils.getGeometryBoundingBox2D(label).getCenter(new THREE.Vector3());
      label.position.copy(boxCenter.sub(labelCenter));
      container.add(label);
    });
  }

  public getFloorValidationResult(): IArcGapsValidationResult[] {
    return this.validationResult;
  }

  public resetResult() {
    this.validationResult.length = 0;
  }

  private validateGaps() {
    const floors = [...appModel.activeCorePlan.floors].sort((a, b) => a.index - b.index);
    floors.forEach(floor => {
      floor.rooms.forEach(room => {
        const roomType = appModel.getRoomType(room.roomTypeId);
        const { gapStatus, gapDescription } = roomType.attributes;

        const roomGapResult = {
          roomId: room.id,
          floorId: floor.id,
          roomNumber: this.validationResult.length + 1,
          status: gapStatus || ArcGapStatus.None,
          description: gapDescription,
        } as IArcGapsValidationResult;

        this.validationResult.push(roomGapResult);
      });
    });

    this.validationResult.sort((a, b) => arcGapSort[a.status] - arcGapSort[b.status]);
    this.validationResult.forEach((item, index) => {
      item.roomNumber = index + 1;
    });
  }
}
