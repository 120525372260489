import { Tabs } from "antd";
import ArcGapValidationTab from "./ArcGapValidationTab";
import AreaValidationTab from "./AreaValidationTab";
// import GravityLoadsTab from "./GravityLoadsTab";
// import PlumbingTab from "./PlumbingTab";
// import ShearCapacityTab from "./ShearCapacityTab";
import "./ValidationPanel.sass";
import WallTypesTab from "./WallTypesTab";

const ValidationPanel = () => {
  // const strValidationTab = (
  //   <div className="validation-type-panel">
  //     <Tabs
  //       defaultActiveKey="GL"
  //       destroyInactiveTabPane
  //       items={[
  //         { label: "Gravity loads", key: "GL", children: <GravityLoadsTab /> },
  //         { label: "Shear capacity", key: "SC", children: <ShearCapacityTab /> },
  //       ]}
  //     />
  //   </div>
  // );

  // const mepValidationTab = (
  //   <div className="validation-type-panel">
  //     <Tabs
  //       defaultActiveKey="P"
  //       destroyInactiveTabPane
  //       items={[
  //         { label: "Plumbing", key: "P", children: <PlumbingTab /> },
  //         { label: "Electrical", key: "E", children: <></> },
  //       ]}
  //     />
  //   </div>
  // );

  const arcValidationTab = (
    <div className="validation-type-panel one-tab">
      <Tabs
        defaultActiveKey="AV"
        destroyInactiveTabPane
        items={[
          { label: "Area", key: "AV", children: <AreaValidationTab /> },
          { label: "Wall types", key: "WT", children: <WallTypesTab /> },
          { label: "Gaps", key: "GV", children: <ArcGapValidationTab /> },
        ]}
      />
    </div>
  );

  return (
    <div className="validation-panel">
      <Tabs
        defaultActiveKey="ARC"
        destroyInactiveTabPane
        className="validation-panel-tabs"
        items={[
          { label: "ARC", key: "ARC", children: arcValidationTab },
          // { label: "STR", key: "STR", children: strValidationTab },
          // { label: "MEP", key: "MEP", children: mepValidationTab },
        ]}
      />
    </div>
  );
};

export default ValidationPanel;
