import { SVGProps } from "react";

const ProfileIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width={29} height={29} fill="none" viewBox="3 3 23 23" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.962 22.959a1.358 1.358 0 0 1-.447.743 1.256 1.256 0 0 1-.79.298 1.16 1.16 0 0 1-.288-.036 1.25 1.25 0 0 1-.656-.433 1.34 1.34 0 0 1-.287-.753l-.003-.886c-.148.025-.3.055-.456.09a15.77 15.77 0 0 0-.544.13c-.335.086-.686.184-1.05.286-2.607.732-6.178 1.733-10.275-.085L8 22.239v-3c0-.832.231-1.645.663-2.343a4.189 4.189 0 0 1 1.773-1.592l3.47-1.629-1.32-.63-.024-.013-.102-.062a3.804 3.804 0 0 1-1.687-3.166c0-2.098 1.7-3.804 3.79-3.804 2.088 0 3.788 1.706 3.788 3.804a3.82 3.82 0 0 1-1.895 3.296l-1.232.58 3.39 1.618a4.196 4.196 0 0 1 1.756 1.592c.428.695.656 1.504.657 2.33l.006 2.036a3.286 3.286 0 0 1 1.02.076c.67.171 1.078.901.909 1.627Zm-1.151-.657a2.274 2.274 0 0 0-.32-.05l.003.483a.344.344 0 0 0 .07.173.252.252 0 0 0 .126.088.16.16 0 0 0 .028.004.258.258 0 0 0 .152-.063.358.358 0 0 0 .116-.197l.002-.009a.43.43 0 0 0-.039-.306.242.242 0 0 0-.138-.123Zm-1.78-1.342-.004-1.737a3.467 3.467 0 0 0-.509-1.81 3.194 3.194 0 0 0-1.335-1.213l-3.621-1.728-3.7 1.737a3.19 3.19 0 0 0-1.349 1.213A3.464 3.464 0 0 0 9 19.24v2.339c3.561 1.412 6.664.56 9.17-.143l.033-.01c.616-.172 1.243-.348 1.829-.466Zm-5.464-8.077 1.421-.669a2.821 2.821 0 0 0 1.363-2.41A2.8 2.8 0 0 0 14.562 7a2.8 2.8 0 0 0-2.789 2.804c0 .94.464 1.811 1.242 2.333l-.006-.003.03.018.011.007 1.517.724Z"
        fill="#FFFFFF"
      />
    </svg>
  );
};

export { ProfileIcon };
