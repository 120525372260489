import { v4 as uuid } from "uuid";
import { RevitRoomType } from "./RevitRoomType";
import { RoomCategory } from "./RoomCategory";
import { RoomType } from "./RoomType";
import { Floor } from "./Floor";
import { CorePlan } from "./CorePlan";
import { Room } from "./Room";
import { FileEntity } from "./FileEntity";
import { kindBackgroundRaw } from "../services/allKinds";
import { LotLineSide } from "./CorePlanAttributes";
import { DesignStyle } from "./DesignStyle";

/*
 Load mock rooms list
 List is placed to file "rooms-list.local.ts", auto-created/updated on 'npm run start' command and not committed to Git
*/
function initModule() {
  try {
    return require("./mockup-rooms-list.local.ts");
  } catch (err) {
    return {};
  }
}
const roomsListModule = initModule();

export default class MockupsManager {
  private static readonly floorBackgroundFileNames: string[] = ["fbg1.jpg", "fbg2.jpg", "fbg3.png", "fbg4.jpg", "fbg5.png"];
  private static readonly roomFolderNames: string[] = roomsListModule?.roomsList ?? [];

  public static readonly designStyles: DesignStyle[] = [];
  public static readonly roomCategories: RoomCategory[] = [];
  public static readonly corePlans: CorePlan[] = [];

  private constructor() {}

  public static async init(): Promise<void> {
    await this.initRoomCategories();
    await this.initCorePlans();
    await this.initDesignStyles();
  }

  public static async initDesignStyles(): Promise<void> {
    const response = await fetch("/assets/textures/themes.json");
    const stylesData = await response.json();
    stylesData.forEach(styleData => {
      const designStyle = DesignStyle.fromJS(styleData);
      designStyle.finishes.forEach(f => {
        if (f.image) {
          f.image = "/assets/textures/" + f.image;
        }
      });

      this.designStyles.push(designStyle);
    });
  }
  private static async initRoomCategories(): Promise<void> {
    const requests = this.roomFolderNames.map(roomFolderName =>
      fetch("/rooms/" + roomFolderName + "/settings.json")
        .then(data => data.text())
        .then(roomJson => {
          const revitRoomType = RevitRoomType.fromJsonFile(JSON.parse(roomJson));

          let roomCategory = this.roomCategories.find(rc => rc.name === revitRoomType.category);
          if (!roomCategory) {
            roomCategory = new RoomCategory(revitRoomType.category);
            this.roomCategories.push(roomCategory);
          }

          const roomType = new RoomType(revitRoomType.name, "/assets/foo_room_type.jpg", revitRoomType.entities, revitRoomType.id);
          roomType.roomCategoryId = roomCategory.id;
          roomType.attributes.indoor = revitRoomType.indoor;
          roomType.attributes.netArea = revitRoomType.netArea;
          roomType.attributes.ceilingHeightOffsetFromLevel = revitRoomType.ceilingHeightOffsetFromLevel;

          roomCategory.roomTypes.push(roomType);
        })
    );
    await Promise.all(requests);
  }
  private static async initCorePlans(): Promise<void> {
    // populate background files:
    const floorBackgrounds: FileEntity[] = this.floorBackgroundFileNames.map(fileName => {
      const fe = new FileEntity();
      fe.id = uuid();
      fe.URI = "/backgrounds/" + fileName;
      fe.relType = kindBackgroundRaw.id;
      return fe;
    });

    // populate corePlans:
    let corePlan = new CorePlan("Mockup CorePlan 1");
    corePlan.id = "4dc153b9-5778-42f5-97f1-246482369f49";
    //corePlan.location = "Mockup Address";
    corePlan.attributes = {
      lotSize: 273600,
      lotLength: 273600,
      lotWidth: 273600,
      floors: 3,
      bedRooms: 3,
      garage: 1,
      bathRooms: 3.5,
      lotLineSideAssociation: {
        left: LotLineSide.Side,
        right: LotLineSide.Side,
        top: LotLineSide.Rear,
      },
      lotLineSetback: {
        front: 0,
        rear: 0,
        side: 0,
        front2: 0,
      },
    };
    corePlan.addFloor(new Floor(0, "Story 1"));
    corePlan.addFloor(new Floor(1, "Story 2"));
    corePlan.addFloor(new Floor(2, "Story 3"));
    corePlan.floors[0].addRoom(new Room(this.roomCategories[0].roomTypes[0].id, "Room 1"));
    corePlan.floors[1].addRoom(new Room(this.roomCategories[1].roomTypes[0].id, "Room 2"));
    corePlan.floors[2].addRoom(new Room(this.roomCategories[2].roomTypes[0].id, "Room 3"));
    corePlan.files.push(...floorBackgrounds);
    this.corePlans.push(corePlan);

    corePlan = new CorePlan("Mockup CorePlan 2");
    corePlan.id = "187ef043-3981-42e8-b061-d15a4cf91910";
    //corePlan.location = "Mockup Address 2";
    corePlan.attributes = {
      lotSize: 273600,
      lotLength: 273600,
      lotWidth: 273600,
      floors: 3,
      bedRooms: 3,
      garage: 1,
      bathRooms: 3.5,
      lotLineSideAssociation: {
        left: LotLineSide.Side,
        right: LotLineSide.Side,
        top: LotLineSide.Rear,
      },
      lotLineSetback: {
        front: 0,
        rear: 0,
        side: 0,
        front2: 0,
      },
    };
    corePlan.addFloor(new Floor(0, "Story 1"));
    corePlan.addFloor(new Floor(1, "Story 2"));
    corePlan.addFloor(new Floor(2, "Story 3"));
    corePlan.floors[0].addRoom(new Room(this.roomCategories[3].roomTypes[0].id, "Room 1"));
    corePlan.floors[1].addRoom(new Room(this.roomCategories[4].roomTypes[0].id, "Room 2"));
    corePlan.floors[2].addRoom(new Room(this.roomCategories[5].roomTypes[0].id, "Room 3"));
    corePlan.files.push(...floorBackgrounds);
    this.corePlans.push(corePlan);
  }
}
