import { soGroup } from "../soGroup";
import * as THREE from "three";
import { soBoundaryLine } from "./soBoundaryLine";
import { Side } from "../../../../models/Side";

/**
 * Class representing the boundary of a room.
 * Inherits from soGroup to allow handling multiple boundary lines.
 */
export class soRoomBoundary extends soGroup {
  boundaryLines: soBoundaryLine[];
  private centerPoint: THREE.Vector3;
  boundaryType: string;
  //each RoomBundary is multiple modelLines
  /**
   * Constructs an instance of RoomBoundary.
   * @param boundaryLines - Array of soBoundaryLine objects representing the room boundary.
   * @param boundaryType - Type of the boundary.
   */
  constructor(boundaryLines: soBoundaryLine[], boundaryType: string) {
    super();

    this.boundaryLines = boundaryLines;
    if (boundaryLines.length > 0) {
      //this.add(...boundaryLines);
    }
    this.boundaryType = boundaryType;
  }
  private calculateCenterPoint(): THREE.Vector3 {
    const center = new THREE.Vector3();
    this.boundaryLines.forEach(line => {
      center.add(line.position);
    });
    center.divideScalar(this.boundaryLines.length);
    return center;
  }
  setBoundaryLinesParentRoomSide(): void {
    const verticalLines = this.boundaryLines.filter(line => line.IsVertical);
    const horizontalLines = this.boundaryLines.filter(line => line.IsHorizontal);
    if (verticalLines.length !== 2 || horizontalLines.length !== 2) {
      throw new Error("Invalid boundary lines provided.");
    }
    if (verticalLines[0].MidPoint.x < verticalLines[1].MidPoint.x) {
      verticalLines[0].ParentRoomSide = Side.left;
      verticalLines[1].ParentRoomSide = Side.right;
    } else {
      verticalLines[0].ParentRoomSide = Side.right;
      verticalLines[1].ParentRoomSide = Side.left;
    }
    if (horizontalLines[0].MidPoint.y < horizontalLines[1].MidPoint.y) {
      horizontalLines[0].ParentRoomSide = Side.bottom;
      horizontalLines[1].ParentRoomSide = Side.top;
    } else {
      horizontalLines[0].ParentRoomSide = Side.top;
      horizontalLines[1].ParentRoomSide = Side.bottom;
    }
  }
  getRelativeBoundaryLineMap(): Map<Side, soBoundaryLine> {
    try {
      const boundaryLinesMap = new Map<Side, soBoundaryLine>();
      const verticalLines = this.boundaryLines.filter(line => line.IsVertical);
      const horizontalLines = this.boundaryLines.filter(line => line.IsHorizontal);
      if (verticalLines.length !== 2 || horizontalLines.length !== 2) {
        throw new Error("Invalid boundary lines provided.");
      }
      if (verticalLines[0].MidPoint.x < verticalLines[1].MidPoint.x) {
        boundaryLinesMap.set(Side.left, verticalLines[0]);
        boundaryLinesMap.set(Side.right, verticalLines[1]);
      } else {
        boundaryLinesMap.set(Side.left, verticalLines[1]);
        boundaryLinesMap.set(Side.right, verticalLines[0]);
      }
      if (horizontalLines[0].MidPoint.y < horizontalLines[1].MidPoint.y) {
        boundaryLinesMap.set(Side.bottom, horizontalLines[0]);
        boundaryLinesMap.set(Side.top, horizontalLines[1]);
      } else {
        boundaryLinesMap.set(Side.bottom, horizontalLines[1]);
        boundaryLinesMap.set(Side.top, horizontalLines[0]);
      }
      return boundaryLinesMap;
    } catch (error) {
      console.error("Error setting boundaryLinesMap:", error);
      throw error;
    }
  }
}
