import { Vector2 } from "three";
import { settings } from "../../entities/settings";
import { appModel } from "../../models/AppModel";
import { Floor } from "../../models/Floor";
import { Roof, RoofEdge } from "../../models/Roof";
import { Space } from "../models/FloorSpaceTree";
import ClipperUtils from "./ClipperUtils";
import GeometryUtils from "./GeometryUtils/GeometryUtils";
import SceneUtils from "./SceneUtils";
import SegmentsUtils from "./SegmentsUtils";
import { Paths, IntPoint } from "js-clipper";
import { Segment } from "../models/segments/Segment";

export default class RoofUtils {
  static calculateActiveCorePlanRoofs(): void {
    const boundingBoxes = SceneUtils.collectRoomBoundingBoxes(
      appModel.activeCorePlan.floors,
      appModel.activeCorePlan.firstFloorToFloorHeight,
      appModel.activeCorePlan.upperFloorToFloorHeight
    );

    const sortedFloors = [...appModel.activeCorePlan.floors.sort((a, b) => a.index - b.index)];

    for (let i = 0; i < sortedFloors.length; i++) {
      const floor = sortedFloors[i];

      const floorBoxes: { indoor: THREE.Box3[]; outdoor: THREE.Box3[] } = {
        indoor: [],
        outdoor: [],
      };
      floor.rooms.forEach(room => {
        const bb = boundingBoxes.get(room.id);
        const isIndoor = !!appModel.getRoomType(room.roomTypeId).attributes.indoor;
        if (isIndoor) {
          floorBoxes.indoor.push(bb);
        } else {
          floorBoxes.outdoor.push(bb);
        }
      });

      const nextFloorBoxes: { indoor: THREE.Box3[]; outdoor: THREE.Box3[] } = {
        indoor: [],
        outdoor: [],
      };

      if (i < sortedFloors.length - 1) {
        const nextFloor = sortedFloors[i + 1]; // Look ahead to the next floor

        nextFloor.rooms.forEach(room => {
          const bb = boundingBoxes.get(room.id);
          const isIndoor = !!appModel.getRoomType(room.roomTypeId).attributes.indoor;
          if (isIndoor) {
            nextFloorBoxes.indoor.push(bb);
          } else {
            nextFloorBoxes.outdoor.push(bb);
          }
        });
      }

      if (floor.index >= 0 || floor.index === appModel.activeCorePlan.floors.length - 1) {
        RoofUtils.setFloorRoofs(floor, [...floorBoxes.indoor, ...floorBoxes.outdoor], [...nextFloorBoxes.indoor, ...nextFloorBoxes.outdoor]);
      }
    }
  }

  static setFloorRoofs(floor: Floor, currentFloorBoxes: THREE.Box3[], nextFloorBoxes: THREE.Box3[]): void {
    floor.resetRoofs();
    const offset = settings.values.validationSettings.roofDefaultOverhang;
    const currentFloorOutlines = SegmentsUtils.findSweepSpaces(currentFloorBoxes);
    const nextFloorOutlines = SegmentsUtils.findSweepSpaces(nextFloorBoxes);
    currentFloorOutlines.forEach(currentFloorOutline => {
      this.processFloorOutline(currentFloorOutline, nextFloorOutlines, offset, floor);
    });
  }

  static processFloorOutline(currentFloorOutline: Space, nextFloorOutlines: Space[], offset: number, floor: Floor): void {
    const holes = [];
    const currentOutlinePath = ClipperUtils.toClipperPath(SegmentsUtils.segmentsToPoints(currentFloorOutline.contour));

    // Prepare the negative paths array
    const negativePaths = nextFloorOutlines.map(outline => ClipperUtils.toClipperPath(SegmentsUtils.segmentsToPoints(outline.contour)));

    // Filter out fully enclosed outlines
    const remainingNegativePaths = negativePaths.filter(path => {
      if (ClipperUtils.isPathFullyEnclosed(path, currentOutlinePath)) {
        holes.push(ClipperUtils.fromClipperPath(path)); // Add to holes if it's fully enclosed
        return false; // Remove from the subtraction process
      }
      return true;
    });

    const subtractedPaths = ClipperUtils.difference(currentOutlinePath, remainingNegativePaths);
    if (subtractedPaths.length > 0) {
      const expandedPaths = this.expandAndSubtractPaths(subtractedPaths, remainingNegativePaths, offset);

      expandedPaths.forEach(path => {
        const roof = new Roof();
        const shapePath = ClipperUtils.fromClipperPath(path);

        for (let i = 0; i < shapePath.length; i++) {
          const startNode = shapePath[i];
          const endNode = shapePath[(i + 1) % shapePath.length]; // Loop back to the first point at the end
          const slope = this.getEdgeSlope(startNode, endNode, floor, nextFloorOutlines);
          const gableDepth = this.getEdgeGableDepth(startNode, endNode, floor);
          const roofEdge = new RoofEdge(startNode, endNode, slope, gableDepth);

          roof.addEdge(roofEdge);
        }

        floor.addRoof(roof);
      });
    }
  }

  static getEdgeSlope(startNode: Vector2, endNode: Vector2, floor: Floor, nextFloorOutlines: Space[]): number {
    const segment = new Segment(startNode, endNode);
    let res = -1;
    for (const room of floor.rooms) {
      const x = room.x;
      const y = room.y;
      const width = room.width;
      const height = room.height;
      const offset = settings.values.validationSettings.roofDefaultOverhang;

      const min = new Vector2(x - width / 2, y - height / 2);
      const max = new Vector2(x + width / 2, y + height / 2);

      const back = new Segment(new Vector2(min.x, min.y - offset), new Vector2(max.x, min.y - offset));
      const front = new Segment(new Vector2(min.x, max.y + offset), new Vector2(max.x, max.y + offset));

      const left = new Segment(new Vector2(min.x - offset, min.y), new Vector2(min.x - offset, max.y));
      const right = new Segment(new Vector2(max.x + offset, min.y), new Vector2(max.x + offset, max.y));

      if (SegmentsUtils.segmentsOverlap(back, segment, 0.01)) {
        res = room.roofSlopes[2];
      } else if (SegmentsUtils.segmentsOverlap(front, segment, 0.01)) {
        res = room.roofSlopes[0];
      } else if (SegmentsUtils.segmentsOverlap(right, segment, 0.01)) {
        res = room.roofSlopes[1];
      } else if (SegmentsUtils.segmentsOverlap(left, segment, 0.01)) {
        res = room.roofSlopes[3];
      }
    }

    // Check for overlaps with next floor segments and set slope to 0 if there's an overlap
    for (const outline of nextFloorOutlines) {
      for (const nextSegment of outline.contour) {
        if (SegmentsUtils.segmentsOverlap(segment, nextSegment, 0.01)) {
          res = 0;
          break;
        }
      }
      if (res === 0) break;
    }

    return res == -1 ? settings.values.validationSettings.roofDefaultSlope : res;
  }

  static getEdgeGableDepth(startNode: Vector2, endNode: Vector2, floor: Floor): number {
    const segment = new Segment(startNode, endNode);
    let res = -1;
    for (const room of floor.rooms) {
      const x = room.x;
      const y = room.y;
      const width = room.width;
      const height = room.height;
      const offset = settings.values.validationSettings.roofDefaultOverhang;

      const min = new Vector2(x - width / 2, y - height / 2);
      const max = new Vector2(x + width / 2, y + height / 2);

      const back = new Segment(new Vector2(min.x, min.y - offset), new Vector2(max.x, min.y - offset));
      const front = new Segment(new Vector2(min.x, max.y + offset), new Vector2(max.x, max.y + offset));

      const left = new Segment(new Vector2(min.x - offset, min.y), new Vector2(min.x - offset, max.y));
      const right = new Segment(new Vector2(max.x + offset, min.y), new Vector2(max.x + offset, max.y));

      if (!room?.dutchGableDepths) {
        room.dutchGableDepths = [0, 0, 0, 0]; // Initialize dutch gable depths if it doesn't already exist
      }

      if (SegmentsUtils.segmentsOverlap(back, segment, 0.01)) {
        res = room.dutchGableDepths[2];
      } else if (SegmentsUtils.segmentsOverlap(front, segment, 0.01)) {
        res = room.dutchGableDepths[0];
      } else if (SegmentsUtils.segmentsOverlap(right, segment, 0.01)) {
        res = room.dutchGableDepths[1];
      } else if (SegmentsUtils.segmentsOverlap(left, segment, 0.01)) {
        res = room.dutchGableDepths[3];
      }
    }

    return res == -1 ? 0 : res;
  }

  static isOverlapping(startNode1: Vector2, endNode1: Vector2, startNode2: Vector2, endNode2: Vector2) {}

  static expandAndSubtractPaths(paths: Paths, negativePaths: IntPoint[][], offset: number): Paths {
    const finalPaths = new Paths();
    paths.forEach(path => {
      const expandedPath = GeometryUtils.addOffsetToContour2D(ClipperUtils.fromClipperPath(path), offset);
      const expandedClipperPath = ClipperUtils.toClipperPath(expandedPath);
      finalPaths.push(...ClipperUtils.difference(expandedClipperPath, negativePaths));
    });
    return finalPaths;
  }
}
