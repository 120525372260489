import { Vector2, Vector3, Box3, Group } from "three";
import { soRoom2D } from "../SceneObjects/Room/soRoom2D";
import { soWall2D, WallSides } from "../SceneObjects/Wall/soWall2D";
import { Vertex } from "./Vertex";
import { Side } from "../../../models/Side";
import GeometryUtils from "../../utils/GeometryUtils/GeometryUtils";
import VectorUtils from "../../utils/GeometryUtils/VectorUtils";
import { appModel } from "../../../models/AppModel";
import { soFloor2D } from "../SceneObjects/Floor/soFloor2D";
import BoundingBoxUtils from "../../utils/GeometryUtils/BoundingBoxUtils";
import { WallType } from "../../../entities/catalogSettings/types";
import RoomUtils from "../../utils/RoomUtils";
import { EPSILON } from "../../consts";
import { Direction } from "../Direction";
import WallUtils from "../../utils/WallUtils";
import Space from "./Space";
import soSpace from "./Space";
import WallOverrides from "../SceneObjects/Wall/wallOverrides";
import { settings } from "../../../entities/settings";

/* ------------------------------------------------------------------------- */
/*                              Class Definition                             */
/* ------------------------------------------------------------------------- */

/**
 * Manages walls and vertices derived from rooms (soRoom2D objects).
 * Each wall is directional (vertical or horizontal), and each vertex tracks
 * connected edges (up, down, left, right) as well as the number of room corners
 * it represents.
 */
export default class WallManager extends Group {
  /* ----------------------------------------------------------------------- */
  /*                           Private Properties                            */
  /* ----------------------------------------------------------------------- */

  private vertices: Map<string, Vertex>;
  private walls: Map<string, soWall2D>;
  private rooms: Map<string, soRoom2D>;
  private WallCache: string[] = [];
  private roomWallsOverrideCache: Map<string, WallOverrides> = new Map();

  /* ----------------------------------------------------------------------- */
  /*                                Constructor                              */
  /* ----------------------------------------------------------------------- */

  /**
   * Creates a new WallManager instance.
   * @param ParentFloor - Reference to the floor that this wall manager is associated with.
   */
  constructor(private ParentFloor: soFloor2D) {
    super();
    this.vertices = new Map<string, Vertex>();
    this.walls = new Map<string, soWall2D>();
    this.rooms = new Map<string, soRoom2D>();
  }

  /* ----------------------------------------------------------------------- */
  /*                            Public Methods                               */
  /* ----------------------------------------------------------------------- */

  /**
   * Adds a list of rooms to the rooms list and updates the walls and vertices accordingly.
   * @param rooms An array of soRoom2D objects representing the rooms to add.
   */
  public addRooms(rooms: soRoom2D[]): void {
    try {
      rooms.forEach(room => {
        if (!this.rooms.has(room.soId)) {
          this.addRoom(room);
          this.updateWallsGeometry(true);
        }
        //rooms.forEach(room => room.wallOverrides.clear());
      });
    } catch (error) {
      console.error("Failed to add rooms: " + (error as Error).message);
    }
  }
  /**
   * Adds a room to the rooms list and updates the walls and vertices accordingly.
   * @param room The soRoom2D object representing the room to add.
   * @throws Error if the room is invalid.
   */
  private addRoom(room: soRoom2D): void {
    try {
      room.wallsIds = [];
      this.rooms.set(room.soId, room);

      const boundingBox = room.boundingBoxByModelLine;
      const corners = BoundingBoxUtils.getBoundingBoxCorners2D(boundingBox);
      let dataBoxVertices = room.dataBoxes.map(dataBox => dataBox.getVerticesOnRoomBoundary(boundingBox)).flat();

      // Remove pairs of vertices that are too close to each other
      dataBoxVertices = this.filterCloseVertices(dataBoxVertices, settings.values.validationSettings.plmFixtureWallSplitTolerance);

      // Update vertices for corners and dataBoxVertices
      this.updateRoomVertices(corners, false);
      this.updateRoomVertices(dataBoxVertices, true);

      // Update walls with new edges
      this.updateWallsForBoundingBox(corners);
    } catch (error) {
      throw new Error("Failed to add room: " + (error as Error).message);
    }
  }

  /**
   * Updates vertices for a room by validating distance (if necessary), updating vertex counts,
   * and splitting walls when required.
   *
   * @param vertices - The list of vertices to process.
   * @param validateDistance - Whether to validate the vertex distance before processing.
   */
  private updateRoomVertices(vertices: THREE.Vector2[], validateDistance: boolean): void {
    for (const vertex of vertices) {
      if (validateDistance && !this.validateVertexDistance(vertex, 3)) {
        continue; // Skip this vertex if it is too close to any existing vertex
      }

      const vertexId = Vertex.generateVertexId(vertex);
      let existingVertex = this.vertices.get(vertexId);

      if (!existingVertex) {
        existingVertex = new Vertex(vertex);
        this.vertices.set(vertexId, existingVertex);
      }

      this.updateVertexCount(vertexId, 1);
      this.splitWallIfNecessary(existingVertex);
    }
  }

  /**
   * Adds a list of rooms to the rooms list and updates the walls and vertices accordingly.
   * @param rooms An array of soRoom2D objects representing the rooms to add.
   */
  public removeRooms(rooms: soRoom2D[]): void {
    try {
      rooms.forEach(room => {
        room.wallOverrides.forEach(wallOverride => this.roomWallsOverrideCache.set(wallOverride.WallId, wallOverride));
      });
      rooms.forEach(room => {
        if (this.rooms.has(room.soId)) {
          this.removeRoom(room);
          this.updateWallsGeometry(true);
        }
      });
    } catch (error) {
      console.error("Failed to remove rooms: " + (error as Error).message);
    }
  }
  /**
   * Removes a room from the rooms list and updates the walls and vertices accordingly.
   * @param room The soRoom2D object representing the room to remove.
   * @throws Error if the room is invalid or not found.
   */
  private removeRoom(room: soRoom2D): void {
    if (!this.rooms.has(room.soId)) {
      throw new Error(`Room ${room.name} Id:${room.soId} not found in the manager.`);
    }

    const roomWallIds = [...room.wallsIds];
    const boundingBox = room.boundingBoxByModelLine;
    const corners = BoundingBoxUtils.getBoundingBoxCorners2D(boundingBox);

    let dataBoxVertices = room.dataBoxes.map(dataBox => dataBox.getVerticesOnRoomBoundary(boundingBox)).flat();

    // Remove pairs of vertices that are too close to each other
    dataBoxVertices = this.filterCloseVertices(dataBoxVertices, settings.values.validationSettings.plmFixtureWallSplitTolerance);

    this.rooms.delete(room.soId);

    // Update vertex counts for corners and dataBoxVertices
    this.decrementVertexCounts(corners);
    this.decrementVertexCounts(dataBoxVertices);

    const remainingWalls = roomWallIds.filter(wallId => this.walls.has(wallId));
    const invalidWalls = remainingWalls.filter(wallID => !this.verifyWallValidById(wallID));
    invalidWalls.forEach(wallId => this.RemoveWallById(wallId));
  }

  /**
   * Decrements the count for a list of vertices.
   * This can include corners, dataBox vertices, or other types of vertices.
   *
   * @param vertices - The list of vertices to process.
   */
  private decrementVertexCounts(vertices: THREE.Vector2[]): void {
    for (const vertex of vertices) {
      const vertexId = Vertex.generateVertexId(vertex);
      this.updateVertexCount(vertexId, -1);
    }
  }

  /**
   * Filters out pairs of vertices that are too close to each other from the given array.
   * First identifies all close vertices, then removes them.
   *
   * @param vertices - The array of vertices to filter.
   * @param threshold - The distance threshold for removal.
   * @returns {THREE.Vector2[]} - A filtered array of vertices.
   */
  private filterCloseVertices(vertices: THREE.Vector2[], threshold: number): THREE.Vector2[] {
    const toRemove = new Set<number>(); // Indices of vertices to remove

    // Step 1: Identify pairs of close vertices
    for (let i = 0; i < vertices.length; i++) {
      for (let j = i + 1; j < vertices.length; j++) {
        if (this.isWithinThreshold(vertices[i], vertices[j], threshold)) {
          toRemove.add(i);
          toRemove.add(j);
        }
      }
    }

    // Step 2: Filter out identified vertices
    return vertices.filter((_, index) => !toRemove.has(index));
  }

  /**
   * Checks if two vertices are within the given distance threshold.
   *
   * @param vertex1 - The first vertex.
   * @param vertex2 - The second vertex.
   * @param threshold - The distance threshold.
   * @returns {boolean} - True if the vertices are within the threshold, otherwise false.
   */
  private isWithinThreshold(vertex1: THREE.Vector2, vertex2: THREE.Vector2, threshold: number): boolean {
    const distanceX = Math.abs(vertex1.x - vertex2.x);
    const distanceY = Math.abs(vertex1.y - vertex2.y);

    return distanceX < threshold && distanceY < threshold;
  }

  /**
   * Validates if a given vertex is farther than a specified distance from all vertices in the collection.
   * Checks the distance in the x and y directions separately.
   *
   * @param vertex - The vertex to validate.
   * @param threshold - The distance threshold in x and y directions.
   * @returns {boolean} - Returns true if the vertex is farther than the threshold from all other vertices, false otherwise.
   */
  private validateVertexDistance(vertex: THREE.Vector2, threshold: number): boolean {
    for (const existingVertex of this.vertices.values()) {
      if (this.isWithinThreshold(vertex, existingVertex.point, threshold)) {
        return false; // The vertex is within the threshold of an existing vertex
      }
    }

    return true; // The vertex is valid (not within the threshold of any existing vertex)
  }

  /**
   * Checks whether this manager contains the specified room.
   * @param roomId The ID of the room to check.
   * @returns True if the room exists in the manager, false otherwise.
   */
  public HasRoom(roomId: string): boolean {
    return this.rooms.has(roomId);
  }

  /**
   * Returns the current list of walls.
   * @returns An array of soWall2D objects.
   */
  public getWalls(): soWall2D[] {
    return Array.from(this.walls.values());
  }

  /**
   * Retrieves a specific wall by its ID.
   * @param id The ID of the wall to retrieve.
   * @returns The soWall2D object or undefined if not found.
   */
  public getWallById(id: string): soWall2D | undefined {
    return this.walls.get(id);
  }

  /**
   * Checks if a wall exists by its ID.
   * @param id The wall ID.
   * @returns True if the wall exists, false otherwise.
   */
  public hasWall(id: string): boolean {
    return this.walls.has(id);
  }

  /**
   * Retrieves a specific vertex by its ID.
   * @param id The ID of the vertex to retrieve.
   * @returns The Vertex object or undefined if not found.
   */
  public getVertexById(id: string): Vertex | undefined {
    return this.vertices.get(id);
  }

  /**
   * Retrieves multiple walls by their IDs.
   * @param ids The array of wall IDs to retrieve.
   * @returns An array of soWall2D objects.
   */
  public getWallsByIds(ids: string[]): soWall2D[] {
    return ids.map(id => this.walls.get(id)).filter((wall): wall is soWall2D => wall !== undefined);
  }

  /**
   * Removes a wall from the manager by its soWall2D object.
   * @param wall The soWall2D object to remove.
   * @returns True if the wall was removed, false otherwise.
   */
  public RemoveWall(wall: soWall2D): boolean {
    if (!wall) return false;
    try {
      this.removeWallFromVertices(wall);
      // If you need to remove it from a cache: this.removeFromWallCache(wall);
      wall.parentRoomIds?.forEach(id => {
        (this.parent as soFloor2D).getRoomById(id)?.removeWall(wall.wallId);
      });

      this.walls.delete(wall.wallId);
      this.remove(wall);
      GeometryUtils.disposeObject(wall);
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * Removes a wall from the manager by its wallId.
   * @param wallId The ID of the wall to remove.
   * @returns True if the wall was removed, false otherwise.
   */
  public RemoveWallById(wallId: string): boolean {
    const wall = this.getWallById(wallId);
    return this.RemoveWall(wall);
  }

  /**
   * Adds a wall to the walls map and updates the vertices accordingly.
   * @param wall The soWall2D object to add.
   */
  public addWall(wall: soWall2D): void {
    const wallId = wall.wallId;
    this.addToWallCache(wall);

    if (!this.walls.has(wallId)) {
      this.addWallToVertices(wall);
      this.walls.set(wallId, wall);
      this.add(wall);
    }
    this.updateWallFunction(wall);
  }

  /**
   * Links a wall to its start and end vertices.
   * @param wall The wall to link.
   */
  public addWallToVertices(wall: soWall2D): void {
    const startVertexId = Vertex.generateVertexId(wall.start);
    const endVertexId = Vertex.generateVertexId(wall.end);

    const startVertex = this.vertices.get(startVertexId);
    const endVertex = this.vertices.get(endVertexId);

    if (startVertex) {
      this.addEdgeToVertex(startVertex, wall);
    }
    if (endVertex) {
      this.addEdgeToVertex(endVertex, wall);
    }
  }

  /**
   * Removes a wall reference from its start and end vertices.
   * @param wall The wall to remove.
   */
  public removeWallFromVertices(wall: soWall2D): void {
    const startVertexId = Vertex.generateVertexId(wall.start);
    const endVertexId = Vertex.generateVertexId(wall.end);

    const startVertex = this.vertices.get(startVertexId);
    const endVertex = this.vertices.get(endVertexId);

    if (startVertex) {
      startVertex.edges = Object.fromEntries(Object.entries(startVertex.edges).filter(([, value]) => value !== wall.wallId));
    }
    if (endVertex) {
      endVertex.edges = Object.fromEntries(Object.entries(endVertex.edges).filter(([, value]) => value !== wall.wallId));
    }
  }

  /**
   * Updates the wall's external/internal status based on its intersection with rooms.
   * Checks if the midpoint of the given wall intersects with any room's bounding box.
   * @param wall The wall to be updated.
   */
  public updateWallFunction(wall: soWall2D): void {
    const intersectingRooms = Array.from(this.rooms.values()).filter(room => {
      const midpoint3D = VectorUtils.Vector2ToVector3(wall.getWallMidpoint());
      return (
        GeometryUtils.isPointInsideBoundingBox(midpoint3D, room.boundingBoxByModelLine) &&
        GeometryUtils.isPointOnBoundingBoxPerimeter(midpoint3D, room.boundingBoxByModelLine)
      );
    });

    wall.setParentRooms(intersectingRooms);
    intersectingRooms.forEach(room => {
      room.addWall(wall);
    });
  }

  /**
   * Adds a wall to the internal cache.
   * @param wall The wall to cache.
   */
  public addToWallCache(wall: soWall2D): void {
    if (!this.WallCache.includes(wall.wallId)) {
      this.WallCache.push(wall.wallId);
    }
  }

  /**
   * Adds a wall to the internal cache by wall ID.
   * @param wallId The wall ID to add.
   */
  public addToWallCacheById(wallId: string): void {
    const wall = this.getWallById(wallId);
    if (wall) {
      this.addToWallCache(wall);
    }
  }

  /**
   * Removes a wall from the internal cache.
   * @param wall The wall to remove from cache.
   */
  public removeFromWallCache(wall: soWall2D): void {
    if (this.WallCache.includes(wall.wallId)) {
      this.WallCache = this.WallCache.filter(id => id !== wall.wallId);
    }
  }

  /**
   * Updates the wall geometry for either just cached walls or all walls.
   * @param WallCacheOnly If true, only walls in the WallCache are updated. Otherwise, update all walls.
   */
  public updateWallsGeometry(WallCacheOnly: boolean = false): void {
    if (WallCacheOnly) {
      this.getWallsByIds(this.WallCache).forEach(wall => {
        wall.CreateWallGeometry();
      });
      this.WallCache = [];
    } else {
      this.getWalls().forEach(wall => {
        wall.CreateWallGeometry();
      });
    }
  }

  /**
   * Updates the geometry of walls based on their IDs.
   * @param wallsIds The IDs of the walls to update.
   */
  public updateGeometryByWallIds(wallsIds: string | string[]) {
    if (Array.isArray(wallsIds)) {
      this.getWallsByIds(wallsIds).forEach(wall => {
        wall.CreateWallGeometry();
      });
    } else {
      this.getWallById(wallsIds).CreateWallGeometry();
    }
  }
  /**
   * Verifies if a wall is valid by checking if its midpoint intersects with any room bounding box.
   * @param wall The wall to verify.
   * @returns True if the wall is valid, false otherwise.
   */
  public verifyWallValid(wall: soWall2D): boolean {
    const wallMidpoint = wall.getWallMidpoint();
    return Array.from(this.rooms.values()).some(room =>
      GeometryUtils.isPointInsideBoundingBox(VectorUtils.Vector2ToVector3(wallMidpoint), room.boundingBoxByModelLine)
    );
  }

  /**
   * Verifies if a wall is valid by its ID.
   * @param wallId The wall ID to verify.
   * @returns True if the wall is valid, false otherwise.
   */
  public verifyWallValidById(wallId: string): boolean {
    const wall = this.getWallById(wallId);
    return wall ? this.verifyWallValid(wall) : false;
  }

  /**
   * Returns the string array of vertex IDs that define a given wall.
   * @param wallId The wall ID string (format: "vertexId1$vertexId2").
   * @returns An array containing the start and end vertex IDs.
   */
  public getVerticesIdsFromWallId(wallId: string): string[] {
    return wallId.split("$");
  }

  /**
   * Returns the current list of vertices in the manager.
   * @returns An array of Vertex objects.
   */
  public getVertices(): Vertex[] {
    return Array.from(this.vertices.values());
  }

  /**
   * Returns the keys (IDs) of the current list of vertices.
   * @returns An array of vertex IDs.
   */
  public getVerticesKeys(): string[] {
    return Array.from(this.vertices.keys());
  }

  /**
   * Returns the keys (IDs) of the current list of walls.
   * @returns An array of wall IDs.
   */
  public getWallsKeys(): string[] {
    return Array.from(this.walls.keys());
  }

  /**
   * Creates a string that can be used to export the wall geometry (e.g., for Rhino).
   * @returns A string representation of wall start/end coordinates.
   */
  public getWallsForRhino(): string {
    let result = "";
    for (const wall of this.walls.values()) {
      result += `${wall.start.x},${wall.start.y},0` + `$` + `${wall.end.x},${wall.end.y},0\n`;
    }
    return result;
  }

  /**
   * Creates and returns an array of `soSpace` objects representing enclosed spaces
   * formed from external walls. Each space is defined by the walls that enclose it.
   *
   * @returns {soSpace[]} An array of `soSpace` objects, each representing an enclosed space.
   */
  public createEnclosedSpacesFromExternalWalls(): soSpace[] {
    // Map of free external walls, keyed by their unique wallId
    const freeExternalWalls = new Map<string, soWall2D>(
      this.getWalls()
        .filter(wall => wall.isExternal) // Filter only external walls
        .map(wall => [wall.wallId, wall]) // Create a map of wallId to wall object
    );

    // Map to store used external walls to prevent reprocessing
    const usedExternalWalls: Map<string, soWall2D> = new Map();

    // Array to store the resulting enclosed spaces
    const enclosedSpaces: soSpace[] = [];

    // Process until all external walls are handled
    while (freeExternalWalls.size > 0) {
      // Get the first wall from the map of free external walls
      const firstWall = freeExternalWalls.values().next().value as soWall2D;

      // If no wall is found, exit the loop (edge case)
      if (!firstWall) break;

      // Create a new space object to represent the enclosed area
      const space = new soSpace();
      let currentWall = firstWall;

      // Traverse connected external walls to define the boundary of the space
      do {
        const wallId = currentWall.wallId;

        // Mark the current wall as used
        usedExternalWalls.set(wallId, currentWall);
        freeExternalWalls.delete(wallId);

        // Add the current wall to the space's contour
        space.addContourWall(currentWall);

        // Add rooms contained by the current wall
        space.addContainedRooms([...currentWall.parentRoomIds]);

        // Get all connected walls at the start and end vertices of the current wall
        const connectedWalls = this.getWallsByIds(
          Array.from(
            new Set([
              ...currentWall.WallEndVertex.getAllEdgesIds(), // Edges at the end vertex
              ...currentWall.WallStartVertex.getAllEdgesIds(), // Edges at the start vertex
            ])
          )
        );

        // Find the next external wall to continue the loop
        const nextWall = connectedWalls.find(w => freeExternalWalls.has(w.wallId));

        // Add all connected internal walls to the space
        connectedWalls.filter(w => !w.isExternal).forEach(w => space.addinternalWall(w));

        // Move to the next external wall
        currentWall = nextWall;
      } while (currentWall);

      // Add the completed space to the list of enclosed spaces
      enclosedSpaces.push(space);
    }

    // Return the array of enclosed spaces
    return enclosedSpaces;
  }

  /* ----------------------------------------------------------------------- */
  /*                           Private Methods                               */
  /* ----------------------------------------------------------------------- */

  /**
   * Adjusts the vertexCount of the vertex by the given delta and removes the vertex if vertexCount reaches zero.
   * @param vertexId The ID of the vertex to update.
   * @param delta The amount to adjust the cornerCount by.
   * @throws Error if the vertex does not exist.
   */
  private updateVertexCount(vertexId: string, delta: number): void {
    const vertex = this.vertices.get(vertexId);
    if (!vertex) {
      throw new Error(`Vertex with ID ${vertexId} does not exist.`);
    }

    if (delta > 0) {
      vertex.incrementVertexCount();
    } else {
      vertex.decrementVertexCount();
    }

    if (vertex.getVertexCount() <= 0) {
      // Remove the vertex entirely
      this.removeEdgesFromVertex(vertex);
      this.vertices.delete(vertexId);
    } else {
      // Update walls connected to this vertex
      for (const edgeId of Object.values(vertex.edges)) {
        const edgeWall = this.getWallById(edgeId);
        if (edgeWall) {
          this.addToWallCache(edgeWall);
          this.updateWallFunction(edgeWall);
        }
      }
    }
  }

  /**
   * Splits a wall if the given vertex lies on it (but not on its edges).
   * @param vertex The vertex to check.
   */
  private splitWallIfNecessary(vertex: Vertex): void {
    for (const wall of this.walls.values()) {
      if (wall.isPointOnWall(vertex.point) && !wall.isPointOnWallEdges(vertex.point)) {
        this.splitWall(wall, vertex);
        break;
      }
    }
  }

  /**
   * Splits an existing wall into two walls at the location of a given vertex.
   * @param wall The wall to split.
   * @param vertex The vertex where the split occurs.
   * @returns The new wall IDs [wall1, wall2].
   */
  private splitWall(wall: soWall2D, vertex: Vertex): string[] {
    if (!(wall.isPointOnWall(vertex.point) && !wall.isPointOnWallEdges(vertex.point))) {
      return [];
    }

    // Remove the original wall
    if (this.walls.has(wall.wallId)) this.RemoveWall(wall);

    const startVertexId = Vertex.generateVertexId(wall.start);
    const endVertexId = Vertex.generateVertexId(wall.end);
    const vertexId = vertex.id;

    const wall1Id = WallUtils.generateWallId(startVertexId, vertexId);
    const wall2Id = WallUtils.generateWallId(vertexId, endVertexId);

    const wall1 = new soWall2D(wall.start.clone(), vertex.point.clone());
    const wall2 = new soWall2D(vertex.point.clone(), wall.end.clone());

    this.addWall(wall1);
    this.addWall(wall2);

    return [wall1Id, wall2Id];
  }

  /**
   * For a wall with the given ID, splits it into multiple smaller walls based on additional vertices.
   * @param wallId The ID of the wall to split.
   * @param verticesIds The IDs of the vertices that define split points on the wall.
   */
  private splitWallWithVertices(wallId: string, verticesIds: string[]): void {
    const mergedIds = [...this.getVerticesIdsFromWallId(wallId), ...verticesIds];
    const wallVerticesIds = Vertex.sortVertexIds(mergedIds);

    for (let i = 0; i < wallVerticesIds.length - 1; i++) {
      const startVertexId = wallVerticesIds[i];
      const endVertexId = wallVerticesIds[i + 1];

      const newWallId = WallUtils.generateWallId(startVertexId, endVertexId);
      if (!this.hasWall(newWallId)) {
        const start = this.getVertexById(startVertexId)?.point.clone();
        const end = this.getVertexById(endVertexId)?.point.clone();

        if (start && end) {
          const newWall = new soWall2D(start, end);
          this.addWall(newWall);
        }
      }
    }
  }

  /**
   * Merges adjacent walls connected to the given vertex if they are collinear.
   * @param vertexId The ID of the vertex around which walls might be merged.
   */
  private mergeWalls(vertexId: string): void {
    const vertex = this.vertices.get(vertexId);
    if (vertex) {
      for (const dir of Object.keys(Side)) {
        const oppositeDir = this.getOppositeDirection(dir);
        const wall1 = this.walls.get(vertex.edges[dir]);
        const wall2 = this.walls.get(vertex.edges[oppositeDir]);

        if (wall1 && wall2) {
          if (WallUtils.areWallsCollinear(wall1, wall2)) {
            // Sort walls so we combine in a consistent order
            const wallsToMerge = [wall1, wall2].sort((a, b) => {
              if (a.isVertical) {
                return a.start.y - b.start.y;
              }
              return a.start.x - b.start.x;
            });

            const w1 = wallsToMerge[0];
            const w2 = wallsToMerge[1];
            this.RemoveWall(w1);
            this.RemoveWall(w2);

            const startPoint = VectorUtils.areVectors2Equal(w1.start, vertex.point) ? w1.end : w1.start;
            const endPoint = VectorUtils.areVectors2Equal(w2.start, vertex.point) ? w2.end : w2.start;
            const startVertexId = Vertex.generateVertexId(startPoint);
            const endVertexId = Vertex.generateVertexId(endPoint);

            const newWallId = WallUtils.generateWallId(startVertexId, endVertexId);
            const newWall = new soWall2D(startPoint.clone(), endPoint.clone());
            this.addWall(newWall);

            // Update edges on vertices
            const startVertex = this.vertices.get(startVertexId);
            const endVertex = this.vertices.get(endVertexId);
            if (startVertex) {
              this.addEdgeToVertex(startVertex, newWall);
            }
            if (endVertex) {
              this.addEdgeToVertex(endVertex, newWall);
            }
          }
        }
      }
    }
  }

  /**
   * Adds an edge (wall) to a vertex in the appropriate direction.
   * Suggestion: Could be moved to a geometry or wall utility file if desired.
   * @param vertex The vertex to add the edge to.
   * @param wall The wall to add.
   */
  private addEdgeToVertex(vertex: Vertex, wall: soWall2D): void {
    const direction = this.getDirectionFromWall(vertex, wall);
    if (direction) {
      vertex.addWallIdBySide(direction, wall.wallId);
    }
  }

  /**
   * Determines the opposite direction of a given side.
   * @param side The original direction.
   * @returns The opposite side.
   */
  private getOppositeDirection(side: string): Side {
    switch (side) {
      case Side.top:
        return Side.bottom;
      case Side.bottom:
        return Side.top;
      case Side.left:
        return Side.right;
      case Side.right:
        return Side.left;
      default:
        return null;
    }
  }

  /**
   * Determines the direction of a wall from a given vertex's perspective.
   * @param vertex The vertex used as a reference point.
   * @param wall The wall to analyze.
   * @returns The side (top, bottom, left, right) if determinable, otherwise null.
   */
  private getDirectionFromWall(vertex: Vertex, wall: soWall2D): Side {
    const wallStartVertex = this.vertices.get(Vertex.generateVertexId(wall.start));
    const wallEndVertex = this.vertices.get(Vertex.generateVertexId(wall.end));
    if (!wallStartVertex || !wallEndVertex) {
      return null;
    }

    if (wallStartVertex.id === vertex.id) {
      if (Math.abs(wallEndVertex.point.x - vertex.point.x) < EPSILON) {
        return wallEndVertex.point.y > vertex.point.y ? Side.top : Side.bottom;
      } else if (Math.abs(wallEndVertex.point.y - vertex.point.y) < EPSILON) {
        return wallEndVertex.point.x > vertex.point.x ? Side.right : Side.left;
      }
    } else if (wallEndVertex.id === vertex.id) {
      if (Math.abs(wallStartVertex.point.x - vertex.point.x) < EPSILON) {
        return wallStartVertex.point.y > vertex.point.y ? Side.top : Side.bottom;
      } else if (Math.abs(wallStartVertex.point.y - vertex.point.y) < EPSILON) {
        return wallStartVertex.point.x > vertex.point.x ? Side.right : Side.left;
      }
    }
    return null;
  }

  /**
   * Removes all edges connected to the given vertex, potentially merging walls if they are collinear.
   * @param vertex The vertex whose edges should be removed.
   */
  private removeEdgesFromVertex(vertex: Vertex): void {
    this.mergeWalls(vertex.id);
    for (const dir of Object.keys(Side)) {
      const wallId = vertex.edges[dir.toLowerCase()];
      if (wallId) {
        this.RemoveWallById(wallId);
      }
    }
  }

  /**
   * Updates the walls based on the bounding box corners of a room.
   * Splits walls if there are intersecting vertices.
   * @param corners An array of 2D corners representing the bounding box.
   */
  private updateWallsForBoundingBox(corners: Vector2[]): void {
    const vertexIds = corners.map(corner => Vertex.generateVertexId(corner));
    const pairs = [
      [0, 1], // Left edge
      [1, 2], // Top edge
      [3, 2], // Right edge
      [0, 3], // Bottom edge
    ];

    for (const [startIndex, endIndex] of pairs) {
      const startVertexId = vertexIds[startIndex];
      const endVertexId = vertexIds[endIndex];
      const wallId = WallUtils.generateWallId(startVertexId, endVertexId);

      if (!this.walls.has(wallId)) {
        const startVertex = this.vertices.get(startVertexId);
        const endVertex = this.vertices.get(endVertexId);

        if (startVertex && endVertex) {
          const wall = new soWall2D(startVertex.point, endVertex.point);
          const intersectingVertices = Array.from(this.vertices.values()).filter(
            v => v.id !== startVertexId && v.id !== endVertexId && wall.isPointOnWall(v.point)
          );

          if (intersectingVertices.length > 0) {
            this.splitWallWithVertices(
              wallId,
              intersectingVertices.map(v => v.id)
            );
          } else {
            this.addWall(wall);
            // If you want to explicitly connect end vertices here, you could do:
            // this.addEdgeToVertex(endVertex, wall);
          }
        }
      } else {
        // Update the function of existing wall and add to cache
        this.updateWallFunction(this.walls.get(wallId));
        this.addToWallCacheById(wallId);
      }
    }
  }
}
