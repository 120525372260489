import * as THREE from "three";
import { Text } from "troika-three-text";
import { feetInchesFractionToInches, inchesToFeetInchesFraction } from "../../helpers/measures";
import { appModel } from "../../models/AppModel";
import { DIMENSION_INDICATOR_RENDER_ORDER, DIMENSION_LABEL_COLOR, DIMENSION_LINE_COLOR } from "../consts";
import RoomManager from "../managers/RoomManager/RoomManager";
import { Direction } from "../models/Direction";

import SceneUtils from "../utils/SceneUtils";
import UnitsUtils from "../utils/UnitsUtils";
import { debounce } from "../../helpers/utilities";
import RoomUtils from "../utils/RoomUtils";
import SceneManager from "../managers/SceneManager/SceneManager";
import { soRoom2D } from "../models/SceneObjects/Room/soRoom2D";
import RaycastUtils from "../utils/GeometryUtils/RaycastUtils";
import BoundingBoxUtils from "../utils/GeometryUtils/BoundingBoxUtils";

const ACTIVE_COLOR = { dec: 0x1f75bb, hex: "#1f75bb" };
const DEFAULT_COLOR = { dec: 0x858585, hex: "#858585" };
const DISABLED_COLOR = { dec: 0x43434359, hex: "#43434359" };

const lockIcon = `
<svg width="15" height="14" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3139_1241)">
<path d="M2.49984 2.66666L2.83317 2.66666L2.83317 2C2.83317 1.08 3.57984 0.333328 4.49984 0.333328C5.41984 0.333328 6.1665 1.08 6.1665 2L6.1665 2.66666L6.49984 2.66666C6.8665 2.66666 7.1665 2.96666 7.1665 3.33333L7.1665 6.66666C7.1665 7.03333 6.8665 7.33333 6.49984 7.33333L2.49984 7.33333C2.13317 7.33333 1.83317 7.03333 1.83317 6.66666L1.83317 3.33333C1.83317 2.96666 2.13317 2.66666 2.49984 2.66666ZM5.49984 2C5.49984 1.44666 5.05317 0.999995 4.49984 0.999995C3.9465 0.999995 3.49984 1.44666 3.49984 2L3.49984 2.66666L5.49984 2.66666L5.49984 2ZM4.49984 5.66666C4.13317 5.66666 3.83317 5.36666 3.83317 5C3.83317 4.63333 4.13317 4.33333 4.49984 4.33333C4.8665 4.33333 5.1665 4.63333 5.1665 5C5.1665 5.36666 4.8665 5.66666 4.49984 5.66666Z" fill="#858585"/>
</g>
<defs>
<clipPath id="clip0_3139_1241">
<rect width="8" height="8" fill="white" transform="matrix(-1 4.37114e-08 4.37114e-08 1 8.5 0)"/>
</clipPath>
</defs>
</svg>
`;

export class DimensionLine extends THREE.Object3D {
  private startLine: THREE.Line;
  private line: THREE.Line;
  private endLine: THREE.Line;
  private startDashedLine: THREE.Line;
  private endDashedLine: THREE.Line;

  private inputElement: HTMLInputElement;
  private inputContainer: HTMLDivElement;
  private iconElement: HTMLElement;

  private direction: Direction;
  private isShowingNet: boolean;
  private smallLineLength: number;
  private roomManager: RoomManager;
  private isChangingFooterProps: boolean = false;
  private offset: number;

  constructor(direction: Direction, isShowingNet: boolean, roomManager, offset: number) {
    super();

    this.roomManager = roomManager;
    this.direction = direction;
    this.isShowingNet = isShowingNet;
    this.offset = offset;

    this.setDimensionActive = this.setDimensionActive.bind(this);
    this.setDimensionToDefault = this.setDimensionToDefault.bind(this);

    this.line = new THREE.Line(
      new THREE.BufferGeometry(),
      new THREE.LineDashedMaterial({
        color: DEFAULT_COLOR.dec,
        dashSize: 0.15 * UnitsUtils.getConversionFactor(),
        gapSize: 0,
        transparent: true,
      })
    );
    this.line.renderOrder = DIMENSION_INDICATOR_RENDER_ORDER;

    const h = 0.257 * UnitsUtils.getConversionFactor();
    this.smallLineLength = h;

    const horizontalLineGeometry = new THREE.BufferGeometry().setFromPoints([
      new THREE.Vector3(0, -this.smallLineLength, 0),
      new THREE.Vector3(0, this.smallLineLength, 0),
    ]);

    const verticalLineGeometry = new THREE.BufferGeometry().setFromPoints([
      new THREE.Vector3(-this.smallLineLength, 0, 0),
      new THREE.Vector3(this.smallLineLength, 0, 0),
    ]);

    const lineMaterial = new THREE.LineBasicMaterial({ color: DIMENSION_LINE_COLOR });

    const horizontalDashedLineGeometry = new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, -this.offset, 0)]);
    const verticalDashedLineGeometry = new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(0, 0, 0), new THREE.Vector3(-this.offset, 0, 0)]);

    const dashedLineMaterial = new THREE.LineDashedMaterial({
      color: DIMENSION_LINE_COLOR,
      dashSize: 2,
      gapSize: 1,
    });

    if (this.direction === Direction.Horizontal) {
      this.startLine = new THREE.Line(horizontalLineGeometry, lineMaterial);
      this.endLine = new THREE.Line(horizontalLineGeometry, lineMaterial);
      this.startDashedLine = new THREE.Line(horizontalDashedLineGeometry, dashedLineMaterial);
      this.endDashedLine = new THREE.Line(horizontalDashedLineGeometry.clone(), dashedLineMaterial);
    } else {
      this.startLine = new THREE.Line(verticalLineGeometry, lineMaterial);
      this.endLine = new THREE.Line(verticalLineGeometry, lineMaterial);
      this.startDashedLine = new THREE.Line(verticalDashedLineGeometry, dashedLineMaterial);
      this.endDashedLine = new THREE.Line(verticalDashedLineGeometry.clone(), dashedLineMaterial);
    }

    this.startLine.renderOrder = DIMENSION_INDICATOR_RENDER_ORDER;
    this.endLine.renderOrder = DIMENSION_INDICATOR_RENDER_ORDER;
    this.startDashedLine.renderOrder = DIMENSION_INDICATOR_RENDER_ORDER;
    this.endDashedLine.renderOrder = DIMENSION_INDICATOR_RENDER_ORDER;

    this.startDashedLine.computeLineDistances();
    this.endDashedLine.computeLineDistances();
    this.startDashedLine.visible = false;
    this.endDashedLine.visible = false;

    this.add(this.startLine, this.line, this.endLine, this.endDashedLine, this.startDashedLine);

    this.inputElement = document.createElement("input");
    this.inputContainer = document.createElement("div");
    this.inputContainer.style.position = "absolute";
    this.inputContainer.style.width = "112px";
    this.inputContainer.style.height = "25px";
    this.inputContainer.style.zIndex = "1";
    this.inputContainer.style.display = "none";
    if (this.direction === Direction.Vertical) {
      const scaleFactor = this.getScale();
      this.inputContainer.style.transform = `scale(${scaleFactor}) rotate(90deg)`;
    }

    this.inputElement.type = "text";
    this.inputElement.style.position = "absolute";
    this.inputElement.style.width = "112px";
    this.inputElement.style.height = "25px";
    this.inputElement.style.textAlign = "center";
    this.inputElement.style.borderRadius = "15px";
    this.inputElement.style.color = "#000";
    this.inputElement.style.borderColor = DEFAULT_COLOR.hex;
    this.inputElement.style.borderStyle = "solid";
    this.inputElement.style.zIndex = "1";
    this.inputElement.style.display = "block";
    document.body.appendChild(this.inputElement);

    this.iconElement = document.createElement("i");
    this.iconElement.innerHTML = lockIcon;
    this.iconElement.style.position = "absolute";
    this.iconElement.style.left = "8px";
    this.iconElement.style.zIndex = "2";

    this.iconElement.style.top = "14px";
    this.iconElement.style.display = "none";
    this.iconElement.style.transform = "translateY(-50%)";
    this.iconElement.style.pointerEvents = "none";
    this.inputElement.style.boxShadow = "none";
    this.inputElement.style.outline = "none";

    this.inputContainer.appendChild(this.iconElement);
    this.inputContainer.appendChild(this.inputElement);

    document.body.appendChild(this.inputContainer);

    this.inputElement.addEventListener("click", event => {
      if (this.direction === Direction.Vertical) {
        const scaleFactor = this.getScale();
        this.inputContainer.style.transform = `scale(${scaleFactor}) rotate(0deg)`;
      }
      this.setDimensionActive();
    });

    this.inputContainer.addEventListener("mouseenter", this.setDimensionActive);
    this.inputContainer.addEventListener("mouseleave", this.setDimensionToDefault);

    this.inputElement.addEventListener(
      "input",
      debounce(event => {
        if (!this.inputElement?.value) return;
        const parsed = feetInchesFractionToInches(this.inputElement.value);
        const rooms = appModel.activeCorePlan?.getRooms(appModel.selectedRoomsIds) || [];
        const copyLockedRoomDimensions = { ...appModel.activeCorePlan.lockedRoomDimensions };
        this.isChangingFooterProps = true;

        if (parsed !== null) {
          const roomId = rooms[0].id;
          if (this.direction === Direction.Vertical) {
            rooms[0].setNetHeight(parsed);
            copyLockedRoomDimensions[roomId] = {
              x: copyLockedRoomDimensions && copyLockedRoomDimensions[roomId] ? appModel.activeCorePlan.lockedRoomDimensions[roomId].x : false,
              y: true,
            };
          } else {
            copyLockedRoomDimensions[roomId] = {
              x: true,
              y: copyLockedRoomDimensions && copyLockedRoomDimensions[roomId] ? appModel.activeCorePlan.lockedRoomDimensions[roomId].y : false,
            };
            rooms[0].setNetWidth(parsed);
          }
          this.setSize(parsed);
        }

        appModel.activeCorePlan.setLockedRoomDimensions(copyLockedRoomDimensions);
        this.toggleLockDimension(true);
      }, 300)
    );

    this.inputElement.addEventListener("blur", event => {
      if (this.direction === Direction.Vertical) {
        const scaleFactor = this.getScale();
        this.inputContainer.style.transform = `scale(${scaleFactor}) rotate(90deg)`;
      }
      this.isChangingFooterProps = false;
      this.setDimensionToDefault();
    });
  }

  public setSize(size: number): void {
    const halfSize = this.direction === Direction.Horizontal ? new THREE.Vector3(1, 0, 0) : new THREE.Vector3(0, 1, 0);

    halfSize.multiplyScalar(size / 2);
    this.line.geometry.setFromPoints([halfSize.clone().negate(), halfSize.clone()]);
    this.line.geometry.computeBoundingSphere();
    this.line.computeLineDistances();
    const startLineOffset = halfSize.clone().negate();
    const endLineOffset = halfSize.clone();

    this.startLine.position.copy(startLineOffset);
    this.endLine.position.copy(endLineOffset);
    this.startDashedLine.position.copy(startLineOffset);
    this.endDashedLine.position.copy(endLineOffset);

    if (!this.isChangingFooterProps) {
      this.setText(inchesToFeetInchesFraction(size, UnitsUtils.getRoundFractionPrecision()));
      this.waitForRectReady();
    }
  }

  public setDashedLinesLength(startDashedLineLen: number = 1, endDashedLineLen: number = 1): void {
    const vec = this.direction === Direction.Vertical ? new THREE.Vector3(-1, 0, 0) : new THREE.Vector3(0, -1, 0);
    this.startDashedLine.geometry.setFromPoints([new THREE.Vector3(), vec.clone().multiplyScalar(startDashedLineLen)]);
    this.endDashedLine.geometry.setFromPoints([new THREE.Vector3(), vec.clone().multiplyScalar(endDashedLineLen)]);
    this.startDashedLine.computeLineDistances();
    this.endDashedLine.computeLineDistances();
  }

  public setDimensionActive(): void {
    this.startDashedLine.visible = true;
    this.endDashedLine.visible = true;
    this.setColor(ACTIVE_COLOR.dec);
    this.inputElement.style.borderColor = ACTIVE_COLOR.hex;
  }

  public setDimensionToDefault(): void {
    if (document.activeElement !== this.inputElement) {
      this.startDashedLine.visible = false;
      this.endDashedLine.visible = false;
      this.setColor(DEFAULT_COLOR.dec);
      this.inputElement.style.borderColor = DEFAULT_COLOR.hex;
    }
  }

  public getScale(): number {
    const camera = this.roomManager.camera;

    const cameraZ = camera.position.z;

    const referenceZ = 0;
    const zDistance = Math.abs(cameraZ - referenceZ);

    const scaleFactor = 220 / zDistance;

    return scaleFactor;
  }

  public setColor(color: number): void {
    (this.line.material as THREE.LineBasicMaterial).color.set(color);
    (this.startLine.material as THREE.LineBasicMaterial).color.set(color);
    (this.endLine.material as THREE.LineBasicMaterial).color.set(color);
    (this.startDashedLine.material as THREE.LineBasicMaterial).color.set(color);
    (this.endDashedLine.material as THREE.LineBasicMaterial).color.set(color);
  }

  public toggleLockDimension(isLock: boolean): void {
    this.iconElement.style.display = isLock ? "block" : "none";
  }

  public setDimensionDisabled(): void {
    this.setColor(DISABLED_COLOR.dec);
    this.inputElement.style.color = DISABLED_COLOR.hex;
    this.inputElement.style.borderColor = DISABLED_COLOR.hex;
    this.inputElement.style.pointerEvents = "none";
    this.inputContainer.style.pointerEvents = "none";
    this.inputElement.style.cursor = "not-allowed";

    this.inputContainer.removeEventListener("mouseenter", this.setDimensionActive);
    this.inputContainer.removeEventListener("mouseleave", this.setDimensionToDefault);

    (this.line.material as THREE.LineBasicMaterial).opacity = 0.35;
    (this.startLine.material as THREE.LineBasicMaterial).opacity = 0.55;
    (this.endLine.material as THREE.LineBasicMaterial).opacity = 0.55;
  }

  public setDimensionsEnable(): void {
    this.setColor(DEFAULT_COLOR.dec);
    this.inputElement.style.color = "#000";
    this.inputElement.style.borderColor = DEFAULT_COLOR.hex;
    this.inputElement.style.pointerEvents = "auto";
    this.inputContainer.style.pointerEvents = "auto";
    this.inputElement.style.cursor = "default";

    this.inputContainer.addEventListener("mouseenter", this.setDimensionActive);
    this.inputContainer.addEventListener("mouseleave", this.setDimensionToDefault);

    (this.line.material as THREE.LineBasicMaterial).opacity = 1;
    (this.startLine.material as THREE.LineBasicMaterial).opacity = 1;
    (this.endLine.material as THREE.LineBasicMaterial).opacity = 1;
  }

  private setText(text: string): void {
    this.inputElement.value = text;
  }

  private waitForRectReady(retryLimit: number = 20): void {
    let retryCount = 0;

    const checkRect = () => {
      const rect = this.roomManager.baseManager.getParentContainerRectangle();
      if (rect && rect.width > 0 && rect.height > 0) {
        this.updateInputElementPosition();
      } else if (retryCount < retryLimit) {
        retryCount++;
        requestAnimationFrame(checkRect);
      } else {
        console.error("Container rect not ready after maximum retries.");
      }
    };

    requestAnimationFrame(checkRect);
  }

  private updateInputElementPosition(): void {
    const camera = this.roomManager.camera;

    const labelPosition = new THREE.Vector3();
    this.line.getWorldPosition(labelPosition);

    const rect = this.roomManager.baseManager.getParentContainerRectangle();

    const scaleFactor = this.getScale();
    const labelScreenPos = this.toScreenPosition(labelPosition, camera, rect);

    if (this.direction === Direction.Vertical) {
      this.inputContainer.style.transform = `scale(${scaleFactor}) rotate(90deg)`;
    } else {
      this.inputContainer.style.transform = `scale(${scaleFactor})`;
    }

    this.inputContainer.style.display = this.updateVisibility();
    this.inputContainer.style.left = `${labelScreenPos.x - this.inputContainer.offsetWidth / 2}px`;
    this.inputContainer.style.top = `${labelScreenPos.y - this.inputContainer.offsetHeight / 2}px`;
  }

  private toScreenPosition(vector: THREE.Vector3, camera: THREE.Camera, rect: DOMRect) {
    if (!rect) {
      return { x: 0, y: 0 };
    }
    const widthHalf = 0.5 * rect.width;
    const heightHalf = 0.5 * rect.height;

    vector.project(camera);
    return {
      x: vector.x * widthHalf + widthHalf + rect.left,
      y: -(vector.y * heightHalf) + heightHalf + rect.top,
    };
  }

  public toggleInputElement(isVisible): void {
    const display = isVisible ? "block" : "none";
    if (this.inputElement) {
      this.inputElement.style.display = display;
      this.inputContainer.style.display = display;
      this.iconElement.style.display = display;
    }
  }
  public deleteInputElement(): void {
    this.inputContainer.style.display = "none";
  }

  private updateVisibility(): string {
    const camera = this.roomManager.camera;

    const frustum = new THREE.Frustum();
    const matrix = new THREE.Matrix4().multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);
    frustum.setFromProjectionMatrix(matrix);

    const verticalLinePosition = new THREE.Vector3();
    this.getWorldPosition(verticalLinePosition);

    const isVisible = frustum.containsPoint(verticalLinePosition);

    return isVisible ? "block" : "none";
  }
}

export default class SoRoomDimensionTool {
  private roomManager: any;
  private soRooms: soRoom2D[] = [];
  private horizontalLine: DimensionLine;
  private verticalLine: DimensionLine;
  private isShowingNet: boolean;
  private offset: number;
  private isListenersDisabled: boolean = false;

  constructor(roomManager: any, offset: number, isShowingNet: boolean) {
    if (!(roomManager instanceof RoomManager) && !(roomManager instanceof SceneManager)) {
      throw new Error("Manager is not an instance of RoomManager");
    }
    this.offset = offset;
    this.roomManager = roomManager;
    this.isShowingNet = isShowingNet;

    this.horizontalLine = new DimensionLine(Direction.Horizontal, isShowingNet, roomManager, this.offset);
    this.verticalLine = new DimensionLine(Direction.Vertical, isShowingNet, roomManager, this.offset);

    this.setIndicatorsVisibility(false);
  }

  public addTo(parent: THREE.Object3D): void {
    parent.add(this.horizontalLine, this.verticalLine);
  }

  public removeFrom(parent: THREE.Object3D): void {
    parent.remove(this.horizontalLine, this.verticalLine);
  }

  public setRooms(soRooms: soRoom2D[], isFloor: boolean = false): void {
    this.soRooms = soRooms;
    this.updateSize(isFloor);
  }

  public updateSize(isFloor: boolean = false): void {
    if (this.soRooms.length === 0) {
      this.setIndicatorsVisibility(false);

      return;
    }

    if (isFloor || this.isListenersDisabled) {
      this.setDimensionsDisabled(true);
    } else {
      this.setDimensionsDisabled(false);
    }

    this.setIndicatorsVisibility(true);
    const lockedRoomDimensions = appModel.activeCorePlan?.lockedRoomDimensions && appModel.activeCorePlan?.lockedRoomDimensions[this.soRooms[0].userData.id];
    if (this.soRooms.length === 1 && lockedRoomDimensions && !isFloor) {
      if (lockedRoomDimensions.x) {
        this.horizontalLine.toggleLockDimension(true);
      } else {
        this.horizontalLine.toggleLockDimension(false);
      }
      if (lockedRoomDimensions.y) {
        this.verticalLine.toggleLockDimension(true);
      } else {
        this.verticalLine.toggleLockDimension(false);
      }
    } else {
      this.verticalLine.toggleLockDimension(false);
      this.horizontalLine.toggleLockDimension(false);
    }

    const box = this.calculateRoomsBoundary();
    const roomsModelLineBox = new THREE.Box3();

    this.soRooms.forEach(soRoom => roomsModelLineBox.union(soRoom.getSoRoomBoundingBoxByModelLines()));

    const center = box.getCenter(new THREE.Vector3());

    this.verticalLine.position.set(roomsModelLineBox.max.x + this.offset, center.y, 0);
    this.horizontalLine.position.set(center.x, roomsModelLineBox.max.y + this.offset, 0);

    const size = BoundingBoxUtils.getBoundingBoxSize(box);

    this.horizontalLine.setSize(size.x);
    this.verticalLine.setSize(size.y);

    this.updateDashedLinesLength(box);
  }

  // box is parameter because we want to know where to cast the rays from (the box + offset is the position of the dimension tool)
  private updateDashedLinesLength(box: THREE.Box3): void {
    if (this.soRooms.length === 0) {
      return;
    }
    if (this.soRooms.length === 1) {
      this.horizontalLine.setDashedLinesLength(this.offset, this.offset);
      this.verticalLine.setDashedLinesLength(this.offset, this.offset);
      return;
    }
    const boundaryBoxArr = this.soRooms.map(soRoom => RoomUtils.getSoRoomNetBoundingBox(this.roomManager, soRoom));

    const horizontalStartRay = new THREE.Ray(new THREE.Vector3(box.min.x, box.max.y + this.offset, 0), new THREE.Vector3(0, -1, 0));
    const horizontalEndRay = new THREE.Ray(new THREE.Vector3(box.max.x, box.max.y + this.offset, 0), new THREE.Vector3(0, -1, 0));
    const horizontalClosestIntersectionStart = RaycastUtils.getClosestIntersectionWithBoxes(horizontalStartRay, boundaryBoxArr);
    const horizontalClosestIntersectionEnd = RaycastUtils.getClosestIntersectionWithBoxes(horizontalEndRay, boundaryBoxArr);

    const verticalStartRay = new THREE.Ray(new THREE.Vector3(box.max.x + this.offset, box.min.y, 0), new THREE.Vector3(-1, 0, 0));
    const verticalEndRay = new THREE.Ray(new THREE.Vector3(box.max.x + this.offset, box.max.y, 0), new THREE.Vector3(-1, 0, 0));
    const verticalClosestIntersectionStart = RaycastUtils.getClosestIntersectionWithBoxes(verticalStartRay, boundaryBoxArr);
    const verticalClosestIntersectionEnd = RaycastUtils.getClosestIntersectionWithBoxes(verticalEndRay, boundaryBoxArr);

    this.horizontalLine.setDashedLinesLength(horizontalClosestIntersectionStart.distance, horizontalClosestIntersectionEnd.distance);
    this.verticalLine.setDashedLinesLength(verticalClosestIntersectionStart.distance, verticalClosestIntersectionEnd.distance);
  }

  private calculateRoomsBoundary(): THREE.Box3 {
    const bb = new THREE.Box3();
    //todo remove this check when removing FF
    if (this.roomManager instanceof SceneManager) {
      this.soRooms.forEach(soRoom => {
        if (this.isShowingNet) {
          bb.union(soRoom.NetBoundingBox);
        } else {
          bb.union(soRoom.boundingBoxByModelLine);
        }
      });
    } else {
      this.soRooms.forEach(soRoom => {
        if (this.isShowingNet) {
          bb.union(RoomUtils.getRoomNetBoundingBox(this.roomManager, soRoom));
        } else {
          bb.union(RoomUtils.getRoomBoundingBoxByModelLines(soRoom));
        }
      });
    }

    if (!this.isShowingNet) {
      const offset = appModel.showCladding ? UnitsUtils.getAreaCalculationExteriorOffset() : UnitsUtils.getSyntheticWallHalfSize();
      bb.expandByVector(new THREE.Vector3(offset, offset));
    }

    return bb;
  }

  private setIndicatorsVisibility(isVisible: boolean): void {
    this.horizontalLine.visible = isVisible;
    this.verticalLine.visible = isVisible;
    this.verticalLine.toggleInputElement(isVisible);
    this.horizontalLine.toggleInputElement(isVisible);
  }

  public setDimensionsDisabled(isDisable: boolean): void {
    if (isDisable) {
      this.verticalLine.setDimensionDisabled();
      this.horizontalLine.setDimensionDisabled();
    } else {
      this.verticalLine.setDimensionsEnable();
      this.horizontalLine.setDimensionsEnable();
    }
  }

  public toggleInputs(isVisible: boolean): void {
    if (this.soRooms.length === 0) {
      return;
    }
    this.verticalLine.toggleInputElement(isVisible);
    this.horizontalLine.toggleInputElement(isVisible);
  }
  public deleteInput(): void {
    this.verticalLine.deleteInputElement();
    this.horizontalLine.deleteInputElement();
    // this.horizontalLine = new DimensionLine(Direction.Horizontal, this.isShowingNet, this.roomManager);
    // this.verticalLine = new DimensionLine(Direction.Vertical, this.isShowingNet, this.roomManager);
  }

  public toggleListeners(isDisable: boolean): void {
    this.isListenersDisabled = isDisable;
  }
}
