import { appModel } from "../../../models/AppModel";
import { Room } from "../../../models/Room";
import SceneUtils from "../../utils/SceneUtils";
import RoomManager from "../../managers/RoomManager/RoomManager";
import { CommandTypes } from "./CommandTypes";
import { RoomCommand } from "./RoomCommand";

/**
 *@deprecated
 */
export class DeleteRoomCommand extends RoomCommand {
  private room: Room;

  constructor(private soRoom: THREE.Object3D) {
    super(soRoom.userData.id);

    this.type = CommandTypes.DeleteRoomCommand;
  }

  apply(roomManager: RoomManager): void {
    roomManager.selectRoom(this.soRoom, false);
    SceneUtils.unhighlightSelectedRoom(this.soRoom);
    SceneUtils.displayRoomStretchControls(this.soRoom, false);

    roomManager.getActiveSoFloor()?.remove(this.soRoom);

    this.room = appModel.activeFloor.rooms.find(r => r.id === this.soRoom.userData.id);
    appModel.activeFloor.removeRoom(this.soRoom.userData.id);
  }

  undo(roomManager: RoomManager): void {
    roomManager.getActiveSoFloor()?.add(this.soRoom);
    appModel.activeFloor.addRoom(this.room);

    roomManager.checkRoomsSharedObjects([this.entityId]);
  }
}
