import * as THREE from "three";
import { v4 as uuidv4 } from "uuid";
import GeometryUtils from "../../utils/GeometryUtils/GeometryUtils";

/**
 * Abstract class extending THREE.Group with an added unique identifier.
 */
export abstract class soGroup extends THREE.Group {
  soId: string;

  /**
   * Constructs an instance of soGroup, generating a unique GUID for the soId.
   */
  constructor() {
    super();
    this.soId = uuidv4();
  }
  CleanChildren(): void {
    let count = 0;
    while (this.children.length > 0 && count < 10000) {
      GeometryUtils.disposeObject(this.children[0]);
      this.remove(this.children[0]);
      count++;
    }
  }
}
