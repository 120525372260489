import { BackgroundManager } from "../../managers/SceneManager/BackgroundManager";
import { BackgroundCommand } from "./BackgroundCommand";
import { CommandTypes } from "./CommandTypes";

export class TranslateBackgroundCommand extends BackgroundCommand {
  constructor(
    entityId: string,
    private delta: THREE.Vector3
  ) {
    super(entityId);

    this.type = CommandTypes.TranslateBackgroundCommand;
  }

  apply(manager: BackgroundManager): void {
    const soBackground = manager.findSoBackground(this.entityId);
    soBackground.position.add(this.delta);
    soBackground.updateMatrixWorld();

    manager.updateActiveBackgroundProperties();
  }

  undo(manager: BackgroundManager): void {
    const soBackground = manager.findSoBackground(this.entityId);
    soBackground.position.sub(this.delta);
    soBackground.updateMatrixWorld();

    manager.updateActiveBackgroundProperties();
  }
}
