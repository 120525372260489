import { Box3, Vector2, Vector3 } from "three";
import VectorUtils from "./VectorUtils";
import MathUtils from "../MathUtils";

export default class BoundingBoxUtils {
  /**
   * Retrieves the four corners of a bounding box.
   * @param boundingBox The bounding box to extract corners from.
   * @returns An array of Vector2 representing the corners.
   */
  static getBoundingBoxCorners2D(boundingBox: Box3): Vector2[] {
    const min = boundingBox.min;
    const max = boundingBox.max;
    return [new Vector2(min.x, min.y), new Vector2(min.x, max.y), new Vector2(max.x, max.y), new Vector2(max.x, min.y)];
  }
  /**
   * Retrieves the four corners of a bounding box.
   * @param boundingBox The bounding box to extract corners from.
   * @returns An array of Vector2 representing the corners.
   */
  static getFlatBoundingBoxCorners3D(boundingBox: Box3): Vector3[] {
    const min = boundingBox.min;
    const max = boundingBox.max;
    return [new Vector3(min.x, min.y), new Vector3(min.x, max.y), new Vector3(max.x, max.y), new Vector3(max.x, min.y)];
  }
  /**
   * Retrieves the center of a bounding box.
   * @param boundingBox The bounding box to find the center of.
   * @returns A Vector3 representing the center of the bounding box.
   */
  static getBoundingBoxCenter(boundingBox: Box3): Vector3 {
    const min = boundingBox.min.clone();
    const max = boundingBox.max.clone();
    return new Vector3((min.x + max.x) / 2, (min.y + max.y) / 2, (min.z + max.z) / 2);
  }
  /**
   * Retrieves the size of a bounding box.
   * @param boundingBox The bounding box to find the size of.
   * @returns A Vector3 representing the size of the bounding box.
   */
  static getBoundingBoxSize(boundingBox: Box3): Vector3 {
    const min = boundingBox.min;
    const max = boundingBox.max;
    return new Vector3(max.x - min.x, max.y - min.y, max.z - min.z);
  }
  /**
   * Returns all sub-Box3 segments of `mainBox` after subtracting
   * each box from `splittingBoxes`.
   */
  public static boundingBoxBooleanDifference(mainBox: THREE.Box3, splittingBoxes: THREE.Box3[]): THREE.Box3[] {
    let result = [mainBox.clone()];

    for (const splitBox of splittingBoxes) {
      const newResult: THREE.Box3[] = [];
      for (const piece of result) {
        newResult.push(...BoundingBoxUtils.subtractBox(piece, splitBox));
      }
      result = newResult;
    }

    return result;
  }

  /**
   * Subtracts `boxB` from `boxA`.
   * Returns the (up to) six sub-boxes outside their intersection.
   */
  private static subtractBox(boxA: THREE.Box3, boxB: THREE.Box3): THREE.Box3[] {
    const intersection = BoundingBoxUtils.intersectBoundingBoxes(boxA.clone(), boxB.clone());

    // If there's no overlap, just return the original box
    if (!intersection) {
      return [boxA];
    }

    // For elegance, define slices in each axis:
    //  X-axis has [Amin.x, Imin.x], [Imin.x, Imax.x], [Imax.x, Amax.x]
    //  (and similarly for Y, Z)
    const Amin = boxA.min,
      Amax = boxA.max;
    const Imin = intersection.min,
      Imax = intersection.max;

    const xSlices: [number, number][] = [
      [Amin.x, Imin.x],
      [Imin.x, Imax.x],
      [Imax.x, Amax.x],
    ];
    const ySlices: [number, number][] = [
      [Amin.y, Imin.y],
      [Imin.y, Imax.y],
      [Imax.y, Amax.y],
    ];
    const zSlices: [number, number][] = [
      [Amin.z, Imin.z],
      [Imin.z, Imax.z],
      [Imax.z, Amax.z],
    ];

    // Utility to build a Box3 from slice bounds; returns null if degenerate
    function buildBox(x0: number, x1: number, y0: number, y1: number, z0: number, z1: number): THREE.Box3 | null {
      if (x1 <= x0 || y1 <= y0) {
        return null;
      }
      return new Box3(new Vector3(x0, y0, z0), new Vector3(x1, y1, z1));
    }

    const result: Map<string, THREE.Box3> = new Map();
    // Enumerate all 3×3×3 = 27 slices, skip the intersection region
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 3; j++) {
        for (let k = 0; k < 3; k++) {
          // (1,1,1) corresponds to the intersection slice
          if (i === 1 && j === 1) {
            continue;
          }

          const [xMin, xMax] = xSlices[i];
          const [yMin, yMax] = ySlices[j];
          const [zMin, zMax] = zSlices[k];

          const subBox = buildBox(xMin, xMax, yMin, yMax, MathUtils.round(zMin), MathUtils.round(zMax));

          if (subBox) {
            const boxKey = BoundingBoxUtils.boxToKey(subBox);
            if (!result.has(boxKey)) result.set(boxKey, subBox);
          }
        }
      }
    }
    return Array.from(result.values());
  }
  /**
   * Converts a THREE.Box3 to a unique string key based on its min and max.
   * @param box The THREE.Box3 to convert.
   * @returns A string representation of the box.
   */
  private static boxToKey(box: THREE.Box3): string {
    const min = box.min
      .toArray()
      .map(v => MathUtils.round(v))
      .join(",");
    const max = box.max
      .toArray()
      .map(v => MathUtils.round(v))
      .join(",");
    return `min:${min},max:${max}`;
  }

  /**
   * Converts a string key back to a THREE.Box3.
   * @param key The string key.
   * @returns A THREE.Box3 object.
   */
  static keyToBox(key: string): THREE.Box3 {
    const [minPart, maxPart] = key.split(",max:");
    const min = new Vector3(...minPart.replace("min:", "").split(",").map(parseFloat));
    const max = new Vector3(...maxPart.split(",").map(parseFloat));
    return new Box3(min, max);
  }
  /**
   * Computes the intersection of two bounding boxes numerically.
   * @param boxA The first bounding box (THREE.Box3).
   * @param boxB The second bounding box (THREE.Box3).
   * @returns An object with `min` and `max` as numeric arrays, or `null` if there is no intersection.
   */
  static intersectBoundingBoxes(boxA: THREE.Box3, boxB: THREE.Box3): Box3 {
    // Compute the overlapping ranges for each axis
    const minX = Math.max(boxA.min.x, boxB.min.x);
    const maxX = Math.min(boxA.max.x, boxB.max.x);

    const minY = Math.max(boxA.min.y, boxB.min.y);
    const maxY = Math.min(boxA.max.y, boxB.max.y);

    const minZ = Math.max(boxA.min.z, boxB.min.z);
    const maxZ = Math.min(boxA.max.z, boxB.max.z);

    // Check if there is a valid intersection
    if (minX >= maxX || minY >= maxY) {
      return null;
    }

    return new Box3(new Vector3(minX, minY, minZ), new Vector3(maxX, maxY, maxZ));
  }
}
