import { appModel } from "../../../models/AppModel";
import { Room } from "../../../models/Room";
import SceneUtils from "../../utils/SceneUtils";
import RoomManager from "../../managers/SceneManager/SceneManager";
import { CommandTypes } from "./CommandTypes";
import { RoomCommand } from "./RoomCommand";
import { soRoom2D } from "../SceneObjects/Room/soRoom2D";

export class SoDeleteRoomCommand extends RoomCommand {
  private room: Room;

  constructor(private soRoom: soRoom2D) {
    super(soRoom.userData.id);

    this.type = CommandTypes.DeleteRoomCommand;
  }

  apply(roomManager: RoomManager): void {
    roomManager.selectRoom(this.soRoom, false);
    SceneUtils.unhighlightSelectedRoom(this.soRoom);
    SceneUtils.displayRoomStretchControls(this.soRoom, false);

    //roomManager.getActiveSoFloor()?.remove(this.soRoom);
    roomManager.getActiveSoFloor()?.removeRoom(this.soRoom, false);
    this.room = appModel.activeFloor.rooms.find(r => r.id === this.soRoom.userData.id);
    appModel.activeFloor.removeRoom(this.soRoom.userData.id);
  }

  undo(roomManager: RoomManager): void {
    roomManager.getActiveSoFloor()?.addRoom(this.soRoom);
    appModel.activeFloor.addRoom(this.room);

    roomManager.checkRoomsSharedObjects([this.entityId]);
  }
}
