import * as THREE from "three";
import GeometryUtils from "../utils/GeometryUtils/GeometryUtils";
import SegmentsUtils from "../utils/SegmentsUtils";
import { Segment } from "./segments/Segment";
import VectorUtils from "../utils/GeometryUtils/VectorUtils";

export class Space {
  public innerSegments: Segment[];

  private _contour: Segment[];
  private contourPoints: THREE.Vector3[] = [];

  constructor(contour: Segment[] = [], innerSegments: Segment[] = []) {
    this.contour = contour;
    this.innerSegments = innerSegments;
  }

  public get contour(): Segment[] {
    return this._contour;
  }
  public set contour(value: Segment[]) {
    this._contour = value;
    this.contourPoints = this.contour.map(segment => VectorUtils.Vector2ToVector3(segment.end));
  }

  public containsSpace(space: Space): boolean {
    return space.contourPoints.every(point => this.containsPoint(point));
  }

  public containsSegment(segment: Segment): boolean {
    return this.containsPoint(VectorUtils.Vector2ToVector3(segment.start)) || this.containsPoint(VectorUtils.Vector2ToVector3(segment.end));
  }

  public containsPoint(point: THREE.Vector3): boolean {
    return GeometryUtils.isPointInsidePolygon(this.contourPoints, point);
  }
}

export class FloorSpace {
  space: Space;
  subspaces: Space[];

  constructor(space: Space, subspaces: Space[]) {
    this.space = space;
    this.subspaces = subspaces;
  }

  public extractSegments(): Segment[] {
    const segments: Segment[] = [];
    this.space.contour.forEach(s => segments.push(s.clone()));
    this.space.innerSegments.forEach(s => segments.push(s.clone()));
    this.subspaces.forEach(space => space.contour.forEach(s => segments.push(s.clone())));

    // The algorithm assumes that all segments are oriented from bottom to top or from left to right
    SegmentsUtils.alignSegments(segments);

    return segments;
  }
}

export class FloorSpaceNode {
  children: FloorSpaceNode[] = [];
  space: Space;

  constructor(space: Space) {
    this.space = space;
  }

  public add(node: FloorSpaceNode): void {
    this.children.push(node);
  }

  public remove(node: FloorSpaceNode): void {
    const idx = this.children.indexOf(node);

    if (idx !== -1) {
      this.children.splice(idx, 1);
    }
  }

  public getFloorSpaces(): FloorSpace[] {
    const result = [
      new FloorSpace(
        this.space,
        this.children.map(node => node.space)
      ),
    ];
    this.children.forEach(child => result.push(...child.getFloorSpaces()));

    return result;
  }
}

export class FloorSpaceTree {
  // root <=> infinity space
  root: FloorSpaceNode = new FloorSpaceNode(
    new Space(
      [
        new Segment(
          new THREE.Vector2(Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY),
          new THREE.Vector2(Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY)
        ),
        new Segment(
          new THREE.Vector2(Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY),
          new THREE.Vector2(Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY)
        ),
        new Segment(
          new THREE.Vector2(Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY),
          new THREE.Vector2(Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY)
        ),
        new Segment(
          new THREE.Vector2(Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY),
          new THREE.Vector2(Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY)
        ),
      ],
      []
    )
  );

  public insertSpace(space?: Space): void {
    if (space.contour.length) {
      const newNode = new FloorSpaceNode(space);

      let node = this.root;
      let prevNode: FloorSpaceNode;
      while (node) {
        prevNode = node;
        node = node.children.find(node => node.space.containsSpace(space));
      }

      const innerNodes = prevNode.children.filter(node => space.containsSpace(node.space));
      if (innerNodes.length) {
        innerNodes.forEach(node => {
          newNode.add(node);
        });
      }

      prevNode.add(newNode);
    } else {
      space.innerSegments.forEach(segment => this.insertInnerSegment(segment));
    }
  }

  public insertInnerSegment(segment: Segment): void {
    let node = this.root;
    let prevNode: FloorSpaceNode;

    while (node) {
      prevNode = node;
      node = node.children.find(node => node.space.containsSegment(segment));
    }

    prevNode.space.innerSegments.push(segment);
  }

  public extractFloorSpaces(): FloorSpace[] {
    return this.root.children.flatMap(node => node.getFloorSpaces());
  }

  public postOrder(): Space[] {
    const result: Space[] = [];

    const traverse = (node: FloorSpaceNode) => {
      node.children.forEach(traverse);

      if (node !== this.root) {
        result.push(node.space);
      }
    };

    traverse(this.root);

    return result;
  }
}
