import { appModel } from "../../../models/AppModel";
import { soOpening } from "../../models/SceneObjects/Openings/soOpening";
import { soWall2D } from "../../models/SceneObjects/Wall/soWall2D";
import { IManager } from "../IManager";
import SceneManager from "./SceneManager";

/**
 * Class representing a selection manager in the scene.
 * Extends from IManager.
 */
export class soSelectionManager implements IManager {
  private selectedRoomIds: Set<string> = new Set();
  private selectedLotItemsIds: Set<string> = new Set();
  private selectedRoomOpenings: soOpening[] = [];
  private selectedRoomWallIds: Set<string> = new Set();
  private isSelectedRoomWallsEligible: boolean = false;
  private selectedRoomSegments = [];

  constructor(private sceneManager: SceneManager) {}

  get SelectedRoomIds(): string[] {
    return Array.from(this.selectedRoomIds);
  }
  get SelectedLotItems(): string[] {
    return Array.from(this.selectedLotItemsIds);
  }
  get SelectedRoomOpenings(): soOpening[] {
    return this.selectedRoomOpenings;
  }
  get SelectedWallIds(): string[] {
    return Array.from(this.selectedRoomWallIds);
  }
  get SelectedWalls(): soWall2D[] {
    return this.selectedRoomWallIds.size > 0 ? this.sceneManager.getActiveSoFloor().getWallsByIds(this.SelectedWallIds) : [];
  }
  get SelectedWallsIdSet(): Set<string> {
    return this.selectedRoomWallIds;
  }
  clearSelectedRoomsIds(): void {
    this.selectedRoomIds.clear();
    appModel.selectionChanged = true;
  }
  ClearSelectedWallIds(): void {
    this.selectedRoomWallIds.clear();
    appModel.selectionChanged = true;
  }
  ClearSelectedLotItems(): void {
    this.selectedLotItemsIds.clear();
    appModel.selectionChanged = true;
  }
  ClearSelectedRoomOpenings(): void {
    this.selectedRoomOpenings = [];
    appModel.selectionChanged = true;
  }
  addSelectedRoomId(roomId: string): void {
    this.selectedRoomIds.add(roomId);
    appModel.selectionChanged = true;
  }
  addSelectedLotItemId(lotItemId: string): void {
    this.selectedLotItemsIds.add(lotItemId);
    appModel.selectionChanged = true;
  }
  addSelectedRoomOpening(opening: soOpening): void {
    this.selectedRoomOpenings.push(opening);
    appModel.selectionChanged = true;
  }
  addSelectedWallId(wallId: string): void {
    this.selectedRoomWallIds.add(wallId);
    appModel.selectionChanged = true;
  }
  set SelectedRoomWallsEligable(value: boolean) {
    this.isSelectedRoomWallsEligible = value;
    appModel.selectionChanged = true;
  }
  get SelectedRoomWallsEligable(): boolean {
    return this.isSelectedRoomWallsEligible;
  }
}
