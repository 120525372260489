import { Geometry } from "three";
import RoomManager from "../../managers/SceneManager/SceneManager";
import SceneUtils from "../../utils/SceneUtils";
import { ReferenceLine } from "../ReferenceLine";
import { CommandTypes } from "./CommandTypes";
import { RoomCommand } from "./RoomCommand";
import GeometryUtils from "../../utils/GeometryUtils/GeometryUtils";
import RoomUtils from "../../utils/RoomUtils";

export class StretchRoomCommand extends RoomCommand {
  constructor(
    entityId: string,
    private referenceLine: ReferenceLine,
    private delta: number
  ) {
    super(entityId);

    this.type = CommandTypes.StretchRoomCommand;
  }

  apply(roomManager: RoomManager): void {
    const soRoom = roomManager.getActiveFloorSoRoom(this.entityId);
    roomManager.getActiveSoFloor().wallManager.removeRooms([soRoom]);
    SceneUtils.stretchRoomByReferenceLine(soRoom, this.referenceLine, this.delta);
    soRoom.children.forEach(child => (child.userData.roomPosition = child.position));
    roomManager.getActiveSoFloor().wallManager.addRooms([soRoom]);
    roomManager.updateRoomsProperties([this.entityId]);
    roomManager.checkRoomsSharedObjects([this.entityId]);
  }

  undo(roomManager: RoomManager): void {
    const soRoom = roomManager.getActiveFloorSoRoom(this.entityId);
    roomManager.getActiveSoFloor().wallManager.removeRooms([soRoom]);
    SceneUtils.stretchRoomByReferenceLine(soRoom, this.referenceLine, -this.delta);
    soRoom.children.forEach(child => (child.userData.roomPosition = child.position));
    roomManager.getActiveSoFloor().wallManager.addRooms([soRoom]);
    roomManager.updateRoomsProperties([this.entityId]);
    roomManager.checkRoomsSharedObjects([this.entityId]);
  }
}
