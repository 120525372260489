export type KeyValue = { [key: string]: any };
export type KeyString = { [key: string]: string };
export enum SettingsUnits {
  ft = "ft",
  sqft = "sqft",
  units = "units",
  hex = "#hex",
  plf = "plf",
  percent = "%",
  psf = "psf",
}

export enum WebAppUISettingsKeys {
  displayUnits = "displayUnits",
  selectionColor = "selectionColor",
  highlightColor = "highlightColor",
  wallsColor = "wallsColor",
  floorsColor = "floorsColor",
  openingsColor = "openingsColor",
  gridPrimaryColor = "gridPrimaryColor",
  gridSecondaryColor = "gridSecondaryColor",
  lotLinesColor = "lotLinesColor",
  lotSetbackColor = "lotSetbackColor",
  obsoleteRoomsColor = "obsoleteRoomsColor",
  glassColor = "glassColor",
  doorColor = "doorColor",
  roofSurface = "roofSurface",
  roofEdge = "roofEdge",
  gravityExteriorLBColor = "gravityExteriorLBColor",
  gravityStackedLBColor = "gravityStackedLBColor",
  gravityNonLBColor = "gravityNonLBColor",
  gravityBeamColor = "gravityBeamColor",
  shearWalls = "shearWalls",
  shearObstructionOnCurrentLevel = "shearObstructionOnCurrentLevel",
  shearObstructionOnLevelBelow = "shearObstructionOnLevelBelow",
  livingSpaces = "livingSpaces",
  garage = "garage",
  porchesAndPatio = "porchesAndPatio",
  roof = "roof",
}

export type TWebAppUISettings = {
  [WebAppUISettingsKeys.displayUnits]: "feet" | "inch";
  [WebAppUISettingsKeys.selectionColor]: string;
  [WebAppUISettingsKeys.highlightColor]: string;
  [WebAppUISettingsKeys.wallsColor]: string;
  [WebAppUISettingsKeys.floorsColor]: string;
  [WebAppUISettingsKeys.openingsColor]: string;
  [WebAppUISettingsKeys.gridPrimaryColor]: string;
  [WebAppUISettingsKeys.gridSecondaryColor]: string;
  [WebAppUISettingsKeys.lotLinesColor]: string;
  [WebAppUISettingsKeys.lotSetbackColor]: string;
  [WebAppUISettingsKeys.obsoleteRoomsColor]: string;
  [WebAppUISettingsKeys.glassColor]: string;
  [WebAppUISettingsKeys.doorColor]: string;
  [WebAppUISettingsKeys.roofSurface]: string;
  [WebAppUISettingsKeys.roofEdge]: string;
  [WebAppUISettingsKeys.gravityExteriorLBColor]: string;
  [WebAppUISettingsKeys.gravityStackedLBColor]: string;
  [WebAppUISettingsKeys.gravityNonLBColor]: string;
  [WebAppUISettingsKeys.gravityBeamColor]: string;
  [WebAppUISettingsKeys.shearWalls]: string;
  [WebAppUISettingsKeys.shearObstructionOnCurrentLevel]: string;
  [WebAppUISettingsKeys.shearObstructionOnLevelBelow]: string;
  [WebAppUISettingsKeys.livingSpaces]: string;
  [WebAppUISettingsKeys.garage]: string;
  [WebAppUISettingsKeys.porchesAndPatio]: string;
  [WebAppUISettingsKeys.roof]: string;
};

export enum CorePlanRangesKeys {
  storiesMax = "storiesMax",
  minFloorToPlateLevelHeight = "minFloorToPlateLevelHeight",
  maxFloorToPlateLevelHeight = "maxFloorToPlateLevelHeight",
  bedroomMin = "bedroomMin",
  bedroomMax = "bedroomMax",
  bathroomMin = "bathroomMin",
  bathroomMax = "bathroomMax",
  carsMax = "carsMax",
}

export type TCorePlanRanges = {
  [CorePlanRangesKeys.storiesMax]: number;
  [CorePlanRangesKeys.minFloorToPlateLevelHeight]: number;
  [CorePlanRangesKeys.maxFloorToPlateLevelHeight]: number;
  [CorePlanRangesKeys.bedroomMin]: number;
  [CorePlanRangesKeys.bedroomMax]: number;
  [CorePlanRangesKeys.bathroomMin]: number;
  [CorePlanRangesKeys.bathroomMax]: number;
  [CorePlanRangesKeys.carsMax]: number;
};

export enum CorePlanDefaultsKeys {
  numOfStories = "numOfStories",
  numOfBedrooms = "numOfBedrooms",
  numOfBathrooms = "numOfBathrooms",
  numOfHalfBathrooms = "numOfHalfBathrooms",
  numOfCars = "numOfCars",
  minBuildingNetArea = "minBuildingNetArea",
  maxBuildingGrossArea = "maxBuildingGrossArea",
  lotArea = "lotArea",
  lotWidth = "lotWidth",
  lotLength = "lotLength",
  floorToPlateLevelHeight = "floorToPlateLevelHeight",
  floorThickness = "floorThickness",
  lotFrontSetback = "lotFrontSetback",
  lotBackSetback = "lotBackSetback",
  lotSideSetback = "lotSideSetback",
  lotFront2Setback = "lotFront2Setback",
}

export type TCorePlanDefaults = {
  [CorePlanDefaultsKeys.numOfStories]: number;
  [CorePlanDefaultsKeys.numOfBedrooms]: number;
  [CorePlanDefaultsKeys.numOfBathrooms]: number;
  [CorePlanDefaultsKeys.numOfHalfBathrooms]: number;
  [CorePlanDefaultsKeys.numOfCars]: number;
  [CorePlanDefaultsKeys.minBuildingNetArea]: number;
  [CorePlanDefaultsKeys.maxBuildingGrossArea]: number;
  [CorePlanDefaultsKeys.lotArea]: number;
  [CorePlanDefaultsKeys.lotWidth]: number;
  [CorePlanDefaultsKeys.lotLength]: number;
  [CorePlanDefaultsKeys.floorToPlateLevelHeight]: number;
  [CorePlanDefaultsKeys.floorThickness]: number;
  [CorePlanDefaultsKeys.lotFrontSetback]: number;
  [CorePlanDefaultsKeys.lotBackSetback]: number;
  [CorePlanDefaultsKeys.lotSideSetback]: number;
  [CorePlanDefaultsKeys.lotFront2Setback]: number;
};

export enum ValidationSettingsKeys {
  gravityMaxFloorSpan = "gravityMaxFloorSpan",
  gravityMaxBeamSpan = "gravityMaxBeamSpan",
  gravityMaxCantileverSpan = "gravityMaxCantileverSpan",
  shearF1cap = "shearF1cap",
  shearF2cap = "shearF2cap",
  shearSeismicFactorCd = "shearSeismicFactorCd",
  shearComboDesignFactor = "shearComboDesignFactor",
  shearPreliminaryFactor = "shearPreliminaryFactor",
  exteriorWallWeight = "exteriorWallWeight",
  interiorWallWeight = "interiorWallWeight",
  beamWeight = "beamWeight",
  floorWeight = "floorWeight",
  roofWeight = "roofWeight",
  openingAlignmentMinGap = "openingAlignmentMinGap",
  areaCalculationExteriorOffset = "areaCalculationExteriorOffset",
  gridCellSizeForNewCorePlans = "gridCellSizeForNewCorePlans",
  stretchAllowance = "stretchAllowance",
  frameSize = "frameSize",
  openingStructuralTolerance = "openingStructuralTolerance",
  edgeOfExteriorWallTolerance = "edgeOfExteriorWallTolerance",
  firstFloorPlumbingPointMaxOffset = "firstFloorPlumbingPointMaxOffset",
  pipeFallTolerance = "pipeFallTolerance",
  plmFixtureWallSplitTolerance = "plmFixtureWallSplitTolerance",
  minimalWallSegmentLength = "minimalWallSegmentLength",
  roofDefaultSlope = "roofDefaultSlope",
  roofDefaultOverhang = "roofDefaultOverhang",
  minimumGableSeparation = "minimumGableSeparation",
  windowEdgeOffset = "windowEdgeOffset",
  doorEdgeOffset = "doorEdgeOffset",
}

export type TValidationSettings = {
  [ValidationSettingsKeys.gravityMaxFloorSpan]: number;
  [ValidationSettingsKeys.gravityMaxBeamSpan]: number;
  [ValidationSettingsKeys.gravityMaxCantileverSpan]: number;
  [ValidationSettingsKeys.shearF1cap]: number;
  [ValidationSettingsKeys.shearF2cap]: number;
  [ValidationSettingsKeys.shearSeismicFactorCd]: number;
  [ValidationSettingsKeys.shearComboDesignFactor]: number;
  [ValidationSettingsKeys.shearPreliminaryFactor]: number;
  [ValidationSettingsKeys.exteriorWallWeight]: number;
  [ValidationSettingsKeys.interiorWallWeight]: number;
  [ValidationSettingsKeys.beamWeight]: number;
  [ValidationSettingsKeys.floorWeight]: number;
  [ValidationSettingsKeys.roofWeight]: number;
  [ValidationSettingsKeys.openingAlignmentMinGap]: number;
  [ValidationSettingsKeys.areaCalculationExteriorOffset]: number;
  [ValidationSettingsKeys.gridCellSizeForNewCorePlans]: number;
  [ValidationSettingsKeys.stretchAllowance]: number;
  [ValidationSettingsKeys.frameSize]: number;
  [ValidationSettingsKeys.openingStructuralTolerance]: number;
  [ValidationSettingsKeys.edgeOfExteriorWallTolerance]: number;
  [ValidationSettingsKeys.firstFloorPlumbingPointMaxOffset]: number;
  [ValidationSettingsKeys.pipeFallTolerance]: number;
  [ValidationSettingsKeys.plmFixtureWallSplitTolerance]: number;
  [ValidationSettingsKeys.minimalWallSegmentLength]: number;
  [ValidationSettingsKeys.roofDefaultSlope]: number;
  [ValidationSettingsKeys.roofDefaultOverhang]: number;
  [ValidationSettingsKeys.minimumGableSeparation]: number;
  [ValidationSettingsKeys.windowEdgeOffset]: number;
  [ValidationSettingsKeys.doorEdgeOffset]: number;
};

export enum AreaTakeOffSettingsKeys {
  livingSpaces = "livingSpaces",
  garage = "garage",
  porchesAndPatio = "porchesAndPatio",
  roof = "roof",
}

export type AreaTakeOffSettings = {
  [AreaTakeOffSettingsKeys.livingSpaces]: string;
  [AreaTakeOffSettingsKeys.garage]: string;
  [AreaTakeOffSettingsKeys.porchesAndPatio]: string;
  [AreaTakeOffSettingsKeys.roof]: string;
};

export type TSettingsValues = {
  corePlanRangesSettings: TCorePlanRanges;
  corePlanDefaults: TCorePlanDefaults;
  validationSettings: TValidationSettings;
  webAppUISettings: TWebAppUISettings;
};

export type TSettingsDescriptions = {
  corePlanRangesSettings: { [Key in keyof TCorePlanRanges]: string };
  corePlanDefaults: { [Key in keyof TCorePlanDefaults]: string };
  validationSettings: { [Key in keyof TValidationSettings]: string };
  webAppUISettings: { [Key in keyof TWebAppUISettings]: string };
};

export type TSettings = {
  values: TSettingsValues;
  descriptions: TSettingsDescriptions;
};
