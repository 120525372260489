import { WallType } from "../../../../entities/catalogSettings/types";
import { appModel } from "../../../../models/AppModel";
import { soRoom2D } from "../Room/soRoom2D";

export default class WallSideInfo {
  private roomId: string;
  private roomCategoryName: string = "";
  private wallSideTypeTags: WallType[] = [];
  constructor(soRoom: soRoom2D) {
    this.roomId = soRoom.soId;
    this.setRoomCategoryName(soRoom);
  }
  get RoomId(): string {
    return this.roomId;
  }
  get RoomCategoryName(): string {
    return this.roomCategoryName;
  }
  get WallSideTypeTags(): WallType[] {
    return this.wallSideTypeTags;
  }
  private setRoomCategoryName(soRoom: soRoom2D): void {
    const roomType = appModel.getRoomType(soRoom.userData.roomTypeId);
    if (roomType) {
      if (roomType.isShaft) {
        this.addWallClassification(WallType.SFT);
      }
    }

    const roomCategory = appModel.getRoomCategory(roomType.roomCategoryId);
    if (roomCategory) {
      if (roomCategory.isGarage) {
        this.addWallClassification(WallType.GRG);
        this.roomCategoryName = "garage";
      } else if (roomCategory.isBedroom) {
        this.roomCategoryName = "bedroom";
      } else if (roomCategory.isBathroom) {
        this.roomCategoryName = "bathroom";
        this.addWallClassification(WallType.SHW);
      } else if (roomCategory.isKitchen) {
        this.roomCategoryName = "kitchen";
      } else if (roomCategory.isOutdoor) {
        this.roomCategoryName = "outdoor";
      } else if (roomCategory.isStairs) {
        this.roomCategoryName = "stairs";
      } else if (roomCategory.isStorage) {
        this.roomCategoryName = "storage";
      } else {
        this.roomCategoryName = "internal";
      }
    }
  }
  private addWallClassification(wallType: WallType) {
    if (!this.wallSideTypeTags.includes(wallType)) this.wallSideTypeTags.push(wallType);
  }
}
