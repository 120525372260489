import RoomManager from "../managers/RoomManager/RoomManager";
import { appModel } from "../../models/AppModel";
import { RoomType } from "../../models/RoomType";
import { SceneMode } from "../../models/SceneMode";
import { addAuthParam } from "../../services/api/utilities";
import { IMAGE_WIDTH } from "../../ui/components/Editor/LeftBar/RoomCatalog/RoomTypeListItem";
import { IManager } from "../managers/IManager";
import SceneManager from "../managers/SceneManager/SceneManager";

export default class RoomDragAndDropTool {
  private imageDiv: HTMLImageElement;

  constructor(private roomManager: RoomManager | SceneManager) {
    // if (!(this.roomManager instanceof RoomManager) && !(this.roomManager instanceof SceneManager)) {
    //   throw new Error("Manager is not an instance of RoomManager");
    // }
    this.onRoomTypeDrag = this.onRoomTypeDrag.bind(this);
    this.onRoomTypeDragEnd = this.onRoomTypeDragEnd.bind(this);
  }

  public onRoomTypeDragStart(e: React.MouseEvent, roomType: RoomType): void {
    if (appModel.sceneMode == SceneMode.Editor) {
      if (!this.imageDiv) {
        this.imageDiv = document.createElement("img") as HTMLImageElement;
        this.imageDiv.classList.add("room-type-ghost-image");
        this.imageDiv.width = IMAGE_WIDTH;
        this.imageDiv.height = IMAGE_WIDTH;
        const root = document.getElementById("root");
        root.appendChild(this.imageDiv);
      }
      this.imageDiv.src = addAuthParam(roomType.thumbnailUrl);
      this.imageDiv.style.top = `${e.clientY - IMAGE_WIDTH / 2}px`;
      this.imageDiv.style.left = `${e.clientX - IMAGE_WIDTH / 2}px`;
      document.addEventListener("mousemove", this.onRoomTypeDrag);
      document.addEventListener("mouseup", this.onRoomTypeDragEnd);

      this.roomManager.onRoomTypeDragStart(e, roomType);
    }
  }

  public onRoomTypeDrag(e: MouseEvent): void {
    this.roomManager.baseManager.updateMouseProperties(e.clientX, e.clientY);
    if (this.roomManager.baseManager.isMouseWithinScene) {
      this.imageDiv.style.visibility = "hidden";
    } else {
      this.imageDiv.style.visibility = "visible";
      this.imageDiv.style.top = `${e.clientY - IMAGE_WIDTH / 2}px`;
      this.imageDiv.style.left = `${e.clientX - IMAGE_WIDTH / 2}px`;
    }
    this.roomManager.onRoomTypeDrag();
  }

  public onRoomTypeDragEnd(e: MouseEvent): void {
    this.roomManager.baseManager.updateMouseProperties(e.clientX, e.clientY);
    const root = document.getElementById("root");
    root.removeChild(this.imageDiv);
    this.imageDiv = null;
    this.roomManager.onRoomTypeDragEnd(e);
    document.removeEventListener("mousemove", this.onRoomTypeDrag);
    document.removeEventListener("mouseup", this.onRoomTypeDragEnd);
  }
}
