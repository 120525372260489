import { Vector3 } from "three";
import VectorUtils from "../../../utils/GeometryUtils/VectorUtils";
import { WallSides } from "./soWall2D";

export class WallOffset {
  direction: WallSides;
  distance: number;
  constructor(direction: WallSides, distance: number) {
    this.direction = direction;
    this.distance = Math.abs(distance);
  }

  get Reversed(): WallOffset {
    return new WallOffset(this.direction == WallSides.left ? WallSides.left : WallSides.right, this.distance);
  }
  getSignedDistance(): number {
    return this.direction == WallSides.left ? -this.distance : this.distance;
  }
}
