import { FuncCode } from "../../../entities/catalogSettings/types";
import { appModel } from "../../../models/AppModel";
import { soWall2D } from "../SceneObjects/Wall/soWall2D";
import { RoomCommand } from "./RoomCommand";

export class ChangeWallWidthCommand extends RoomCommand {
  private isLocked: boolean;
  private oldFunctionCodeMap: Map<string, string>;

  constructor(
    private soWall: soWall2D,
    private functionCode: string
  ) {
    super(soWall.userData.id);

    this.isLocked = soWall.userData.isLocked;
    this.oldFunctionCodeMap = new Map();
  }

  public apply(): void {
    this.lockWall();

    const updatedSegments = Array.from(appModel.selectedRoomSoWall).map(wall => {
      const oldFunctionCode = wall.FunctionCode;
      this.oldFunctionCodeMap.set(wall.wallId, oldFunctionCode);
      this.updateSegmentFunctionCode(wall, this.functionCode);
      this.soWall.ParentRooms.forEach(room => (room.getWallOveride(this.soWall.wallId).FunctionCode = this.functionCode));
      return wall;
    });

    appModel.setModifiedSegmentWalls(updatedSegments);

    this.updateRoomWall();
  }

  public undo(): void {
    this.unlockWall();

    const restoredSegments = Array.from(appModel.selectedRoomSoWall).map(segment => {
      const oldFunctionCode = this.oldFunctionCodeMap.get(segment.userData.id);
      if (oldFunctionCode) {
        this.updateSegmentFunctionCode(segment, oldFunctionCode);
        this.soWall.ParentRooms.forEach(room => (room.getWallOveride(this.soWall.wallId).FunctionCode = this.functionCode));
      }
      return segment.userData.segment;
    });

    appModel.setModifiedSegmentWalls(restoredSegments);

    this.restoreRoomWall();
  }

  private lockWall(): void {
    this.soWall.userData.isLocked = true;
  }

  private unlockWall(): void {
    this.soWall.userData.isLocked = this.isLocked;
  }

  private updateSegmentFunctionCode(wall: soWall2D, functionCode: string): void {
    if (wall) {
      wall.setFunctionCode(functionCode);
    }
  }

  private updateRoomWall(): void {
    const currentWall = appModel.selectedRoomSoWall.values().next().value;
    appModel.updateSelectedRoomWall(currentWall, this.soWall);
    appModel.baseManager.roomManager.setRoomWallSelected();
  }

  private restoreRoomWall(): void {
    const currentWall = appModel.selectedRoomSoWall.values().next().value;
    appModel.updateSelectedRoomWall(this.soWall, currentWall);
    appModel.baseManager.roomManager.setRoomWallSelected();
  }
}
