import { Vector2 } from "three";
import { Side } from "../../../models/Side";
import MathUtils from "../../utils/MathUtils";

export class Vertex {
  id: string;
  point: Vector2;
  edges: {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
  };
  private vertexCount: number = 0;

  constructor(point: Vector2) {
    this.point = point;
    this.id = Vertex.generateVertexId(point);
    this.edges = {};
  }
  static generateVertexId(point: Vector2): string {
    return `${MathUtils.round(point.x)},${MathUtils.round(point.y)}`;
  }
  getVertexCount(): number {
    return this.vertexCount;
  }
  incrementVertexCount(): void {
    this.vertexCount++;
  }
  decrementVertexCount(): void {
    this.vertexCount--;
  }
  getSegmantIdBySide(Side: Side): string {
    return this.edges[Side];
  }
  getAllEdgesIds(): string[] {
    return Object.values(this.edges).filter(edge => edge !== undefined);
  }
  addWallIdBySide(side: string, WallId: string): void {
    this.edges[side] = WallId;
  }
  static sortVertexIds(Ids: string[]): string[] {
    return Ids.map(id => {
      const [x, y] = id.split(",").map(Number);
      return { x, y, original: id };
    })
      .sort((a, b) => a.x - b.x || a.y - b.y)
      .map(id => id.original);
  }
  static VertexIdToVector2(id: string): Vector2 {
    const [x, y] = id.split(",").map(Number);
    return new Vector2(x, y);
  }
}
