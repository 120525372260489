import { showToastMessage } from "../../helpers/messages";

export class Configuration {
  public configurationData: any = {};

  public async loadConfiguration() {
    try {
      const configurationJson = await fetch("/settings/configuration.json").then(data => data.json());
      this.configurationData = configurationJson.configuration;
    } catch (error) {
      showToastMessage("Error", "Error occurred on configuration loading.");
    }
  }
}

const configuration = new Configuration();

export default configuration;
