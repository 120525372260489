import { ChangeEvent } from "react";
import { Col, Form, Input, Row } from "antd";
import { feetInchesFractionToInches, inchesToFeetInchesFraction } from "../../../../helpers/measures";
import { settings } from "../../../../entities/settings";

import "./FloorToPlateDefinition.sass";

const FIRST_FLOOR_HEIGHT = "firstFloorToPlateHeight";
const UPPER_FLOOR_HEIGHT = "upperFloorToPlateHeight";

const Item = ({ onBlur, validator, name, story }) => (
  <Form.Item name={name} rules={[{ required: true, message: `Please enter the ${story} floor upper height` }, { validator: validator }]}>
    <Input
      onBlur={e => onBlur(e, name)}
      addonBefore={`${story.charAt(0).toUpperCase() + story.slice(1)} Floor`}
      addonAfter="ft"
      type="string"
      className="floor2plate-input"
    />
  </Form.Item>
);

const FloorToPlateDefinition = ({ formData, handleFormData }) => {
  const validateFloorHeight = async (_: any, value: string) => {
    if (!value) return Promise.resolve();
    const { minFloorToPlateLevelHeight, maxFloorToPlateLevelHeight } = settings.values.corePlanRangesSettings;

    const numericValue = feetInchesFractionToInches(value);

    if (numericValue < minFloorToPlateLevelHeight || numericValue > maxFloorToPlateLevelHeight) {
      return Promise.reject(
        new Error(
          `Value must be between ${inchesToFeetInchesFraction(minFloorToPlateLevelHeight)} and ${inchesToFeetInchesFraction(maxFloorToPlateLevelHeight)}`
        )
      );
    }
    return Promise.resolve();
  };

  const onBlur = (event: ChangeEvent<HTMLInputElement>, key: string) => {
    if (!event.target.value) return;
    handleFormData(key)({
      target: {
        value: feetInchesFractionToInches(event.target.value),
      },
    });
  };

  return (
    <div>
      <div className="ant-form-item-label mid-label">
        <label>Floor to Plate</label>
      </div>
      <Row className="area-group">
        <Col>
          <Item onBlur={onBlur} validator={validateFloorHeight} name={FIRST_FLOOR_HEIGHT} story="first" />
        </Col>
      </Row>
      {formData.floors > 1 && (
        <Row>
          <Col>
            <Item onBlur={onBlur} validator={validateFloorHeight} name={UPPER_FLOOR_HEIGHT} story="upper" />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default FloorToPlateDefinition;
