import { catalogSettings } from "../../../entities/catalogSettings";
import { appModel } from "../../../models/AppModel";
import { ChangeWallAlignmentCommand } from "../../models/commands/ChangeWallAlignmentCommand";
import { soWall2D } from "../../models/SceneObjects/Wall/soWall2D";
import { Segment } from "../../models/segments/Segment";

export class WallAlignmentManager {
  alignSelectedWallsByPosition(position: string) {
    const commands: ChangeWallAlignmentCommand[] = [];
    // centerlize the selected walls - remove offsets if exist
    if (position === "center") {
      Array.from(appModel.selectedRoomSoWall).forEach(wall => {
        commands.push(new ChangeWallAlignmentCommand(wall, 0));
      });
    } else {
      // otherwise, align the selected walls to the position
      const maxThickness = this.findThickestSegment();
      Array.from(appModel.selectedRoomSoWall).forEach(wall => {
        const wallThickness = WallAlignmentManager.getSegmentCoreThickness(wall);
        if (wallThickness === maxThickness) {
          return;
        }
        let offset = maxThickness - wallThickness;
        offset = position === "left" || position === "top" ? -offset : offset;
        wall.userData.offset = offset;
        commands.push(new ChangeWallAlignmentCommand(wall, offset));
        appModel.addModifiedSegmentWall(wall);
      });
    }

    appModel.baseManager.roomManager.runChangeWallWidthAlignmentCommand(commands);
    appModel.baseManager.roomManager.setRoomWallSelected(100);
  }

  findThickestSegment = () => {
    let maxThickness = 0;

    appModel.selectedRoomSoWall.forEach(wall => {
      const coreThickness = WallAlignmentManager.getSegmentCoreThickness(wall);
      if (coreThickness > maxThickness) {
        maxThickness = coreThickness;
      }
    });
    return maxThickness;
  };

  static getSegmentCoreThickness(item: soWall2D): number {
    //check if modified, otherwise get core thickness from passed segment
    return item.coreSideThickness;
  }

  static getOffset = (segment: soWall2D): number => {
    return appModel.modifiedWallManager.findSegmentOffset(segment);
  };
}

const wallAlignmentManager = new WallAlignmentManager();
export default wallAlignmentManager;
