import { Col, Row } from "antd";
import AttributeSelector from "../../common/AttributeSelector/AttributeSelector";

const AttributeGroup = ({ corePlanData, formData, handleFormData, bedroomNumberRange, BathroomNumberRange, carsNumberRange, setCorePlanData }) => {
  const handleRadioAttributeChange = e => {
    const value = e.target.name == "garage" ? e.target.value : parseFloat(e.target.value);
    setCorePlanData(e.target.name, value, true);
    switch (e.target.name) {
      case "floors":
        const newCoverages = [...formData.floorCoverages];
        if (value < formData.floors) {
          const itemsToDelete = formData.floors - value;
          newCoverages.splice(newCoverages.length - itemsToDelete, itemsToDelete);
        } else {
          const lastPercent = formData.floorCoverages[formData.floorCoverages.length - 1];
          const itemsToPush = value - formData.floors;
          newCoverages.push(...Array(itemsToPush).fill(lastPercent));
        }
        setCorePlanData("floorCoverages", newCoverages, true);
        handleFormData({ ...formData, floors: value, floorCoverages: newCoverages });

        //corePlanData.attributes.floorCoverages = newCoverages;
        break;
      case "bedRooms":
      case "garage":
      case "bathRooms":
      case "halfBathRooms":
      default:
        break;
    }
  };

  return (
    <div>
      <Row className="attributes-group group-2">
        <Col>
          <AttributeSelector
            label="Stories"
            name="floors"
            iconClass="floors"
            formData={formData.floors}
            handleRadioAttributeChange={handleRadioAttributeChange}
            options={[1, 2, 3, 4]}
          />
        </Col>
      </Row>
      <Row className="attributes-group group-2">
        <Col style={{ flex: "0 3 auto" }}>
          <AttributeSelector
            label="Bedrooms"
            name="bedRooms"
            iconClass="bedrooms"
            formData={formData.bedRooms}
            handleRadioAttributeChange={handleRadioAttributeChange}
            options={bedroomNumberRange}
          />
        </Col>
        <Col style={{ flex: "1 3 auto" }}>
          <AttributeSelector
            label="Bathrooms"
            name="bathRooms"
            iconClass="bathrooms"
            formData={formData.bathRooms}
            handleRadioAttributeChange={handleRadioAttributeChange}
            options={BathroomNumberRange}
          />
        </Col>
        <Col style={{ flex: "2 3 auto" }}>
          <AttributeSelector
            label="Half Bathrooms"
            name="halfBathRooms"
            iconClass="bathrooms"
            formData={formData.halfBathRooms}
            handleRadioAttributeChange={handleRadioAttributeChange}
            options={[0, 1]}
          />
        </Col>
        <Col style={{ flex: "3 3 auto" }}>
          <AttributeSelector
            label="Car Spaces"
            name="garage"
            iconClass="garage"
            formData={formData.garage}
            handleRadioAttributeChange={handleRadioAttributeChange}
            options={carsNumberRange}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AttributeGroup;
