import { appModel } from "../../../models/AppModel";
import { Room } from "../../../models/Room";
import RoomManager from "../../managers/RoomManager/RoomManager";
import { soRoom2D } from "../SceneObjects/Room/soRoom2D";
import { CommandTypes } from "./CommandTypes";
import { RoomCommand } from "./RoomCommand";

/**
 *@deprecated
 */
export class AddRoomCommand extends RoomCommand {
  constructor(
    private soRoom: THREE.Object3D,
    private room?: Room
  ) {
    super(soRoom.userData.id);

    this.type = CommandTypes.AddRoomCommand;
  }

  apply(roomManager: RoomManager): void {
    roomManager.getActiveSoFloor()?.add(this.soRoom);
    appModel.activeFloor.addRoom(this.room);

    roomManager.updateRoomsProperties([this.entityId]);
    roomManager.checkRoomsSharedObjects([this.soRoom.userData.id]);
  }

  undo(roomManager: RoomManager): void {
    this.room = appModel.activeFloor.rooms.find(r => r.id === this.soRoom.userData.id);

    roomManager.selectRoom(this.soRoom, false);
    roomManager.getActiveSoFloor()?.remove(this.soRoom);

    appModel.activeFloor.removeRoom(this.soRoom.userData.id);
  }
}
