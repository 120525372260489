export enum RoomEntityType {
  Floor = "Floor",
  Wall = "Wall",
  PlumbingWall = "PlumbingWall",
  PlumbingPoint = "PlumbingPoint",
  Door = "Door",
  Window = "Window",
  Furniture = "Furniture",
  ModelLine = "ModelLine",
  RoomBoundaryLines = "RoomBoundaryLines",
  ReferenceLine = "ReferenceLine",
  OpeningZone = "OpeningZone",
}
