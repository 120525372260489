import { ReloadOutlined } from "@ant-design/icons";
import { List, Row } from "antd";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { Scrollbar } from "react-scrollbars-custom";
import { areaToEnUsLocaleString, inchesToFeetInchesFraction, numberToEnUsLocaleString } from "../../../../../helpers/measures";
import { Size, useWindowSize } from "../../../../../helpers/utilities";
import { appModel } from "../../../../../models/AppModel";
import LoadingSpinner from "../../../common/LoadingSpinner";
import "./ProductParams.sass";

const ProductParams = observer(() => {
  const size: Size = useWindowSize();
  const height = size.height ? size.height - 217 : "auto";

  useEffect(() => {
    if (!appModel.isViewOnlyMode && appModel.activeCorePlan?.isCostOutdated && !appModel.activeCorePlan?.isEstimatingCorePlanCost) {
      appModel.updateCorePlanCost();
    }
  }, []);

  const updateCorePlanCost = async () => {
    if (appModel.activeCorePlan?.isEstimatingCorePlanCost || appModel.isViewOnlyMode) {
      return;
    }

    // In case user leaves corePlan.
    const corePlan = appModel.activeCorePlan;
    corePlan.setIsEstimatingCost(true);
    await appModel.updateCorePlanCost();
    corePlan.setIsEstimatingCost(false);
  };

  const cost = typeof appModel.activeCorePlan?.cost === "number" ? numberToEnUsLocaleString(appModel.activeCorePlan.cost) : "-";
  const costPerSqft = typeof appModel.activeCorePlan?.costPerSqft === "number" ? numberToEnUsLocaleString(appModel.activeCorePlan.costPerSqft) : "-";
  const co2Emission = typeof appModel.activeCorePlan?.co2Emission === "number" ? numberToEnUsLocaleString(appModel.activeCorePlan.co2Emission, 100) : "-";

  return (
    <div className="product-params">
      <Scrollbar style={{ height: height }}>
        <Row className="title-row">
          <span className="text">Demand Data</span>
        </Row>
        <List className="list-demands">
          <List.Item>
            <List.Item.Meta description="Lot size:"></List.Item.Meta>
            <div>
              <b>{areaToEnUsLocaleString(appModel.activeCorePlan?.attributes.lotSize)}</b> sqft
            </div>
          </List.Item>
          <List.Item>
            <List.Item.Meta description="Min “Net” area:"></List.Item.Meta>
            <div>
              <b>{areaToEnUsLocaleString(appModel.activeCorePlan?.attributes.minNetArea)}</b> sqft
            </div>
          </List.Item>
          <List.Item>
            <List.Item.Meta description="Max “Gross” area:"></List.Item.Meta>
            <div>
              <b>{areaToEnUsLocaleString(appModel.activeCorePlan?.attributes.maxGrossArea)}</b> sqft
            </div>
          </List.Item>
          <List.Item>
            <List.Item.Meta description="Stories:"></List.Item.Meta>
            <div>
              <b>{appModel.activeCorePlan?.attributes.floors || 0}</b>
            </div>
          </List.Item>
          <List.Item>
            <List.Item.Meta description="Garage:"></List.Item.Meta>
            <div>
              <b>{appModel.activeCorePlan?.attributes.garage || 0}</b> cars
            </div>
          </List.Item>
          <List.Item>
            <List.Item.Meta description="Bedrooms:"></List.Item.Meta>
            <div>
              <b>{appModel.activeCorePlan?.attributes.bedRooms || 0}</b>
            </div>
          </List.Item>
          <List.Item>
            <List.Item.Meta description="Bathrooms:"></List.Item.Meta>
            <div>
              <b>{appModel.activeCorePlan?.attributes.bathRooms || 0}</b>
            </div>
          </List.Item>
          <List.Item>
            <List.Item.Meta description="First Floor to Floor:"></List.Item.Meta>
            <div>
              <b>{inchesToFeetInchesFraction(appModel.activeCorePlan?.firstFloorToFloorHeight)}</b>
            </div>
          </List.Item>
          {appModel.activeCorePlan?.attributes.floors > 1 && (
            <List.Item>
              <List.Item.Meta description="Upper Floor to Floor:" />
              <div>
                <b>{inchesToFeetInchesFraction(appModel.activeCorePlan?.upperFloorToFloorHeight)}</b>
              </div>
            </List.Item>
          )}
        </List>

        <Row className="title-row">
          <span className="text">Estimations</span>
          <ReloadOutlined onClick={updateCorePlanCost} disabled={appModel.activeCorePlan?.isEstimatingCorePlanCost || appModel.isViewOnlyMode} />
        </Row>
        {appModel.activeCorePlan?.isCostOutdated && !appModel.activeCorePlan?.isEstimatingCorePlanCost && (
          <div className="outdated-warning">*Cost estimations are outdated</div>
        )}
        <List className="list-info">
          {appModel.activeCorePlan?.isEstimatingCorePlanCost && (
            <div className="spinner-container">
              <LoadingSpinner />
            </div>
          )}
          <List.Item>
            <div className="item-title">
              Overall Cost
              <br />
            </div>
            <div className="item-value">
              {cost} <span className="unit">USD</span>
            </div>
          </List.Item>
          <List.Item>
            <div className="item-title">
              Price per SqFt
              <br />
            </div>
            <div className="item-value">
              {costPerSqft} <span className="unit">USD</span>
            </div>
          </List.Item>
          <List.Item>
            <div className="item-title">
              Co2 Emission
              <br />
            </div>
            <div className="item-value">{co2Emission} kg per sqft</div>
          </List.Item>
        </List>
      </Scrollbar>
    </div>
  );
});

export default ProductParams;
