import { appModel } from "../../../../models/AppModel";
import { soGroup } from "../soGroup";
import { soFloor2D } from "./soFloor2D";

/**
 * Class representing a 2D root floor in the scene.
//  * Inherits from soGroup to allow handling multiple child components.
 */
export class soFloor2DRoot extends soGroup {
  soFloors: soFloor2D[] = [];

  /**
   * Constructs an instance of soFloor2DRoot.
   * @param soFloors - Array of floors contained in the floor root.
   */
  constructor(floorId) {
    super();
    this.soId = floorId;
  }

  getVisibleSoFloors(): soFloor2D[] {
    const floorIds: string[] = [];
    this.soFloors.forEach(floor => {
      if (floor.floorIndex === appModel.activeFloor.index) {
        floorIds.push(floor.soId);
      }
      if (appModel.showAboveFloor && floor.floorIndex === appModel.activeFloor.index + 1) {
        floorIds.push(floor.soId);
      }
      if (appModel.showBelowFloor && floor.floorIndex === appModel.activeFloor.index - 1) {
        floorIds.push(floor.soId);
      }
    });

    return this.soFloors.filter(soFloor => floorIds.includes(soFloor.soId)) as soFloor2D[];
  }
  addFloors(soFloors: soFloor2D[]): void {
    soFloors.forEach(soFloor => {
      if (soFloor) {
        this.addFloor(soFloor);
      }
    });
  }
  addFloor(soFloor: soFloor2D): void {
    this.soFloors.push(soFloor);
    this.add(soFloor);
  }
  removeFloor(soFloor: soFloor2D): void {
    const index = this.soFloors.indexOf(soFloor);
    if (index > -1) {
      this.soFloors.splice(index, 1);
      this.remove(soFloor);
    }
  }
  removeFloors(soFloors: soFloor2D[]): void {
    soFloors.forEach(soFloor => {
      this.removeFloor(soFloor);
    });
  }
  getFloors(): soFloor2D[] {
    return this.soFloors;
  }
  getFloorById(floorId: string): soFloor2D {
    return this.soFloors.find(soFloor => soFloor.soId === floorId);
  }
}
