import { v4 as uuid } from "uuid";
import { appModel } from "../../../models/AppModel";
import { LotLineEdge, LotLineVertex } from "../../../models/LotLine";
import { LotLineManager } from "../../managers/SceneManager/LotLineManager";
import SceneUtils from "../../utils/SceneUtils";
import { SoLotLineEdge, SoLotLineVertex } from "../scene/SoLotLine";
import { CommandTypes } from "./CommandTypes";
import { LotItemCommand } from "./LotItemCommand";

interface FloorLotItems {
  vertex?: LotLineVertex;
  edge1?: LotLineEdge;
  edge2?: LotLineEdge;
  newEdge?: LotLineEdge;

  vertexIndex?: number;
  edge1Index?: number;
  edge2Index?: number;
}

export class DeleteLotVertexCommand extends LotItemCommand {
  private soVertex: SoLotLineVertex;
  private soEdge1: SoLotLineEdge;
  private soEdge2: SoLotLineEdge;
  private newSoEdge: SoLotLineEdge;
  private floorItems: Map<string, FloorLotItems> = new Map();

  constructor(entityId: string) {
    super(entityId);

    this.type = CommandTypes.TranslateBackgroundCommand;
  }

  apply(manager: LotLineManager): void {
    if (!this.soVertex) {
      this.init(manager);
    }

    appModel.activeCorePlan.floors.forEach(floor => {
      const items = this.floorItems.get(floor.id);

      floor.lotLine.removeVertex(items.vertex);
      floor.lotLine.removeEdge(items.edge2);
      floor.lotLine.removeEdge(items.edge1);
      floor.lotLine.addEdge(items.newEdge, items.edge1Index);
    });

    this.soEdge1.start.unlinkEdge(this.soEdge1);
    this.soEdge2.end.unlinkEdge(this.soEdge2);
    manager.getSoRoot().remove(this.soVertex, this.soEdge1, this.soEdge2);

    SceneUtils.setObjectColor(this.soVertex);

    this.newSoEdge.start.linkEdge(this.newSoEdge);
    this.newSoEdge.end.linkEdge(this.newSoEdge);
    manager.getSoRoot().add(this.newSoEdge);

    manager.updateLotItemsProperties([this.newSoEdge.userData.id], true);
  }

  undo(manager: LotLineManager): void {
    appModel.activeCorePlan.floors.forEach(floor => {
      const items = this.floorItems.get(floor.id);

      floor.lotLine.addVertex(items.vertex, items.vertexIndex);
      floor.lotLine.removeEdge(items.newEdge);
      floor.lotLine.addEdge(items.edge1, items.edge1Index);
      floor.lotLine.addEdge(items.edge2, items.edge2Index);
    });

    this.newSoEdge.start.unlinkEdge(this.newSoEdge);
    this.newSoEdge.end.unlinkEdge(this.newSoEdge);
    manager.getSoRoot().remove(this.newSoEdge);

    this.soEdge1.start.linkEdge(this.soEdge1);
    this.soEdge2.end.linkEdge(this.soEdge2);
    manager.getSoRoot().add(this.soVertex, this.soEdge1, this.soEdge2);
  }

  private init(manager: LotLineManager): void {
    const [edge1, edge2] = appModel.activeFloor.lotLine.getLinkedEdges(this.entityId);
    this.soVertex = manager.getSoLotItem(this.entityId) as SoLotLineVertex;
    this.soEdge1 = manager.getSoLotItem(edge1.id) as SoLotLineEdge;
    this.soEdge2 = manager.getSoLotItem(edge2.id) as SoLotLineEdge;
    this.newSoEdge = new SoLotLineEdge(this.soEdge1.start, this.soEdge2.end, uuid());

    appModel.activeCorePlan.floors.forEach(floor => {
      const items: FloorLotItems = {};

      items.vertex = floor.lotLine.getLotItem(this.entityId) as LotLineVertex;
      [items.edge1, items.edge2] = floor.lotLine.getLinkedEdges(items.vertex.id);
      items.newEdge = new LotLineEdge(items.edge1.startId, items.edge2.endId, Math.min(items.edge1.offset, items.edge2.offset), this.newSoEdge.userData.id);
      items.vertexIndex = floor.lotLine.vertices.indexOf(items.vertex);
      items.edge1Index = floor.lotLine.edges.indexOf(edge1);
      items.edge2Index = floor.lotLine.edges.indexOf(edge2);

      this.floorItems.set(floor.id, items);
    });
  }
}
