import * as THREE from "three";
import { catalogSettings } from "../../entities/catalogSettings";
import { settings } from "../../entities/settings";
import { WebAppUISettingsKeys } from "../../entities/settings/types";
import { EPSILON, WALL_RENDER_ORDER } from "../consts";
import { SceneEntityType } from "../models/SceneEntityType";
import { soWall2D, WallEndType, WallSides } from "../models/SceneObjects/Wall/soWall2D";
import GeometryUtils from "./GeometryUtils/GeometryUtils";
import { Vertex } from "../models/graph/Vertex";
import WallManager from "../models/graph/WallManager";
import MathUtils from "./MathUtils";
import { soRoom2D } from "../models/SceneObjects/Room/soRoom2D";
import { Direction } from "../models/Direction";
import { Side } from "../../models/Side";
import wallFunctionRules from "../../entities/wallFunctionCode/WallFunctionRules";
import { FuncCode, WallType } from "../../entities/catalogSettings/types";
import roomTiers from "../../entities/wallFunctionCode/RoomTiers";
import { appModel } from "../../models/AppModel";

export default class WallUtils {
  /**
   * Creates a synthetic wall geometry mesh based on the bounding box.
   * @param {THREE.Box3} bBox - The bounding box of the wall.
   * @param {soWall2D} [wall] - The synthetic wall object.
   * @returns {THREE.Mesh} The wall geometry mesh.
   */
  public static createSoWallGeometry(bBox: THREE.Box3, wall?: soWall2D): THREE.Mesh {
    const result = GeometryUtils.createFilledPlane(bBox, {
      color: settings.getColorNumber(WebAppUISettingsKeys.wallsColor),
      transparent: true,
      opacity: 1,
    });
    result.userData.type = SceneEntityType.SyntheticWall;
    result.userData.segment = wall?.wallId || "unknown";
    result.renderOrder = WALL_RENDER_ORDER;

    return result;
  }

  /**
   * Gets the wall width based on the function code.
   * @param {string} funcCode - The function code of the wall.
   * @param {boolean} [onlyCoreValue=false] - Whether to return only the core width.
   * @returns {number} The wall width.
   */
  static getWallWidthByFuncCode(funcCode: string, onlyCoreValue: boolean = false): number {
    try {
      const wall = catalogSettings.walls[funcCode];
      return onlyCoreValue ? wall.coreWidth * 2 : wall.exteriorThickness + wall.interiorThickness;
    } catch (error) {
      console.error("Error calculating wall width:", error);
      return 0;
    }
  }

  /**
   * Gets the exterior thickness of a wall by function code.
   * @param {string} funcCode - The function code of the wall.
   * @returns {number} The exterior thickness.
   */
  static getExteriorThicknessByFuncCode(funcCode: string): number {
    try {
      return catalogSettings.walls[funcCode].exteriorThickness;
    } catch (error) {
      console.error("Error retrieving exterior thickness:", error);
      return 0;
    }
  }

  /**
   * Gets the interior thickness of a wall by function code.
   * @param {string} funcCode - The function code of the wall.
   * @returns {number} The interior thickness.
   */
  static getInteriorThicknessByFuncCode(funcCode: string): number {
    try {
      return catalogSettings.walls[funcCode].interiorThickness;
    } catch (error) {
      console.error("Error retrieving interior thickness:", error);
      return 0;
    }
  }

  /**
   * Reverts the start and end points of a wall.
   * @param {soWall2D} wall - The wall to revert.
   * @param {boolean} [clone=true] - Whether to clone the wall before reverting.
   * @returns {soWall2D} The reverted wall.
   */
  public static revertWall(wall: soWall2D, clone: boolean = true): soWall2D {
    const tempWall = clone ? wall.DeepCopy() : wall;
    const tmp = tempWall.start;
    tempWall.setStart(tempWall.end);
    tempWall.setEnd(tmp);
    return tempWall;
  }

  /**
   * Creates a wall from a Line3 object.
   * @param {THREE.Line3} line - The line representing the wall.
   * @returns {soWall2D} The created wall.
   */
  public static createWallFromLine3(line: THREE.Line3): soWall2D {
    const wall = new soWall2D(new THREE.Vector2(line.start.x, line.start.y), new THREE.Vector2(line.end.x, line.end.y));
    if (!wall.isAxisAligned()) {
      WallUtils.revertWall(wall, false);
    }
    return wall;
  }

  /**
   * Checks if a vertex ID is on a wall by wall ID.
   * @param {string} vertexId - The vertex ID.
   * @param {string} wallId - The wall ID.
   * @returns {boolean} True if the vertex is on the wall, false otherwise.
   */
  public static isVertexIdOnWallById(vertexId: string, wallId: string): boolean {
    try {
      const points = WallUtils.wallIdToVector2(wallId);
      const start = points[0];
      const end = points[1];
      const point = Vertex.VertexIdToVector2(vertexId);
      let result = false;
      if (Math.abs(point.x - start.x) < EPSILON) {
        const minY = Math.min(start.y, end.y);
        const maxY = Math.max(start.y, end.y);
        result = point.y >= minY && point.y <= maxY;
      } else if (Math.abs(point.y - start.y) < EPSILON) {
        const minX = Math.min(start.x, end.x);
        const maxX = Math.max(start.x, end.x);
        result = point.x >= minX && point.x <= maxX;
      }
      return result;
    } catch (error) {
      console.error("Error checking vertex on wall:", error);
      return false;
    }
  }

  /**
   * Calculates the wall edge extension.
   * @param {WallManager} wallManager - The wall manager.
   * @param {soWall2D} wall - The wall.
   * @param {WallEndType} endType - The end type of the wall.
   * @returns {number} The wall edge extension.
   */
  public static getWallEdgeExtension(wallManager: WallManager, wall: soWall2D, endType: WallEndType): number {
    try {
      const walls = wall.getPerpendicularWallIds(endType).map(wallId => wallManager.getWallById(wallId));
      let size = Infinity;
      if (wall.wallDirection === "H") {
        if (endType === WallEndType.start) {
          walls.forEach(pWall => (size = Math.min(size, pWall.wallLeftSideThickness + pWall.getOffsetValue())));
        } else {
          walls.forEach(pWall => (size = Math.min(size, pWall.wallRightSideThickness + pWall.getOffsetValue())));
        }
      } else {
        if (endType === WallEndType.start) {
          walls.forEach(pWall => (size = Math.min(size, pWall.wallRightSideThickness + pWall.getOffsetValue())));
        } else {
          walls.forEach(pWall => (size = Math.min(size, pWall.wallLeftSideThickness + pWall.getOffsetValue())));
        }
      }
      return size < Infinity ? size : 0;
    } catch (error) {
      console.error("Error calculating wall edge extension:", error);
      return 0;
    }
  }

  /**
   * Determines the side of a wall relative to a room.
   * @param {soRoom2D} room - The room.
   * @param {soWall2D} wall - The wall.
   * @returns {WallSides|undefined} The wall side or undefined if not in the room.
   */
  static getParentRoomWallSide(room: soRoom2D, wall: soWall2D): WallSides {
    try {
      if (!wall.parentRoomIds.includes(room.soId)) {
        console.log("Wall is not in the room");
        return undefined;
      }
      return wall.wallDirection === Direction.Horizontal
        ? room.position.y < wall.start.y
          ? WallSides.right
          : WallSides.left
        : room.position.x < wall.start.x
          ? WallSides.left
          : WallSides.right;
    } catch (error) {
      console.error("Error determining wall side relative to room:", error);
      return undefined;
    }
  }

  /**
   * Gets the IDs of walls that are collinear and connected to the given wall.
   * @param {soWall2D} wall - The wall to check.
   * @param {WallEndType} [from=WallEndType.both] - The end type to check for connections.
   * @returns {string[]} The IDs of connected collinear walls.
   */
  static getConnectedColinearWallIds(wall: soWall2D, from: WallEndType = WallEndType.both): string[] {
    try {
      const wallIds: string[] = [];
      if (from === WallEndType.both || from === WallEndType.start) {
        const side1 =
          wall.wallDirection === Direction.Horizontal
            ? wall.WallStartVertex.getSegmantIdBySide(Side.left)
            : wall.WallStartVertex.getSegmantIdBySide(Side.bottom);
        if (side1) wallIds.push(side1);
      }
      if (from === WallEndType.both || from === WallEndType.end) {
        const side2 =
          wall.wallDirection === Direction.Horizontal ? wall.WallEndVertex.getSegmantIdBySide(Side.right) : wall.WallEndVertex.getSegmantIdBySide(Side.top);

        if (side2) wallIds.push(side2);
      }
      return wallIds;
    } catch (error) {
      console.error("Error retrieving connected collinear wall IDs:", error);
      return [];
    }
  }

  /**
   * Determines if two walls are collinear.
   * @param {soWall2D} wall1 - The first wall.
   * @param {soWall2D} wall2 - The second wall.
   * @returns {boolean} True if the walls are collinear, false otherwise.
   */
  static areWallsCollinear(wall1: soWall2D, wall2: soWall2D): boolean {
    if (!wall1 || !wall2) {
      throw new Error("Both walls must be defined.");
    }

    if (wall1.isVertical && wall2.isVertical) {
      return MathUtils.areNumbersEqual(wall1.start.x, wall2.start.x);
    } else if (wall1.isHorizontal && wall2.isHorizontal) {
      return MathUtils.areNumbersEqual(wall1.start.y, wall2.start.y);
    }
    return false;
  }

  /**
   * Generates a unique ID for a wall based on its start and end vertices.
   * @param {string} startId - The start vertex ID.
   * @param {string} endId - The end vertex ID.
   * @returns {string} The generated wall ID.
   */
  public static generateWallId(startId: string, endId: string): string {
    if (!startId || !endId) {
      throw new Error("Start and end vertex IDs must be defined.");
    }
    return `${startId}$${endId}`;
  }

  /**
   * Converts a wall ID into an array of 2D points.
   * @param {string} wallId - The wall ID string.
   * @returns {THREE.Vector2[]} An array of Vector2 points.
   */
  public static wallIdToVector2(wallId: string): THREE.Vector2[] {
    try {
      return wallId.split("$").map(Vertex.VertexIdToVector2);
    } catch (error) {
      console.error("Error converting wall ID to Vector2:", error);
      return [];
    }
  }

  /**
   * Converts a wall ID into a Line3 object.
   * @param {string} wallId - The wall ID string.
   * @returns {THREE.Line3} The Line3 object.
   */
  public static wallIdToLine3(wallId: string): THREE.Line3 {
    const points = WallUtils.wallIdToVector2(wallId);
    return new THREE.Line3(new THREE.Vector3(points[0].x, points[0].y, 0), new THREE.Vector3(points[1].x, points[1].y, 0));
  }
  /**
   * Method to calculate and return the function code based on the classification and wall attributes
   * by matching against the external rule set.
   * @returns The matching function code or null if no match is found.
   * @obsolete - This method is no longer used and will be removed in the future.
   */
  public static generateWallFunctionCode(wall: soWall2D): FuncCode | null {
    const tags = [...wall.wallTypeTags];
    for (const rule of wallFunctionRules.rules) {
      const ruleClassifications: WallType[] = rule.conditions.classification;

      // Check if both arrays have the same length and the same elements (ignoring order)
      const classificationMatch =
        ruleClassifications.length === tags.length &&
        ruleClassifications.every((cls: WallType) => tags.includes(cls)) &&
        tags.every((cls: WallType) => ruleClassifications.includes(cls));

      if (classificationMatch) {
        return rule.functionCode;
      }
    }
    // Fallback: Check if any rule matches the first item of this.wallTypeTags
    const firstClassification = tags[0];
    for (const rule of wallFunctionRules.rules) {
      if (rule.conditions.classification.includes(firstClassification)) {
        return rule.functionCode;
      }
    }

    // Return null if no rule matches
    return null;
  }
  public static GetRoomCategoryNameTier(category: string): number {
    const tiers = roomTiers.Tiers;
    for (const key of Object.keys(tiers)) {
      if (tiers[key].includes(category)) {
        return Number(key);
      }
    }
    return 4;
  }
  static CheckWallOverlapByIds(wallId1: string, wallId2: string): boolean {
    const wall1 = WallUtils.wallIdToLine3(wallId1);
    const wall2 = WallUtils.wallIdToLine3(wallId2);
    return GeometryUtils.doLinesOverlap(wall1, wall1);
  }
  static AddWallToOverideList(wall: soWall2D): void {
    appModel.addModifiedSegmentWall(wall);
  }
  static RemoveWallFromOverideList(wall: soWall2D): void {
    appModel.removeModifiedSegmentWall(wall);
  }
  static attachOpeningToWall(wall: soWall2D, opening: soWall2D): void {
    const soRoom = opening.parent as soRoom2D;
    const toAttach = soRoom.children.filter(child => child.name === "Window" || child.name === "Door");
  }

  /**
   * Gets the perpendicular wall in a room based on the specified wall side.
   * It determines which perpendicular wall is the right one based on the original opening / wall side given.
   * For example, we have two perpendicular walls: __|__ it will return one of them.
   * @param {soWall2D[]} perpendicularWalls - An array of perpendicular walls.
   * @param {string} wallSide - The side of the wall to find the perpendicular wall for.
   * @returns {soWall2D | undefined} - The perpendicular wall that matches the specified side, or undefined if no walls are provided.
   */
  static getPerpendicularWallInRoom(perpendicularWalls: soWall2D[], wallSide: string): soWall2D | undefined {
    if (perpendicularWalls.length === 0) {
      // Safety measures
      return;
    }
    // After we got all perpendicular walls, decide which one is the right one for our direction based on the original opening side
    switch (wallSide) {
      case "left":
        return perpendicularWalls.reduce((prev, curr) => (curr.start.x > prev.start.x ? curr : prev));
      case "top":
        return perpendicularWalls.reduce((prev, curr) => (curr.start.y < prev.start.y ? curr : prev));
      case "right":
        return perpendicularWalls.reduce((prev, curr) => (curr.start.x < prev.start.x ? curr : prev));
      case "bottom":
        return perpendicularWalls.reduce((prev, curr) => (curr.start.y > prev.start.y ? curr : prev));
    }
  }
}
