import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button, Row } from "antd";

import { appModel } from "../../../../../models/AppModel";
import { Scrollbar } from "react-scrollbars-custom";
import { SceneMode } from "../../../../../models/SceneMode";
import { Size, useWindowSize } from "../../../../../helpers/utilities";
import { showToastMessage } from "../../../../../helpers/messages";
import { variationsManager } from "../../../../../entities/variationsManager";
import { fileManager } from "../../../../../entities/fileManager";
import { FolderOptions } from "../LeftBar";
import ConfirmModal from "../../../common/ConfirmModal";

import "./Manage.sass";
import { Variation } from "../../../../../models/Variation";

const Manage = observer(() => {
  const size: Size = useWindowSize();
  const [variationThumbnails, setVariationThumbnails] = useState({});
  const [variationToDelete, setVariationToDelete] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    appModel.setSceneMode(SceneMode.Preview);
  }, []);

  if (!appModel.activeDesignStyle) {
    return;
  }

  let scrollbarHeight: number | string = "auto";
  if (size.height) {
    scrollbarHeight = size.height - 227;
  }

  const handleVariationClick = async variation => {
    const loadVariationResponse = await variationsManager.loadVariation(variation);
    if (!loadVariationResponse) {
      showToastMessage("Server", `Variation loading failed: ${variation.name}`);
    }
  };

  const handleDeleteVariation = async (e, variation) => {
    e.stopPropagation();

    setIsConfirmModalOpen(true);
    setVariationToDelete(variation);
  };

  const onConfirmDelete = async () => {
    if (!variationToDelete.id) {
      console.log(`Missing id for variation: ${variationToDelete.name}`);
      resetDeleteModal();
      return false;
    }
    setIsLoading(true);

    const variationDeletionResponse = await variationsManager.deleteVariation(variationToDelete.id);

    setIsLoading(false);

    if (variationDeletionResponse) {
      appModel.activeCorePlan.deleteVariation(variationToDelete);
      showToastMessage("Success", `'${variationToDelete.name}' was deleted`);

      console.log("Variation deleted:", variationToDelete.name);
    } else {
      console.log("Variation deletion failed:", variationToDelete.name);
    }
    resetDeleteModal();
  };

  const onCancelDelete = () => {
    resetDeleteModal();
  };

  const resetDeleteModal = () => {
    setIsConfirmModalOpen(false);
    setVariationToDelete(null);
  };

  const fetchFileContent = async uri => {
    const content = await fileManager.getCorePlanFile(uri);
    return content;
  };

  const getVariationFiles = async variation => {
    try {
      const files = await fileManager.getVariationFileList(variation.guid);
      if (!files) {
        return;
      }
      const thumbnailsFolderFiles = files.filter(file => {
        const splitName = file.URI.split("/");
        const folderName = splitName.length < 6 ? FolderOptions.VariationFolder : splitName[5];
        return folderName === FolderOptions.ThumbnailsFolder;
      });

      // Fetch image content for front and left views
      const frontImage = thumbnailsFolderFiles.find(file => file.URI.includes("front"));
      const leftImage = thumbnailsFolderFiles.find(file => file.URI.includes("left"));

      const frontImageSrc = frontImage ? `data:image/jpeg;base64,${await fetchFileContent(frontImage.URI)}` : null;
      const leftImageSrc = leftImage ? `data:image/jpeg;base64,${await fetchFileContent(leftImage.URI)}` : null;

      // Fetch material thumbnails
      const materialThumbnails = await Promise.all(
        thumbnailsFolderFiles
          .filter(file => file.URI.includes("Material_Thumbnail"))
          .map(async file => {
            const content = await fetchFileContent(file.URI);
            return `data:image/jpeg;base64,${content}`;
          })
      );

      setVariationThumbnails(prev => ({
        ...prev,
        [variation.guid]: { frontImageSrc, leftImageSrc, materialThumbnails },
      }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const loadAllVariationFiles = async () => {
      appModel.setIsBusy(true);
      try {
        const variations = appModel.activeCorePlan.variations;
        await Promise.all(variations.map(variation => getVariationFiles(variation)));
      } finally {
        appModel.setIsBusy(false);
      }
    };

    loadAllVariationFiles();
  }, [appModel.activeCorePlan.variations]);

  const variations = [...appModel.activeCorePlan.variations].sort((a: Variation, b: Variation) => a.name.localeCompare(b.name));

  const Icon = () => <span className="icon"></span>;

  const VariationThumbnails = ({ variation }) =>
    variationThumbnails[variation.guid] && (
      <>
        <div className="snapshot-thumbnails">
          {variationThumbnails[variation.guid].frontImageSrc && (
            <img src={variationThumbnails[variation.guid].frontImageSrc} alt="Front View" className="snapshot-thumbnail" />
          )}
          {variationThumbnails[variation.guid].leftImageSrc && (
            <img src={variationThumbnails[variation.guid].leftImageSrc} alt="Left View" className="snapshot-thumbnail" />
          )}
        </div>
        <div className="material-thumbnails">
          {variationThumbnails[variation.guid].materialThumbnails.map((src, idx) => (
            <img key={idx} src={src} alt={`Material Thumbnail ${idx}`} className="material-thumbnail" />
          ))}
        </div>
      </>
    );

  return (
    <div className="manage-tab">
      {!variations.length ? (
        <p className="empty-message">Variations is empty.</p>
      ) : (
        <>
          <Scrollbar style={{ height: scrollbarHeight }}>
            {variations.map((variation, index) => (
              <Row key={index}>
                <Button className="item" block onClick={() => handleVariationClick(variation)}>
                  <div className="title">
                    <p>{variation.name}</p>
                  </div>
                  <VariationThumbnails variation={variation} />
                  <Button
                    className="icon-button btn-delete"
                    disabled={appModel.isViewOnlyMode}
                    icon={<Icon />}
                    onClick={e => handleDeleteVariation(e, variation)}
                  />
                </Button>
              </Row>
            ))}
          </Scrollbar>
          <ConfirmModal
            deleteItemName={variationToDelete?.name}
            isOpen={isConfirmModalOpen}
            confirmLoading={isLoading}
            onOk={onConfirmDelete}
            onCancel={onCancelDelete}
            type={"variation"}
          />
        </>
      )}
    </div>
  );
});

export default Manage;
