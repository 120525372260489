import { Col, Button, Modal, Tooltip } from "antd";
import log from "loglevel";
import { Variation } from "../../../../models/Variation";
import { CorePlan } from "../../../../models/CorePlan";
import { openModalVariationPackageWindow } from "../ModalVariationPackageWindow";
import { closeModalCorePlanPackageWindow } from "../ModalCorePlanPackageWindow";
import { appModel } from "../../../../models/AppModel";
import { variationsManager } from "../../../../entities/variationsManager";
import RenderVariationJob from "../../../../entities/renderJob/renderVariationJob";
import React, { useState, useEffect } from "react";
import GenerateSpinner from "../../common/GenerateSpinner";
import { RenderJobStatus } from "../../../../entities/renderJob/types";
import { fileManager } from "../../../../entities/fileManager";
import { FolderOptions } from "../../Editor/LeftBar/LeftBar";
import JSZip from "jszip";
import { MessageKindsEnum, showToastMessage } from "../../../../helpers/messages";

interface CorePlanVariationProps {
  variation: Variation;
  corePlan: CorePlan;
  onLoadComplete: () => void;
}

const CorePlanVariationItem = ({ variation, corePlan: corePlan, onLoadComplete }: CorePlanVariationProps) => {
  const [hasJobs, setHasJobs] = useState(false);
  const [hasFiles, setHasFiles] = useState(false);
  const [frontImageSrc, setFrontImageSrc] = useState<string | null>(null);
  const [leftImageSrc, setLeftImageSrc] = useState<string | null>(null);
  const [materialThumbnails, setMaterialThumbnails] = useState<string[]>([]);

  const isLockedByAnotherUser = corePlan.isLocked() && !corePlan.isLockedByCurrentUser();

  const getVariationFiles = async () => {
    try {
      const files = await fileManager.getVariationFileList(variation.guid);
      if (!files) {
        onLoadComplete();
        return;
      }
      const thumbnailsFolderFiles = files.filter(file => {
        const splitName = file.URI.split("/");
        const folderName = splitName.length < 6 ? FolderOptions.VariationFolder : splitName[5];
        return folderName === FolderOptions.ThumbnailsFolder;
      });

      // Fetch image content for front and left views
      const frontImage = thumbnailsFolderFiles.find(file => file.URI.includes("front"));
      const leftImage = thumbnailsFolderFiles.find(file => file.URI.includes("left"));

      if (frontImage) {
        const frontImageContent = await fetchFileContent(frontImage.URI);
        setFrontImageSrc(`data:image/jpeg;base64,${frontImageContent}`);
      }

      if (leftImage) {
        const leftImageContent = await fetchFileContent(leftImage.URI);
        setLeftImageSrc(`data:image/jpeg;base64,${leftImageContent}`);
      }

      // Fetch material thumbnails
      const materialThumbnails = await Promise.all(
        thumbnailsFolderFiles
          .filter(file => file.URI.includes("Material_Thumbnail"))
          .map(async file => {
            const content = await fetchFileContent(file.URI);
            return `data:image/jpeg;base64,${content}`;
          })
      );

      setMaterialThumbnails(materialThumbnails);
    } catch (error) {
      log.error(error);
    } finally {
      onLoadComplete();
    }
  };

  const fetchFileContent = async uri => {
    const content = await fileManager.getCorePlanFile(uri);
    return content;
  };

  useEffect(() => {
    getVariationFiles();
    const checkJobsAndFiles = async () => {
      const jobs = await RenderVariationJob.getVariationJobs(variation.guid);
      const pendingJobs = jobs.filter(j => j.status === RenderJobStatus.Pending);
      setHasJobs(pendingJobs && pendingJobs.length > 0);

      const failedJobs = jobs.filter(j => j.status === RenderJobStatus.Failed);
      const files = await fileManager.getVariationFileList(variation.guid);
      const filteredFiles = files.filter(file => {
        const splitName = file.URI.split("/");
        const folderName = splitName.length < 6 ? FolderOptions.VariationFolder : splitName[5];
        return (folderName === FolderOptions.ThreeDModelsFolder && !file.URI.endsWith(".json")) || folderName === FolderOptions.DataModelFolder;
      });
      setHasFiles(failedJobs && failedJobs.length == 0 && filteredFiles.length > 0);
    };

    // Initial check
    checkJobsAndFiles();

    // Set interval for subsequent checks
    const intervalId = setInterval(checkJobsAndFiles, 2000); // Check every 2000 milliseconds (2.0 seconds)

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, [variation.guid]);

  const onVariationDetails = (e: any) => {
    if (e.target.children[0]?.disabled && e.target.children[0]?.type === "button") return;

    e.stopPropagation(); // Stop event from propagating to child buttons
    openModalVariationPackageWindow(corePlan, variation);
  };

  const handleGenerateClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation(); // Prevent event from propagating to the item button
    console.log("Generating data for", variation.name);

    const corePlanId = corePlan.id;
    if (appModel.corePlans.length && corePlanId) {
      // Retrieve and set the core plan initially
      const corePlanToSet = appModel.corePlans.find(prj => prj.id === corePlanId);
      if (corePlanToSet) {
        await appModel.setActiveCorePlan(corePlanToSet);
      }
    }

    // Load necessary previews and variation details before starting the job
    await appModel.baseManager.previewManager.loadSoPreviewRooms();
    await variationsManager.loadVariation(variation);

    // Check if the job can be started
    if (await RenderVariationJob.canStartJob()) {
      appModel.setIsBusy(true); // Set app state to busy
      try {
        // Set the core plan again before starting the job
        if (appModel.corePlans.length && corePlanId) {
          const corePlanToSet = appModel.corePlans.find(prj => prj.id === corePlanId);
          if (corePlanToSet) {
            await appModel.setActiveCorePlan(corePlanToSet);
          }
        }

        await RenderVariationJob.startJob(variation);
      } finally {
        appModel.setIsBusy(false); // Ensure app is not busy even if job fails
      }
    } else {
      console.log("Unable to start job for variation", variation.name);
    }
  };

  const handleDownloadClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation(); // Prevent event from propagating to the item button
    console.log("Downloading data for", variation.name);

    appModel.setIsBusy(true); // Set app state to busy at the start

    try {
      const files = await fileManager.getVariationFileList(variation.guid);
      const filteredFiles = files.filter(file => {
        const splitName = file.URI.split("/");
        const folderName = splitName.length < 6 ? FolderOptions.VariationFolder : splitName[5];
        return folderName === FolderOptions.ThreeDModelsFolder || folderName === FolderOptions.DataModelFolder;
      });

      const zip = new JSZip();
      for (const file of filteredFiles) {
        const content = await fetchFileContent(file.URI);
        zip.file(file.URI.split("/").pop(), content, { base64: true });
      }

      const blob = await zip.generateAsync({ type: "blob" });

      const reader = new FileReader();
      reader.onload = function (e) {
        const link = document.createElement("a");
        link.href = e.target.result as string;
        link.download = `${variation.name}.zip`;
        link.click();
      };
      reader.readAsDataURL(blob);

      showToastMessage(MessageKindsEnum.Success, "Your package is being downloaded.");
    } catch (error) {
      log.error(error);
    } finally {
      appModel.setIsBusy(false); // Ensure app is not busy at the end
    }
  };

  return (
    <Col span={8}>
      <Button className="item" block onClick={onVariationDetails}>
        <div className="title">
          <p>{variation.name}</p>
        </div>
        <div className="snapshot-thumbnails">
          {frontImageSrc ? <img src={frontImageSrc} alt="Front View" className="snapshot-thumbnail" /> : <span></span>}
          {leftImageSrc ? <img src={leftImageSrc} alt="Left View" className="snapshot-thumbnail" /> : <span></span>}
        </div>
        <div className="material-thumbnails">
          {materialThumbnails.map((src, index) => (
            <img key={index} src={src} alt={`Material Thumbnail ${index}`} className="material-thumbnail" />
          ))}
        </div>
        <Tooltip title="Generate Variation Package" className="downloadToolTip">
          <Button className="icon-button btn-generate" onClick={handleGenerateClick} disabled={hasJobs || isLockedByAnotherUser}>
            {hasJobs ? <GenerateSpinner size="small" /> : <span className="icon icon-generate"></span>}
          </Button>
        </Tooltip>
        <Tooltip title="Download Package" className="downloadToolTip">
          <Button className="icon-button btn-download" icon={<span className="icon"></span>} onClick={handleDownloadClick} disabled={!hasFiles}></Button>
        </Tooltip>
      </Button>
    </Col>
  );
};

export default CorePlanVariationItem;
