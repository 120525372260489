import { useEffect, RefObject } from "react";
import { appModel } from "../../models/AppModel";
import { SceneEditorMode } from "../../editor/models/SceneEditorMode";

function useClickOutside(primaryRef: RefObject<HTMLElement>, secondaryRef: RefObject<HTMLElement>, onClickOutside: (e) => void) {
  useEffect(() => {
    function handleOutsideClick(event: PointerEvent) {
      const isPrimaryOutside = primaryRef.current && !primaryRef.current.contains(event.target as Node);
      const isSecondaryOutside = secondaryRef.current && !secondaryRef.current.contains(event.target as Node);
      const hasSelectedRoom = appModel.selectedRoomWall?.size;
      if (isPrimaryOutside && isSecondaryOutside && hasSelectedRoom) {
        appModel.setSceneEditorMode(SceneEditorMode.Room);
        onClickOutside(event);
      }
    }

    document.addEventListener("pointerdown", handleOutsideClick, { capture: true });

    return () => {
      document.removeEventListener("pointerdown", handleOutsideClick, { capture: true });
    };
  }, [primaryRef, secondaryRef, onClickOutside]);

  return null;
}

export default useClickOutside;
