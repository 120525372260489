import { showToastMessage } from "../../helpers/messages";

export class RoomTiers {
  public Tiers: any[] = [];

  public async loadRoomTiers() {
    try {
      const RoomTiers = await fetch("/settings/RoomTiers.json").then(data => data.json());
      this.Tiers = RoomTiers;
    } catch (error) {
      showToastMessage("Error", "Error occurred on Room Teir rules loading.");
    }
  }
}
const roomTiers = new RoomTiers();

export default roomTiers;
